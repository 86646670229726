import React from "react";
import "./styles/ion-rangeslider/css/ion.rangeSlider.css";
import "./styles/ion-rangeslider/css/ion.rangeSlider.min.css";
import { useState } from "react";
import {
  Select,
  ErrorMessage,
  SubmitButton,
  Radio,
  FormSlider,
} from "components/Forms";
import WatchForm from "components/Forms/WatchForm";
import { useFormContext } from "react-hook-form";
import {
  MILLING_TYPES,
  FEED_RATE_MARKS,
  FEED_PER_TOOTH_MARKS,
  SPINDLE_SPEED_MARKS,
  CUTTING_SPEED_MARKS,
  FEED_TYPES,
  SPEED_TYPES,
  MATERIAL_SELECTION,
} from "./constants/constants";
import "./index.css";

const CuttingParameters = (props) => {
  const formCtx = useFormContext();

  const [image, setImage] = useState("assets/images/up-milling.png");
  const [sliderValues, setSliderValues] = useState({
    depthOfCut: 15,
    widthOfCut: 8,
    feedRate: 400,
    feedPerTooth: 0,
    spindleSpeed: 1000,
    cuttingSpeed: 0,
  });

  const speedType = formCtx?.getValues("cuttingParameters.speedTypeSelection");
  const feedType = formCtx?.getValues("cuttingParameters.feedTypeSelection");

  const millingTypesRadioHandler = (value) => {
    switch (value) {
      case 1:
        setImage("assets/images/up-milling.png");
        break;
      case 2:
        setImage("assets/images/down-milling.png");
        break;
      case 3:
        setImage("assets/images/slotting.png");
        break;
      default:
        setImage("assets/images/up-milling.png");
    }
  };

  const SpeedTypeSliderHandler = (value) => {
    if (speedType === 1) {
      formCtx.setValue("cuttingParameters.cuttingSpeed", 0);
      formCtx.setValue("cuttingParameters.spindleSpeed", value);
      setSliderValues({ ...sliderValues, spindleSpeed: value });
    } else {
      formCtx.setValue("cuttingParameters.spindleSpeed", 0);
      formCtx.setValue("cuttingParameters.cuttingSpeed", value);
      setSliderValues({ ...sliderValues, cuttingSpeed: value });
    }
  };

  const speedTypeRadioHandler = (value) => {
    switch (value) {
      case 1:
        formCtx.setValue("cuttingParameters.speedTypeSelection", 1);
        break;
      case 2:
        formCtx.setValue("cuttingParameters.speedTypeSelection", 2);
        break;
      default:
        break;
    }
  };

  const feedTypeRadioHandler = (value) => {
    switch (value) {
      case 1:
        formCtx.setValue("cuttingParameters.feedTypeSelection", 1);
        break;
      case 2:
        formCtx.setValue("cuttingParameters.feedTypeSelection", 2);
        break;
      default:
        break;
    }
  };

  const feedTypesSliderHandler = (value) => {
    if (feedType === 1) {
      formCtx.setValue("cuttingParameters.feedPerTooth", 0);
      formCtx.setValue("cuttingParameters.feedRate", value);
      setSliderValues({ ...sliderValues, feedRate: value });
    } else {
      formCtx.setValue("cuttingParameters.feedRate", 0);
      formCtx.setValue("cuttingParameters.feedPerTooth", value);
      setSliderValues({ ...sliderValues, feedPerTooth: value });
    }
  };

  const feedTypeInputHandler = (e) => {
    let inputValue = e.target.value;
    const maxLimit = feedType === 1 ? 40000 : 1;
    if (isNaN(inputValue)) {
      inputValue = 0;
    } else if (inputValue < 0) {
      inputValue = 0;
    } else if (inputValue > maxLimit) {
      inputValue = maxLimit;
    }
    const updatedValues = { ...sliderValues };
    if (feedType === 1) {
      updatedValues.feedRate = inputValue;
      setSliderValues({
        ...sliderValues,
        feedRate: updatedValues.feedRate,
      });
      formCtx.setValue("cuttingParameters.feedRate", updatedValues.feedRate);
    } else {
      updatedValues.feedPerTooth = inputValue;
      setSliderValues({
        ...sliderValues,
        feedPerTooth: updatedValues.feedPerTooth,
      });
      formCtx.setValue(
        "cuttingParameters.feedPerTooth",
        updatedValues.feedPerTooth
      );
    }
    setSliderValues(updatedValues);
  };

  const depthOfCutInputOnChangeHandler = (e, fluteLength) => {
    const inputValue = parseFloat(e.target.value);
    let newValue = inputValue;
    if (!isNaN(inputValue)) {
      if (inputValue > fluteLength) {
        newValue = fluteLength;
      }
    } else {
      newValue = 0;
    }
    setSliderValues({
      ...sliderValues,
      depthOfCut: newValue,
    });
    formCtx.setValue("cuttingParameters.depthOfCut", newValue);
  };

  const widthOfCutInputOnChangeHandler = (e, diameter) => {
    const inputValue = parseFloat(e.target.value);
    let newValue = inputValue;
    if (!isNaN(inputValue)) {
      if (inputValue > diameter) {
        newValue = diameter;
      }
    } else {
      newValue = 0;
    }
    setSliderValues({
      ...sliderValues,
      widthOfCut: newValue,
    });
    formCtx.setValue("cuttingParameters.widthOfCut", newValue);
  };

  const spindleCuttingSpeedInputOnChangeHandler = (e) => {
    let inputValue = parseFloat(e.target.value);
    const maxLimit = speedType === 1 ? 40000 : 15000;
    if (isNaN(inputValue)) {
      inputValue = 0;
    } else if (inputValue < 0) {
      inputValue = 0;
    } else if (inputValue > maxLimit) {
      inputValue = maxLimit;
    }
    const updatedValues = { ...sliderValues };
    if (speedType === 1) {
      updatedValues.spindleSpeed = inputValue;
      setSliderValues({
        ...sliderValues,
        spindleSpeed: updatedValues.spindleSpeed,
      });
      formCtx.setValue(
        "cuttingParameters.spindleSpeed",
        updatedValues.spindleSpeed
      );
    } else {
      updatedValues.cuttingSpeed = inputValue;
      setSliderValues({
        ...sliderValues,
        cuttingSpeed: updatedValues.cuttingSpeed,
      });
      formCtx.setValue(
        "cuttingParameters.cuttingSpeed",
        updatedValues.cuttingSpeed
      );
    }
    setSliderValues(updatedValues);
  };

  return (
    <div className="d-flex flex-column">
      {props.windowWidth <= 994 && (
        <div className="align-self-center col-5 ml-5">
          <img
            style={{
              maxHeight: "350px",
              width: "100%",
              marginTop: props.windowWidth <= 994 ? "10%" : "50%",
            }}
            src={image}
            alt="Milling process"
          />
        </div>
      )}
      <div
        className={
          props.windowWidth <= 994
            ? "d-flex justify-content-center"
            : "d-flex justify-content-start"
        }
      >
        <div
          className={
            props.windowWidth <= 994
              ? "cutting-parameters-input-container col-12 p-0"
              : "cutting-parameters-input-container col-8 pl-4 pr-4"
          }
        >
          <div className="d-flex justify-content-center mt-1 ml-5">
            <h3 className="text-center mb-5">Cutting Parameter Selection</h3>
          </div>
          <div className="inputs">
            <div className="input-wrapper d-flex flex-row align-items-center pb-2">
              <div className="d-flex flex-row col-3">
                <label
                  className="col-form-label"
                  style={{ width: props.windowWidth < 994 && "100px" }}
                >
                  Milling Type
                </label>
              </div>
              <div
                className="input-wrapper d-flex col-9 justify-content-start align-items-center"
                style={{
                  marginLeft: props.windowWidth < 994 && "40px",
                }}
              >
                <div className="d-flex col-12 flex-row">
                  <div className="btn-group-toggle w-100" data-toggle="buttons">
                    <Radio
                      onChange={(value) => millingTypesRadioHandler(value)}
                      name="cuttingParameters.millingTypeSelection"
                      options={MILLING_TYPES}
                    />
                  </div>
                  <ErrorMessage name="cuttingParameters.millingTypeSelection" />
                </div>
              </div>
            </div>
            <div className="input-wrapper d-flex flex-row align-items-center mb-3">
              <div className="d-flex flex-column col-3 align-items-center">
                <label
                  className="col-form-label"
                  style={{ width: props.windowWidth < 994 && "100px" }}
                >
                  Depth of Cut (mm):
                </label>
              </div>
              <WatchForm name="fluteLength">
                {(fluteLength) => {
                  return (
                    <div className="d-flex col-9 align-items-center">
                      <div className="d-flex flex-column col-10">
                        <FormSlider
                          onChange={(value) => {
                            setSliderValues({
                              ...sliderValues,
                              depthOfCut: value,
                            });
                            formCtx.setValue(
                              "cuttingParameters.depthOfCut",
                              value
                            );
                          }}
                          style={{ marginLeft: "-1em", width: 290 }}
                          name="cuttingParameters.depthOfCut"
                          min={0}
                          max={fluteLength}
                          marks={{
                            0: "0",
                            [fluteLength]: fluteLength,
                          }}
                          allowCross={true}
                          value={sliderValues.depthOfCut}
                          step={0.1}
                        />
                        <div className="mt-3">
                          <ErrorMessage name="cuttingParameters.depthOfCut" />
                        </div>
                      </div>
                      <div
                        className="d-flex flex-row ml-5 col-2 align-items-center"
                        style={{ height: 35 }}
                      >
                        <input
                          className={
                            props.windowWidth > 994
                              ? "slider-input mt-4 mb-3 ml-3"
                              : "slider-input mt-4 mb-3"
                          }
                          type="number"
                          step="0.1"
                          value={sliderValues.depthOfCut}
                          style={{ width: 52 }}
                          onChange={(e) =>
                            depthOfCutInputOnChangeHandler(e, fluteLength)
                          }
                          onBlur={(e) => {
                            if (e.target.value === "") {
                              e.target.value = "0";
                              setSliderValues({
                                ...sliderValues,
                                depthOfCut: 0,
                              });
                              formCtx.setValue(
                                "cuttingParameters.depthOfCut",
                                0
                              );
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                          max={fluteLength}
                        />
                      </div>
                    </div>
                  );
                }}
              </WatchForm>
            </div>
            <div className="input-wrapper d-flex flex-row align-items-center mb-3">
              <div className="d-flex flex-column col-3 align-items-center">
                <label
                  className="col-form-label"
                  style={{ width: props.windowWidth < 994 && "100px" }}
                >
                  Width of cut (mm):
                </label>
              </div>
              <WatchForm name="cuttingParameters.millingTypeSelection">
                {(millingTypeSelection, { formValues: { diameter } }) => {
                  if (
                    millingTypeSelection === 1 ||
                    millingTypeSelection === 2
                  ) {
                    return (
                      <div className="d-flex col-9 align-items-center">
                        <div className="d-flex flex-column col-10">
                          <FormSlider
                            onChange={(value) => {
                              setSliderValues({
                                ...sliderValues,
                                widthOfCut: value,
                              });
                              formCtx.setValue(
                                "cuttingParameters.widthOfCut",
                                value
                              );
                            }}
                            name="cuttingParameters.widthOfCut"
                            min={0}
                            max={diameter}
                            step={0.1}
                            marks={{
                              0: "0",
                              [diameter]: diameter,
                            }}
                            value={sliderValues.widthOfCut}
                            allowCross={false}
                            style={{
                              width: 290,
                              marginLeft:
                                props.windowWidth <= 994 ? "-6px" : "-1em",
                            }}
                          />
                          <div className="mt-3">
                            <ErrorMessage name="cuttingParameters.widthOfCut" />
                          </div>
                        </div>
                        <div
                          className="d-flex flex-row ml-5 col-2 align-items-center"
                          style={{ height: 35 }}
                        >
                          <input
                            style={{ width: 52 }}
                            className={
                              props.windowWidth > 994
                                ? "slider-input mt-4 mb-3 ml-3"
                                : "slider-input mt-4 mb-3"
                            }
                            type="number"
                            step="0.1"
                            value={sliderValues.widthOfCut}
                            onChange={(e) =>
                              widthOfCutInputOnChangeHandler(e, diameter)
                            }
                            onBlur={(e) => {
                              if (e.target.value === "") {
                                e.target.value = "0";
                                setSliderValues({
                                  ...sliderValues,
                                  widthOfCut: 0,
                                });
                                formCtx.setValue(
                                  "cuttingParameters.widthOfCut",
                                  0
                                );
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                              }
                            }}
                            max={diameter}
                          />
                        </div>
                      </div>
                    );
                  }
                  if (millingTypeSelection === 3) {
                    return (
                      <div className="d-flex flex-column col-7 align-items-center ml-1">
                        <FormSlider
                          className={
                            props.windowWidth > 994
                              ? "disabled-field-fullscreen"
                              : "disabled-field-tablet"
                          }
                          onChange={(value) => {
                            setSliderValues({
                              ...sliderValues,
                              widthOfCut: value,
                            });
                            formCtx.setValue(
                              "cuttingParameters.widthOfCut",
                              value
                            );
                          }}
                          name="cuttingParameters.widthOfCut"
                          disabled={true}
                          min={0}
                          max={diameter}
                          marks={{
                            0: "0",
                            [diameter]: diameter,
                          }}
                          value={diameter}
                          allowCross={false}
                          style={{
                            width: 290,
                            marginLeft:
                              props.windowWidth <= 994 ? "-6px" : "-1em",
                          }}
                        />
                        <div className="mt-3">
                          <ErrorMessage name="cuttingParameters.widthOfCut" />
                        </div>
                      </div>
                    );
                  }
                }}
              </WatchForm>
            </div>
            <div className="input-wrapper d-flex flex-row align-items-center pb-3">
              <div className="d-flex flex-row col-3">
                <label
                  className="col-form-label"
                  style={{ width: props.windowWidth < 994 && "100px" }}
                >
                  Speed Type
                </label>
              </div>
              <div
                className="input-wrapper d-flex col-9 justify-content-start align-items-center"
                style={{
                  marginLeft: props.windowWidth < 994 && "40px",
                }}
              >
                <div className="d-flex col-12 flex-row">
                  <div className="btn-group-toggle h-50" data-toggle="buttons">
                    <Radio
                      onChange={(value) => speedTypeRadioHandler(value)}
                      name="cuttingParameters.speedTypeSelection"
                      options={SPEED_TYPES}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="input-wrapper d-flex flex-row align-items-center mb-3">
              <div className="d-flex flex-column col-3 align-items-center">
                {speedType === 1 ? (
                  <label
                    className="col-form-label"
                    style={{ width: props.windowWidth < 994 && "100px" }}
                  >
                    Spindle Speed (RPM):
                  </label>
                ) : (
                  <label
                    className="col-form-label"
                    style={{ width: props.windowWidth < 994 && "100px" }}
                  >
                    Cutting Speed (m/min):
                  </label>
                )}
              </div>
              <div className="d-flex col-9 align-items-center">
                <div className="d-flex flex-column col-10">
                  <FormSlider
                    onChange={(value) => SpeedTypeSliderHandler(value)}
                    value={
                      speedType === 1
                        ? sliderValues.spindleSpeed
                        : sliderValues.cuttingSpeed
                    }
                    name={
                      speedType === 1
                        ? "cuttingParameters.spindleSpeed"
                        : "cuttingParameters.cuttingSpeed"
                    }
                    min={0}
                    max={speedType === 1 ? 40000 : 15000}
                    marks={
                      speedType === 1
                        ? SPINDLE_SPEED_MARKS
                        : CUTTING_SPEED_MARKS
                    }
                    style={{ width: 290, marginLeft: "-1em" }}
                  />
                  <div className="mt-4">
                    <ErrorMessage name="cuttingParameters.speedTypeSelection" />
                  </div>
                </div>
                <div
                  className="d-flex flex-row ml-5 col-2 align-items-center"
                  style={{ height: 35 }}
                >
                  <input
                    className={
                      props.windowWidth > 994
                        ? "slider-input mt-4 mb-3 ml-3"
                        : "slider-input mt-4 mb-3"
                    }
                    type="number"
                    step="0.1"
                    value={
                      speedType === 1
                        ? sliderValues.spindleSpeed
                        : sliderValues.cuttingSpeed
                    }
                    style={{ width: 52 }}
                    onChange={(e) => spindleCuttingSpeedInputOnChangeHandler(e)}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        const updatedValues = { ...sliderValues };
                        if (speedType === 1) {
                          updatedValues.spindleSpeed = 0;
                        } else {
                          updatedValues.cuttingSpeed = 0;
                        }
                        setSliderValues(updatedValues);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    max={speedType === 1 ? 40000 : 15000}
                  />
                </div>
              </div>
            </div>
            <div className="input-wrapper d-flex flex-row align-items-center pb-3">
              <div className="d-flex flex-row col-3">
                <label
                  className="col-form-label"
                  style={{ width: props.windowWidth < 994 && "100px" }}
                >
                  Feed Type
                </label>
              </div>
              <div
                className="input-wrapper d-flex col-9 justify-content-start align-items-center"
                style={{
                  marginLeft: props.windowWidth < 994 && "40px",
                }}
              >
                <div className="d-flex col-12 flex-row">
                  <div className="btn-group-toggle h-50" data-toggle="buttons">
                    <Radio
                      onChange={(value) => feedTypeRadioHandler(value)}
                      name="cuttingParameters.feedTypeSelection"
                      options={FEED_TYPES}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="input-wrapper d-flex flex-row align-items-center mb-3">
              <div className="d-flex flex-column col-3 align-items-center">
                {feedType === 1 ? (
                  <label
                    className="col-form-label"
                    style={{ width: props.windowWidth < 994 && "100px" }}
                  >
                    Feed Rate (mm/min):
                  </label>
                ) : (
                  <label
                    className="col-form-label"
                    style={{ width: props.windowWidth < 994 && "100px" }}
                  >
                    Feed Per Tooth (mm/tooth):
                  </label>
                )}
              </div>
              <div className="d-flex col-9 align-items-center">
                <div className="d-flex flex-column col-10">
                  <FormSlider
                    onChange={(value) => feedTypesSliderHandler(value)}
                    name={
                      feedType === 1
                        ? "cuttingParameters.feedRate"
                        : "cuttingParameters.feedPerTooth"
                    }
                    value={
                      feedType === 1
                        ? sliderValues.feedRate
                        : sliderValues.feedPerTooth
                    }
                    marks={
                      feedType === 1 ? FEED_RATE_MARKS : FEED_PER_TOOTH_MARKS
                    }
                    style={{ width: 290, marginLeft: "-1em" }}
                    max={feedType === 1 ? 40000 : 1}
                    min={0}
                    step={feedType === 1 ? 1 : 0.1}
                  />
                  <div className="mt-4">
                    <ErrorMessage name="cuttingParameters.feedRate" />
                  </div>
                </div>
                <div
                  className="d-flex flex-row ml-5 col-2 align-items-center"
                  style={{ height: 35 }}
                >
                  <input
                    className={
                      props.windowWidth > 994
                        ? "slider-input mt-4 mb-3 ml-3"
                        : "slider-input mt-4 mb-3"
                    }
                    value={
                      feedType === 1
                        ? sliderValues.feedRate
                        : sliderValues.feedPerTooth
                    }
                    onChange={(e) => feedTypeInputHandler(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    style={{ width: 52 }}
                  ></input>
                </div>
              </div>
            </div>
            <div className="d-flex ml-4 justify-content-center">
              <div className="w-50 ml-5 mb-2">
                <ErrorMessage name="cuttingParameters.feedTypeSelection" />
              </div>
            </div>
            <div className="input-wrapper d-flex flex-row align-items-center mb-3">
              <div className="d-flex flex-column col-3 align-items-center">
                <label
                  className="col-form-label"
                  style={{ width: props.windowWidth < 994 && "100px" }}
                >
                  Workpiece Material:
                </label>
              </div>
              <div className="d-flex col-10">
                <Select
                  name="cuttingParameters.materialSelection"
                  options={MATERIAL_SELECTION}
                  className="form-control ml-3 w-75"
                  placeholder="Shape"
                />
              </div>
            </div>
          </div>
          <div
            style={{ marginLeft: "5em" }}
            className="d-flex align-items-center justify-content-center"
          >
            <SubmitButton
              type="submit"
              className="btn btn-info waves-effect waves-light"
              style={{
                width: "200px",
                backgroundColor: "#3398DB",
                color: "#fff",
              }}
              disabled={props.loading}
            >
              Submit
            </SubmitButton>
          </div>
        </div>
        {props.windowWidth > 994 && (
          <div className="col-4">
            <img
              style={{
                maxHeight: "25em",
                marginTop: props.windowWidth <= 994 ? "10%" : "65%",
              }}
              src={image}
              alt="Milling process"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CuttingParameters;
