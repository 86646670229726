import http from "utils/http";
import { users } from "constants/endpoints";

const putUserOrganization = ({ userId }) => {
  const ENDPOINT = users.putUserOrganization({ userId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.put(`${API_BASE_URL}${ENDPOINT}`);
};

export default putUserOrganization;
