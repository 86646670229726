import http from "utils/http";
import { files } from "constants/endpoints";

const getFileAsJson = ({ ownerId, fileName }) => {
  const endpoint = files.getFileAsJson({ ownerId, fileName });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${endpoint}`);
};

export default getFileAsJson;
