import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Multiselect } from "react-widgets/cjs";
import get from "utils/get";

const MultiSelect = ({
  name,
  options,
  allowCreate = "onFilter",
  maxSelectedOptions = 999,
  onMaxOptionsSelected,
  textField, // required if you're working with array of objects
  dataKey, // required if you're working with array of objects
  ...rest
} = {}) => {
  const { register, getValues } = useFormContext();
  const [availableOptions, setAvailableOptions] = useState([]);
  const registeredField = register(name);

  useEffect(() => {
    if (options.length) {
      setAvailableOptions(options);
    }
  }, [options]);

  const formValues = getValues();
  const fieldValue = get(formValues, name);

  const changeFormFieldState = (newOption) => {
    registeredField.onChange({
      target: {
        name,
        value: [
          ...(fieldValue ? fieldValue : []),
          dataKey
            ? {
                [dataKey]: availableOptions.length + 1,
                [textField]: newOption,
                id: availableOptions.length + 1,
              }
            : newOption,
        ],
      },
    });
  };

  const setOption = (newOption) => {
    if (maxSelectedOptions === 1) {
      if ((fieldValue?.length || 0) === 0 || newOption.length === 0) {
        changeFormFieldState(newOption);
      } else {
        onMaxOptionsSelected?.(newOption);
      }
    } else if (maxSelectedOptions >= (fieldValue?.length || 0) + 1) {
      changeFormFieldState(newOption);
    } else {
      onMaxOptionsSelected?.(newOption);
    }
  };

  const addOption = (newOption) => {
    setAvailableOptions([...availableOptions, newOption]);
    setOption(newOption);
  };

  return (
    <Multiselect
      textField={textField}
      value={fieldValue}
      allowCreate={allowCreate}
      data={availableOptions}
      onChange={(value) => {
        if (maxSelectedOptions >= value.length) {
          registeredField.onChange({ target: { name, value } });
        } else {
          onMaxOptionsSelected?.(value);
        }
      }}
      onCreate={addOption}
      {...rest}
      dataKey={null} // this needs to be set to null due to an internal Multiselect issue
    />
  );
};

export default MultiSelect;
