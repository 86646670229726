import http from "utils/http";
import { predictiveMaintenance } from "constants/endpoints";

const postMachineExtensions = ({
  operationName,
  toolHolderName,
  toolHolderDataInputSelection,
  toolHolderUrl,
  toolHolderIsoNo,
  cuttingToolName,
  cuttingToolNumber,
  toolDataInputSelection,
  toolUrl,
  toolIsoNo,
  toolDatasheet,
  toolParameters,
  stickOutLength,
  cuttingToolFRFxxUpload,
  cuttingToolFRFyyUpload,
  spindleName,
  machineId,
}) => {
  const POST_USER_ENDPOINT = predictiveMaintenance.postMachineExtensions();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_USER_ENDPOINT}`, {
    data: {
      operationName,
      toolHolderName,
      toolHolderDataInputSelection,
      toolHolderUrl,
      toolHolderIsoNo,
      cuttingToolName,
      cuttingToolNumber,
      toolDataInputSelection,
      toolUrl,
      toolIsoNo,
      toolDatasheet,
      toolParameters,
      stickOutLength,
      cuttingToolFRFxxUpload,
      cuttingToolFRFyyUpload,
      spindleName,
      machineId,
    },
  });
};

export default postMachineExtensions;
