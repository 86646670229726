import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import notify from "utils/toastMsg";
import "./index.css";
import deleteInvitation from "services/Organizations/deleteInvitation";
import patchUserInvitation from "services/Organizations/patchUserInvitation";

const InvitationActionModal = ({
  setOpenInvitationActionModal,
  invitationPayload,
  fetchAllUserInvitations,
}) => {
  const history = useHistory();
  const [isFirstConfirmation, setIsFirstConfirmation] = useState(true);
  const handleInvitationAcceptOrDeclineInvitation = async (
    invitationPayload
  ) => {
    if (
      isFirstConfirmation &&
      invitationPayload.functionToUse === "acceptInvitation"
    ) {
      setIsFirstConfirmation(false);
      return;
    }
    const { invitationShortcode, userOrganizationInvitationId, functionToUse } =
      invitationPayload;
    try {
      if (functionToUse === "acceptInvitation" && !isFirstConfirmation) {
        await patchUserInvitation({
          invitationShortcode,
          userOrganizationInvitationId,
        });
        notify("Success! You successfully accept invitation ✅");
        history.push("/login");
      }
      if (functionToUse === "declineInvitation") {
        await deleteInvitation({
          invitationShortcode,
          userOrganizationInvitationId,
        });
        notify("Success! You successfully delete invitation ✅");
      }
      await fetchAllUserInvitations();
      setOpenInvitationActionModal(false);
    } catch (error) {
      notify(`${error.message} ❌`);
      // eslint-disable-next-line no-console
      console.error(error.message);
    }
  };

  return (
    <div className="notify-content mt-1">
      <div className="d-flex justify-content-between align-items-baseline">
        <h4 className="notify-header">
          {isFirstConfirmation
            ? "Are You Sure ?"
            : "You are about to lose all your previous data. Are you sure you want to continue?"}
        </h4>
        <button
          className="close"
          type="button"
          onClick={() => setOpenInvitationActionModal(false)}
        >
          &times;
        </button>
      </div>
      <div className="d-flex justify-content-around mt-3">
        <button
          className="btn btn-success waves-effect waves-light"
          onClick={() =>
            handleInvitationAcceptOrDeclineInvitation(invitationPayload)
          }
        >
          Yes
        </button>
        <button
          className="btn btn-danger waves-effect waves-light"
          onClick={() => {
            setIsFirstConfirmation(true);
            setOpenInvitationActionModal(false);
          }}
        >
          No
        </button>
      </div>
    </div>
  );
};

export default InvitationActionModal;
