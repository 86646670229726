const toSentenceCase = (str) => {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
};

const convertFromUnderscoreCase = (str) => {
  return str.replace(/_/g, " ");
};

export { toSentenceCase, convertFromUnderscoreCase };
