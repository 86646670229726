import { memo } from "react";
import { useFormContext } from "react-hook-form";
import get from "utils/get";

const Input = memo(
  ({ formMethods, name, value, watchedFieldValue, ...rest }) => {
    const { register, formState } = formMethods;

    const fieldError = Boolean(get(formState.errors, name));
    return (
      <input
        {...register(name)}
        {...rest}
        {...(fieldError ? { style: { borderColor: "#a01" } } : {})}
      />
    );
  },
  (prevProps, nextProps) => {
    const hasChanged =
      prevProps.watchedFieldValue !== nextProps.watchedFieldValue;

    const hasTypeChanged = prevProps.type !== nextProps.type;

    return !hasChanged && !hasTypeChanged;
  }
);

const NestedInputContainer = (props) => {
  const formMethods = useFormContext();
  const watch = formMethods.watch(props.name);

  return (
    <Input formMethods={formMethods} {...props} watchedFieldValue={watch} />
  );
};

export default NestedInputContainer;
