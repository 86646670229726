import http from "utils/http";
import { user } from "constants/endpoints";

const patchUserDetails = ({ firstName, lastName, email }) => {
  const ENDPOINT = user.patchUserDetails();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.put(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      firstName,
      lastName,
      email,
    },
  });
};

export default patchUserDetails;
