import * as yup from "yup";

const prtUploadSchema = yup.object().shape({
  prtUpload: yup
    .string()
    .label("File Upload")
    .typeError("Prt Upload must be a text."),
  optimisationConstraintId: yup
    .number()
    .required()
    .label("Optimization Constraint Selection")
    .typeError(
      "Optimization Constraint Selection output expects an optimization constraing index"
    ),
});

export default prtUploadSchema;
