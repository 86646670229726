import http from "utils/http";
import { organizations } from "constants/endpoints";

const patchUserInvitation = ({
  invitationShortcode,
  userOrganizationInvitationId,
}) => {
  const ENDPOINT = organizations.patchUserInvitation();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.patch(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      invitationId: userOrganizationInvitationId,
      shortcode: invitationShortcode,
    },
  });
};

export default patchUserInvitation;
