import http from "utils/http";
import { digiBasic } from "constants/endpoints";

const getDigibasicFeedRate = ({ feedRateToolId }) => {
  const ENDPOINT = digiBasic.getDigibasicFeedRate();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(
    `${API_BASE_URL}${ENDPOINT}?feedRateToolId=${feedRateToolId}`
  );
};

export default getDigibasicFeedRate;
