const CUTTING_SPEED_MARKS = {
  0: "0",
  15000: "15000",
};

const MILLING_TYPES = [
  { value: 1, label: "Up Milling" },
  { value: 2, label: "Down Milling" },
  { value: 3, label: "Slotting" },
];

const FEED_DIRECTION_TYPES = [
  { value: 1, label: "± X" },
  { value: 2, label: "± 45 deg" },
  { value: 3, label: "± Y" },
];

const MATERIAL_SELECTION = [
  { key: 1, value: 1, label: "AISI P20 Mold Steel" },
  { key: 2, value: 2, label: "AISI 4340 Steel" },
  { key: 4, value: 4, label: "AISI 1045 Steel" },
  { key: 6, value: 6, label: "Aluminium 6061-T6" },
  { key: 7, value: 7, label: "Aluminium 7050-T74" },
];

export {
  MILLING_TYPES,
  CUTTING_SPEED_MARKS,
  MATERIAL_SELECTION,
  FEED_DIRECTION_TYPES,
};
