import * as yup from "yup";

const toolingSchema = yup.object().shape({
  digiStabilityToolTypeId: yup
    .number()
    .required()
    .label("Digi Stability Tool Type Id")
    .typeError("Tool type id should be a number"),
  diameter: yup
    .number()
    .min(1, "Tool Diameter cannot be less than 1mm")
    .max(100, "Tool Diameter cannot be higher than 100mm")
    .required()
    .label("Diameter")
    .typeError("Diameter should be a number"),
  fluteParameters: yup.object().shape({
    noOfFlutes: yup
      .number()
      .integer("Should be positive digit number")
      .min(1)
      .max(10)
      .required()
      .label("Number Of Flutes")
      .typeError("Number of flutes cannot be empty"),
    helixAngle: yup
      .number()
      .integer("Should be positive digit number")
      .min(0)
      .max(60)
      .required()
      .label("Helix Angle")
      .typeError("Helix Angle cannot be empty"),
    frfDataXx: yup
      .string()
      .required("FRF in the feed direction is required")
      .label("FRF in the feed direction"),
    frfDataYy: yup
      .string()
      .required("FRF in the cross-feed direction is required")
      .label("FRF in the cross-feed direction"),
  }),
  cuttingParameters: yup.object().shape({
    cuttingSpeed: yup
      .number()
      .integer("Should be positive digit number")
      .min(10000)
      .max(40000)
      .required()
      .label("Spindle Speed")
      .typeError("Spindle speed cannot be empty"),
  }),
});

export default toolingSchema;
