import http from "utils/http";
import { users } from "constants/endpoints";

const getNotifymeUsers = ({ queryParams }) => {
  const ENDPOINT = users.getNotifymeUsers({ queryParams });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`);
};

export default getNotifymeUsers;
