import http from "utils/http";
import { frfDatabase } from "constants/endpoints";

const postEventLogByMachineId = ({
  eventDate,
  eventType,
  status,
  machineId,
  spindleHours,
  selectedEvent,
  customEvent,
}) => {
  const POST_EVENT_LOG_BY_MACHINE_ID_ENDPOINT =
    frfDatabase.postEventLogByMachineId();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_EVENT_LOG_BY_MACHINE_ID_ENDPOINT}`, {
    data: {
      eventDate,
      eventType,
      status,
      machineId,
      spindleHours,
      selectedEvent,
      customEvent,
    },
  });
};

export default postEventLogByMachineId;
