import React, { useState } from "react";
import Authentication from "services/Authentication";
import notify from "utils/toastMsg";
import {
  Form,
  InputNonOptimized,
  ErrorMessage,
  SubmitButton,
} from "components/Forms";
import confirmForgotPasswordSchema from "./schemas/confirmForgotPasswordSchema";

let auth = new Authentication();

const ConfirmForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      await auth.confirmForgotPassword({ email, code, newPassword });
      setMessage("Password has been reset successfully.");
      notify(`Redirecting to Login page... ✅`);
      setTimeout(() => {
        window.location.href = "/login";
      }, 2500);
    } catch (err) {
      setError("Failed to reset password.");
      notify(`Failed to reset password. ❌`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <img className="login-logo" src="assets/images/ll2.png" alt="logo" />
      <div
        className="mt-5"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="col-lg-5 mt-5">
          <div className="card mb-0 mt-5">
            <div className="card-body">
              <div className="mb-4 text-center">
                <h3 className="mb-0" style={{ fontFamily: "Poppins" }}>
                  Reset Password
                </h3>
              </div>
              <Form
                className="form-horizontal mt-5"
                initialValues={{}}
                validationSchema={confirmForgotPasswordSchema}
                onSubmit={handleSubmit}
              >
                <div className="form-group row">
                  <div className="col-12 mt-4 d-flex justify-content-center align-items-center">
                    {" "}
                    <InputNonOptimized
                      className="mb-2 form-control"
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <ErrorMessage name="email" />
                </div>
                <div className="form-group row">
                  <div className="col-12 mt-4 d-flex justify-content-center align-items-center">
                    {" "}
                    <InputNonOptimized
                      className="mb-2 form-control"
                      type="text"
                      placeholder="Enter verification code"
                      value={code}
                      name="code"
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                  <ErrorMessage name="code" />
                </div>
                <div className="form-group row">
                  <div className="col-12 mt-4 d-flex justify-content-center align-items-center">
                    {" "}
                    <InputNonOptimized
                      className="mb-2 form-control"
                      type="password"
                      placeholder="Enter new password"
                      value={newPassword}
                      name="newPassword"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <ErrorMessage name="newPassword" />
                </div>
                <SubmitButton
                  className="m-2 btn btn-primary btn-lg"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    "Submit"
                  )}
                </SubmitButton>
              </Form>
              {message && <p>{message}</p>}
              {error && <p>{error}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmForgotPassword;
