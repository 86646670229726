import React from "react";

function ToolingDatabase() {
  return (
    <div>
      <h1 className="mt-5">asdasds</h1>
      <h1 className="mt-5">asdasds</h1>
      <h1 className="mt-5">asdasds</h1>
      <h1 className="mt-5">asdasds</h1>
      <h1 className="mt-5">asdasds</h1>
    </div>
  );
}

export default ToolingDatabase;
