import * as yup from "yup";

const frfDataSchema = yup.object().shape({
  cuttingToolFRFxxUpload: yup
    .string()
    .required()
    .label("FRF in the feed direction")
    .typeError("FRF in the feed direction cannot be empty"),
  cuttingToolFRFyyUpload: yup
    .string()
    .required()
    .label("FRF in the cross-feed direction")
    .typeError("FRF in the feed direction cannot be empty"),
  spindleHours: yup
    .number()
    .required()
    .label("Spindle Hours")
    .typeError("Spindle Hours cannot be empty"),
});

export default frfDataSchema;
