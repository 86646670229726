import React, { useState } from "react";
import FileUpload from "components/FileUpload";
import { Form, Select, SubmitButton } from "components/Forms";
import "./style.css";
import notify from "utils/toastMsg";
import postFrfData from "services/frfDatabase/postFrfData";
import frfDataSchema from "./schemas/frfDataSchema";

function UploadData({
  setShowMenu,
  machineExtensionsByMachineIdResponse,
  fetchAllFrfDataByMachineId,
}) {
  const [loading, setLoading] = useState(false);

  const { machineExtensionDataByMachineMachineId } =
    machineExtensionsByMachineIdResponse;

  const machineExtensionOptions = machineExtensionDataByMachineMachineId.map(
    (option) => {
      return {
        key: option.machineExtensionId,
        value: option.machineExtensionId,
        label: option.machineExtensionId,
      };
    }
  );

  const handleFrfDataSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await postFrfData({
        ...values,
      });
      fetchAllFrfDataByMachineId();
      notify("Frf data has been received successfully. ✅ ");
      setShowMenu(false);
      return response;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center modal-container-upload-data "
      onClick={() => setShowMenu(false)}
    >
      <div
        className="main-container-pop-upload-data "
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="pop-up-content-upload-data">
          <h1 className="text-center">Upload New FRF Data</h1>
          <div className="mt-5" style={{ marginRight: "5em" }}>
            {" "}
            <Form
              initialValues={{
                cuttingToolFRFxxUpload: "",
                cuttingToolFRFyyUpload: "",
                cuttingToolFRFxyUpload: "",
                cuttingToolFRFyxUpload: "",
                machineExtensionId: "",
              }}
              validationSchema={frfDataSchema}
              onSubmit={handleFrfDataSubmit}
            >
              <div className="d-flex flex-row mt-3 mb-5 ml-5">
                <h3 className="mr-3">Select Machine Extension Id: </h3>
                <Select
                  name="machineExtensionId"
                  type="number"
                  className="form-control form-control-size-add-new-machine mt-2"
                  placeholder="Select Machine Configuration Id"
                  options={machineExtensionOptions}
                />
              </div>
              <div className="d-flex row">
                <div className="form-group" style={{ marginLeft: "4.4em" }}>
                  <label>File Upload: FRFxx (.frf / .txt)</label>
                  <FileUpload
                    name="cuttingToolFRFxxUpload"
                    className="form-control"
                  />
                </div>
                <div className="form-group" style={{ marginLeft: "4.4em" }}>
                  <label>File Upload: FRFxy (.frf / .txt)</label>
                  <FileUpload
                    name="cuttingToolFRFxyUpload"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="d-flex row">
                <div className="form-group" style={{ marginLeft: "4.4em" }}>
                  <label>File Upload: FRFyx (.frf / .txt)</label>
                  <FileUpload
                    name="cuttingToolFRFyxUpload"
                    className="form-control"
                  />
                </div>
                <div className="form-group" style={{ marginLeft: "4.4em" }}>
                  <label>File Upload: FRFyy (.frf / .txt)</label>
                  <FileUpload
                    name="cuttingToolFRFyyUpload"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-around">
                <button
                  className="btn btn-danger waves-effect m-3"
                  onClick={() => setShowMenu(false)}
                >
                  Cancel
                </button>
                <SubmitButton
                  type="submit"
                  className="btn btn-success waves-effect m-3"
                  disabled={loading}
                >
                  Upload
                </SubmitButton>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadData;
