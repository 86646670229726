import React from "react";

const DigibasicErrorModal = ({ errorModalText }) => {
  return (
    <>
      <div className="mt-4 d-flex justify-content-center">
        <div className="mt-5">
          <div
            className="card mb-0 mt-5"
            style={{ width: "500px", height: "auto" }}
          >
            <div className="card-body">
              <div className="mb-4 text-center">
                <h3 className="mb-0" style={{ fontFamily: "Poppins" }}>
                  Error Report
                </h3>
                <h3 className="mt-4" style={{ fontFamily: "Montserrat" }}>
                  Error Type: {errorModalText?.errorType}
                </h3>
                <p
                  className="mt-4"
                  style={{ fontFamily: "Montserrat", fontSize: "16px" }}
                >
                  {errorModalText?.message}
                </p>
                <p
                  className="mt-4"
                  style={{ fontFamily: "Montserrat", fontSize: "16px" }}
                >
                  We apologize for the inconvenience. Unfortunately, we
                  encountered an error and were unable to obtain the desired
                  outputs.
                </p>
                <p
                  className="mt-4"
                  style={{ fontFamily: "Montserrat", fontSize: "16px" }}
                >
                  Please contact our customer support team, Productive Machines,
                  at{" "}
                  <span className="font-weight-bold">
                    technical@productivemachines.co.uk
                  </span>
                  , for further assistance. Thank you for your understanding and
                  cooperation.
                </p>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-success w-25 text-uppercase"
                    onClick={() => window.location.reload()}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigibasicErrorModal;
