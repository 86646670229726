import http from "utils/http";
import { organizations } from "constants/endpoints";

const postOrganizationInvitation = ({ email, userRoleId, message }) => {
  const POST_ENDPOINT = organizations.postOrganizationInvitation();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_ENDPOINT}`, {
    data: {
      email,
      userRoleId,
      message,
    },
  });
};

export default postOrganizationInvitation;
