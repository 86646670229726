import cadToGodeSchema from "./templates/cadToGcodeSchema";
import camToGodeSchema from "./templates/camToGcodeSchema";
import gcodeToGodeSchema from "./templates/gcodeToGcodeSchema";
import toolPathSchema from "./templates/toolPathSchema";
import sessionTypes from "constants/sessionTypes";

const sessionSchemaProvider = (sessionType) => {
  let schema = camToGodeSchema;

  if (sessionType === sessionTypes.CAD) {
    schema = cadToGodeSchema;
  }
  if (sessionType === sessionTypes.CAM) {
    schema = camToGodeSchema;
  }
  if (sessionType === sessionTypes.GCODE) {
    schema = gcodeToGodeSchema;
  }
  if (sessionType === sessionTypes.TOOLPATH) {
    schema = toolPathSchema;
  }

  return schema;
};

export default sessionSchemaProvider;
