import http from "utils/http";
import { cuttingTools } from "constants/endpoints";

const deleteSessionCuttingToolById = ({ toolId }) => {
  const ENDPOINT = cuttingTools.deleteSessionCuttingToolById({
    toolId,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.delete(`${API_BASE_URL}${ENDPOINT}`);
};

export default deleteSessionCuttingToolById;
