import React from "react";
import postNotifyMeDashboard from "services/postNotifyMe";
import notify from "utils/toastMsg";
import "./index.css";

const NotifyMeModal = ({ setOpenNotificationModal, productName }) => {
  const handleNotifyUser = async (event) => {
    event.preventDefault();
    try {
      await postNotifyMeDashboard({ productName });
      notify("Success! You've been added to our notification list ✅");
      setOpenNotificationModal(false);
    } catch (error) {
      notify(`${error.message} ❌`);
      // eslint-disable-next-line no-console
      console.error(error.message);
    }
  };

  return (
    <div className="notify-content mt-1">
      <div className="d-flex justify-content-between align-items-baseline">
        <h4 className="notify-header">We'll be in touch</h4>
        <button
          className="close"
          type="button"
          onClick={() => setOpenNotificationModal(false)}
        >
          &times;
        </button>
      </div>
      <div className="d-flex flex-column mt-3">
        <button
          type="submit"
          className="btn notify-btn"
          onClick={handleNotifyUser}
        >
          Get Notified
        </button>
      </div>
    </div>
  );
};

export default NotifyMeModal;
