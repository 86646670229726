import http from "utils/http";
import { predictiveMaintenance } from "constants/endpoints";

const putMachines = ({
  machineId,
  machineToolTypeId,
  machineName,
  machineModel,
  maxSpindleSpeed,
  maxPower,
  maxTorque,
}) => {
  const ENDPOINT = predictiveMaintenance.putMachines(machineId);
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.put(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      machineToolTypeId,
      machineName,
      machineModel,
      maxSpindleSpeed,
      maxPower,
      maxTorque,
    },
  });
};

export default putMachines;
