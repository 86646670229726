import * as yup from "yup";

const checkIfConstraintIsNegativeOrPositive = ({
  inputValue,
  label,
  constraint,
}) => {
  if (label.includes("positive")) {
    return parseFloat(inputValue) > constraint;
  } else if (label.includes("negative")) {
    return parseFloat(inputValue) < constraint;
  } else {
    throw new Error(`Unknown label encountered while validation ${label}`);
  }
};

const feedRateOptimizationSchema = ({ digibasicResponse }) =>
  yup.object().shape({
    optimizationParameters: yup.object().shape({
      digibasicOptimisationConstraintId: yup
        .string()
        .label("Optimisation Constraint Id")
        .typeError("Optimisation Constraint should be a number"),
      selectedConstraintMagnitude: yup
        .string()
        .label("Constraint Magnitude")
        .typeError("Selected Constraint Magnitude should be a string")
        .test(
          "isSelectedConstraintMagnitudeValid",
          `Magnitude value should be equal or bigger than the minimum allowed.`,
          function (value) {
            const { digibasicOptimisationConstraintId } = this.parent;
            const { OptimisationValidationRules } = digibasicResponse;

            switch (digibasicOptimisationConstraintId) {
              case "1":
                return checkIfConstraintIsNegativeOrPositive({
                  inputValue: value,
                  label: OptimisationValidationRules.Min_FeedForcetxt,
                  constraint: OptimisationValidationRules.Min_FeedForce,
                });

              case "2":
                return checkIfConstraintIsNegativeOrPositive({
                  inputValue: value,
                  label: OptimisationValidationRules.Min_CrossFeedForcetxt,
                  constraint: OptimisationValidationRules.Min_CrossFeedForce,
                });
              case "3":
                return checkIfConstraintIsNegativeOrPositive({
                  inputValue: value,
                  label: OptimisationValidationRules.Min_AxialForcetxt,
                  constraint: OptimisationValidationRules.Min_AxialForce,
                });
              case "4":
                return checkIfConstraintIsNegativeOrPositive({
                  inputValue: value,
                  label: OptimisationValidationRules.Min_SideForcetxt,
                  constraint: OptimisationValidationRules.Min_SideForce,
                });
              case "5":
                return checkIfConstraintIsNegativeOrPositive({
                  inputValue: value,
                  label: OptimisationValidationRules.Min_Torquetxt,
                  constraint: OptimisationValidationRules.Min_Torque,
                });
              case "6":
                return checkIfConstraintIsNegativeOrPositive({
                  inputValue: value,
                  label: OptimisationValidationRules.Min_Powertxt,
                  constraint: OptimisationValidationRules.Min_Power,
                });
              case "7":
                return checkIfConstraintIsNegativeOrPositive({
                  inputValue: value,
                  label: OptimisationValidationRules.Min_MRRtxt,
                  constraint: OptimisationValidationRules.Min_MRR,
                });
              default:
                return false;
            }
          }
        ),
    }),
  });

export default feedRateOptimizationSchema;
