import React from "react";
import { useState } from "react";
import FileDownload from "components/FileDownload";
// import GetFileAsJson from "components/GetFileAsJson";
import { useParams } from "react-router-dom";
import useAPI from "effects/useAPI";
import getMachineExtensionsByMachineId from "services/getMachineExtensionsByMachineId";
import getMachineByMachineId from "services/getMachineByMachineId";
import deleteFrfDataByExtensionId from "services/frfDatabase/deleteMaintenanceFrfDataById";
import notify from "utils/toastMsg";
import ToolingUploadInfo from "./components/ToolingUploadInfo";

function MachineConfigurations() {
  const [showMenu, setShowMenu] = useState(false);
  const { machineId } = useParams();

  const [
    machineByMachineIdLoading,
    machineByMachineIdError,
    machineByMachineIdResponse,
  ] = useAPI(() => getMachineByMachineId({ machineId }));

  const { machineDetails } = machineByMachineIdResponse;

  const [
    machineExtensionsByMachineIdLoading,
    machineExtensionsByMachineIdError,
    machineExtensionsByMachineIdResponse,
    fetchAllMachineExtensionsByMachineId,
  ] = useAPI(() => getMachineExtensionsByMachineId({ machineId }));

  const { machineExtensionDataByMachineMachineId } =
    machineExtensionsByMachineIdResponse;

  const handleDeleteFrfDataByExtensionId = async (machineExtensionId) => {
    try {
      if (machineExtensionDataByMachineMachineId.length <= 1) {
        notify("Machine should have at least 1 frf data. ❌");
      } else {
        await deleteFrfDataByExtensionId({
          machineExtensionId,
        });
        notify("Frf Data Deleted Successfully. ✅ ");
        fetchAllMachineExtensionsByMachineId();
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <div
      className="container"
      style={{ marginTop: "10em", marginBottom: "10em" }}
    >
      {showMenu ? (
        <>
          {machineExtensionsByMachineIdLoading ? (
            <p>Loading Machine Extensions...</p>
          ) : machineExtensionsByMachineIdError ? (
            <p>Error Loading Machine Extensions</p>
          ) : (
            <ToolingUploadInfo
              setShowMenu={setShowMenu}
              fetchAllMachineExtensionsByMachineId={
                fetchAllMachineExtensionsByMachineId
              }
            />
          )}
        </>
      ) : (
        <>
          <h1 className="text-center mb-5">Machine Configurations</h1>
          <h1>
            {machineByMachineIdError
              ? "Error Loading Machine Name"
              : machineByMachineIdLoading
              ? "Loading Machine Name..."
              : `Machine Name: ${machineDetails.machineName}`}
          </h1>
          <div className="d-flex justify-content-end">
            {machineExtensionsByMachineIdLoading ? (
              <button
                className="btn btn-primary waves-effect m-3"
                style={{ width: "12em" }}
              >
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </button>
            ) : machineExtensionsByMachineIdError ? (
              <button
                className="btn btn-primary waves-effect m-3"
                style={{ width: "12em" }}
              >
                Error loading Component...
              </button>
            ) : machineExtensionDataByMachineMachineId.length === 0 ? (
              <div></div>
            ) : (
              <button
                className="btn btn-primary waves-effect m-3"
                style={{ width: "12em" }}
                onClick={() => setShowMenu(true)}
              >
                Upload Data
              </button>
            )}
          </div>
          <h1 className="text-center">Data Table</h1>
          <div className="d-flex justify-content-center">
            <table
              id="datatable"
              className="table table-bordered text-center m-5"
              role="grid"
              aria-describedby="datatable_info"
            >
              {machineExtensionsByMachineIdLoading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : machineExtensionsByMachineIdError ? (
                <div>Error Loading Machine Extensions</div>
              ) : machineExtensionDataByMachineMachineId.length === 0 ? (
                <div>
                  <h2>You don't have any machine configuration upload one</h2>
                  <button
                    className="btn btn-primary waves-effect m-3"
                    style={{ width: "12em" }}
                    onClick={() => setShowMenu(true)}
                  >
                    Upload Data
                  </button>
                </div>
              ) : (
                <div>
                  <thead>
                    <tr>
                      <th>Machine Extension Id</th>
                      <th>Spindle Id</th>
                      <th>Tool Holder Id</th>
                      <th>Cutting Tool Id</th>
                      <th>Machine Configuration Id</th>
                      <th>Sticking Out Length</th>
                      <th>Frf Datum Id</th>
                      <th>Spindle Name</th>
                      {/* <th>Spindle Meta</th> */}
                      <th>ToolHolder Name</th>
                      {/* <th>Tool Holder Meta</th> */}
                      <th>Cutting Tool Name</th>
                      <th>Cutting Tool Data</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {machineExtensionDataByMachineMachineId.map(
                      (item, index) => (
                        <tr key={index}>
                          <td>{item.machineExtensionId}</td>
                          <td>{item.spindleId}</td>
                          <td>{item.toolHolderId}</td>
                          <td>{item.cuttingToolId}</td>
                          <td>{item.machineConfigurationId}</td>
                          <td>{item.stickingOutLength}</td>
                          <td>
                            <button
                              className="btn btn-danger btn-sm mb-2"
                              style={{ width: "13em" }}
                              onClick={() =>
                                handleDeleteFrfDataByExtensionId(
                                  item.machineExtensionId
                                )
                              }
                            >
                              Delete Frf Datum Id: {item.frfDatumId}
                              <i className="ion ion-md-trash ml-1" />
                            </button>
                            <FileDownload
                              buttonText={`Download XX`}
                              url={item.frfXxUrl}
                              style={{ marginBottom: "5px" }}
                            />
                            <FileDownload
                              buttonText={`Download XY`}
                              url={item.frfXyUrl}
                              style={{ marginBottom: "5px" }}
                            />
                            <FileDownload
                              buttonText={`Download YX`}
                              url={item.frfYxUrl}
                              style={{ marginBottom: "5px" }}
                            />
                            <FileDownload
                              buttonText={`Download YY`}
                              url={item.frfYyUrl}
                            />
                            {/* <GetFileAsJson url={item.frfXxUrl} /> */}
                          </td>
                          <td>{item.spindleName}</td>
                          {/* <td>
                            {" "}
                            <div id="accordion">
                              <div className="card mb-0">
                                <div className="card-header" id="headingOne">
                                  <h5 className="mb-0 mt-0 font-14">
                                    <a
                                      data-toggle="collapse"
                                      data-parent="#accordion"
                                      href="#collapseOne"
                                      aria-expanded="false"
                                      aria-controls="collapseOne"
                                      className="text-dark collapsed"
                                    >
                                      Click To See Meta
                                    </a>
                                  </h5>
                                </div>
                                <div
                                  id="collapseOne"
                                  className="collapse"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordion"
                                  style={{}}
                                >
                                  <div className="card-body">
                                    {item.spindleMeta}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td> */}
                          <td>{item.toolHolderName}</td>
                          {/* <td>
                            {" "}
                            <div id="accordion">
                              <div className="card mb-0">
                                <div className="card-header" id="headingOne">
                                  <h5 className="mb-0 mt-0 font-14">
                                    <a
                                      data-toggle="collapse"
                                      data-parent="#accordion"
                                      href="#collapseOne"
                                      aria-expanded="false"
                                      aria-controls="collapseOne"
                                      className="text-dark collapsed"
                                    >
                                      Click To See Meta
                                    </a>
                                  </h5>
                                </div>
                                <div
                                  id="collapseOne"
                                  className="collapse"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordion"
                                  style={{}}
                                >
                                  <div className="card-body">
                                    <pre>
                                      {JSON.stringify(
                                        item.toolHolderMeta,
                                        null,
                                        2
                                      )}
                                    </pre>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td> */}
                          <td>{item.cuttingToolName}</td>
                          <td>
                            {" "}
                            <div id="accordion">
                              <div className="card mb-0">
                                <div className="card-header" id="headingOne">
                                  <h5 className="mb-0 mt-0 font-14">
                                    <a
                                      data-toggle="collapse"
                                      data-parent="#accordion"
                                      href="#collapseOne"
                                      aria-expanded="false"
                                      aria-controls="collapseOne"
                                      className="text-dark collapsed"
                                    >
                                      Click To See Data
                                    </a>
                                  </h5>
                                </div>
                                <div
                                  id="collapseOne"
                                  className="collapse"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordion"
                                  style={{}}
                                >
                                  <div className="card-body">
                                    {item.cuttingToolMeta &&
                                      Object.entries(item.cuttingToolMeta).map(
                                        ([key, value]) => (
                                          <p key={key}>
                                            <strong>{key}:</strong> {value}
                                          </p>
                                        )
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <th>{item.createdAt}</th>
                        </tr>
                      )
                    )}
                  </tbody>
                </div>
              )}
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default MachineConfigurations;
