import http from "utils/http";
import { digiBasic } from "constants/endpoints";

const postDigiBasicFeedRateOptimisation = ({
  digibasicOptimisationConstraintId,
  selectedConstraintMagnitude,
  digibasicToolId,
}) => {
  const POST_USER_ENDPOINT = digiBasic.postDigiBasicFeedRateOptimisation();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_USER_ENDPOINT}`, {
    data: {
      digibasicOptimisationConstraintId,
      selectedConstraintMagnitude,
      digibasicToolId,
    },
  });
};

export default postDigiBasicFeedRateOptimisation;
