import React from "react";
import "./styles.css";
import { LineChart, Line, XAxis, YAxis, Tooltip, Brush } from "recharts";

const ProcessPrediction = ({ digiStabilityResponse, setSelectedOption }) => {
  const transformDataForChart = () => {
    const transformedData = Object.entries(
      digiStabilityResponse?.Stability
    ).map(([name, Stability_Boundary]) => ({
      name,
      Stability_Boundary,
    }));
    return transformedData;
  };

  const CustomTooltip = ({ payload }) => {
    if (payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid grey",
            backgroundColor: "white",
            width: "200px",
          }}
        >
          <p className="label">Stability Boundary</p>
          <p
            style={{ color: "#3182bd" }}
          >{`Spindle Speed (RPM): ${payload[0].payload.name}`}</p>
          <p
            style={{ color: "#3182bd" }}
          >{`Depth Of Cut (mm): ${payload[0].value.toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {digiStabilityResponse === undefined ? (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className="container mt-2" style={{ background: "none" }}>
          <h2 className="d-flex justify-content-center mb-4">
            Process Prediction
          </h2>
          <div className="d-flex justify-content-between">
            <div className="row">
              {/* First Column */}
              <div
                className="highlight-bar-charts"
                style={{ userSelect: "none", height: "auto" }}
              >
                <LineChart
                  width={650}
                  height={400}
                  data={transformDataForChart()}
                  syncId="anyId"
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    height={50}
                    label={{
                      value: "Spindle Speed (rpm)",
                      position: "right",
                      dx: -120,
                    }}
                  />
                  <YAxis
                    width={60}
                    label={{
                      value: "Depth Of Cut (mm)",
                      position: "insideTopLeft",
                      angle: -90,
                      dy: 100,
                    }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Line
                    type="monotone"
                    dataKey="Stability_Boundary"
                    dot={false}
                    activeDot={false}
                    animationDuration={300}
                  />
                  <Brush dataKey={"name"} width={550} />
                </LineChart>
                <button
                  className="btn btn-info waves-effect waves-light ml-5"
                  style={{
                    width: "7rem",
                    backgroundColor: "#3498DB",
                    color: "#fff",
                    marginTop: "2.7rem",
                  }}
                  onClick={() => setSelectedOption("History")}
                  type="button"
                >
                  Go to History
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <div className="guidance-text">
                <div className="m-4">
                  <p>
                    Obtain an optimal combination of depth of cut and spindle
                    speed whilst avoiding chatter vibrations.
                  </p>
                  <p>
                    You can trust our predicted spindle speeds, just bear in
                    mind that due to the natural variations in material
                    properties, the depth of cut predictions may need fine
                    tuning.
                  </p>
                  <p>
                    When you move the cursor over the plot, a pop up window will
                    appear showing your selected depth of cut and spindle speed.
                  </p>
                  <p>
                    The slider below the plot helps you narrow down the range of
                    spindle speeds you are viewing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProcessPrediction;
