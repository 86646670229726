import { DataTable, TableProvider } from "@xest-ui/data-table-antd";

const XestTable = (props) => {
  return (
    <TableProvider {...props}>
      <DataTable />
    </TableProvider>
  );
};

export default XestTable;
