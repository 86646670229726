import * as yup from "yup";

const handleMachineToolSubmitSchema = yup.object().shape({
  machineName: yup
    .string()
    .required()
    .max(17, "Machine Name must be at most 17 characters.")
    .label("Machine Tool Name")
    .typeError("The Machine Name must be at most 17 characters."),
  machineModel: yup
    .string()
    .required()
    .max(17, "Machine Tool Name must be at most 17 characters.")
    .label("Machine Model")
    .typeError("The Machine Model must be at most 17 characters."),
  maxSpindleSpeed: yup
    .number()
    .required()
    .positive()
    .label("Max Spindle Speed")
    .typeError("The Max Spindle Speed is required."),
  maxPower: yup
    .number()
    .required()
    .positive()
    .label("Max Power")
    .typeError("The Max Power is required."),
  maxTorque: yup
    .number()
    .required()
    .positive()
    .label("Max Torque")
    .typeError("The Max Torque is required."),
  machineToolTypeId: yup
    .string()
    .required()
    .label("Machine Tool Type")
    .typeError("The Machine Tool Type is required."),
});

export default handleMachineToolSubmitSchema;
