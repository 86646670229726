import React from "react";
import { Route, matchPath } from "react-router-dom";

const RenderRouteIf = ({ children, onRoutes = [], notOnRoutes = [] }) => {
  return (
    <Route
      render={({ location }) => {
        const currentPath = location.pathname;

        const matchesNotOnRoutes = notOnRoutes.some((path) =>
          matchPath(currentPath, { path, exact: true })
        );

        if (matchesNotOnRoutes) {
          return null;
        }

        if (onRoutes.length > 0) {
          const matchesOnRoutes = onRoutes.some((path) =>
            matchPath(currentPath, { path, exact: true })
          );
          if (matchesOnRoutes) {
            return children;
          } else {
            return null;
          }
        }

        return children;
      }}
    />
  );
};

export default RenderRouteIf;
