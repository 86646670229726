import React, { useState } from "react";
import Authentication from "services/Authentication";
import notify from "utils/toastMsg";

let auth = new Authentication();

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await auth.forgotPassword({ email });
      setMessage(
        "Password reset initiated. Check your email for the verification code."
      );
      notify(`Redirecting to reset password page... ✅`);
      setTimeout(() => {
        window.location.href = "/reset-password";
      }, 3500);
    } catch (err) {
      setError("Failed to initiate password reset.");
      notify(`Failed to initiate password reset. ❌`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <img className="login-logo" src="assets/images/ll2.png" alt="logo" />
      <div
        className="mt-5"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="col-lg-5 mt-5">
          <div className="card mb-0 mt-5">
            <div className="card-body">
              <div className="mb-4 text-center">
                <h3 className="mb-0" style={{ fontFamily: "Poppins" }}>
                  Reset Password
                </h3>
                <p
                  className="my-2 mt-4"
                  style={{ fontFamily: "Montserrat", fontSize: "16px" }}
                >
                  Enter your email and instructions will be sent to you!
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                <input
                  className="form-control mb-3"
                  style={{ fontFamily: "Montserrat" }}
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={loading}
                />
                <button
                  className="btn btn-primary btn-lg w-100"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </form>
              {message && <p>{message}</p>}
              {error && <p>{error}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
