const optimizationConstraints = {
  TIME: { label: "Time", value: 1 },
  QUALITY: { label: "Quality", value: 2 },
  FORCE_PREDICTION: { label: "Force Prediction", value: 3 },
  MILING_FEED_RATE: { label: "Milling Feed Rate Optimisation", value: 4 },
  MILING_CHATTER_VIBRATION: {
    label: "Milling Chatter Vibration Optimisation",
    value: 5,
  },
};

export default optimizationConstraints;
