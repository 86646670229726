import { useFormContext } from "react-hook-form";
import get from "utils/get";

const InputNonOptimized = ({
  formMethods,
  name,
  value,
  watchedFieldValue,
  ...rest
}) => {
  const { register, formState } = formMethods;

  const fieldError = Boolean(get(formState.errors, name));
  return (
    <input
      {...register(name)}
      value={value}
      {...rest}
      {...(fieldError ? { style: { borderColor: "#a01" } } : {})}
    />
  );
};

const NestedInputContainer = (props) => {
  const formMethods = useFormContext();
  const watch = formMethods.watch(props.name);

  return (
    <InputNonOptimized
      formMethods={formMethods}
      {...props}
      watchedFieldValue={watch}
    />
  );
};

export default NestedInputContainer;
