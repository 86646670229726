function getColor(parameterType) {
  const regex = /^.*_(\d+)$/;
  const match = regex.exec(parameterType);
  if (match && match[1] >= 1 && match[1] <= 30) {
    const colorNumber = parseInt(match[1]);
    const colors = [
      "#000",
      "#00f",
      "#f00",
      "#f0f",
      "#0f0",
      "#939799",
      "#FFA500",
      "#A52A2A",
      "#ADD8E6",
      "#9B870C",
    ];
    const colorIndex = (colorNumber - 1) % colors.length;
    return colors[colorIndex];
  }

  return parameterType === "Feed_Force"
    ? "#000"
    : parameterType === "CrossFeed_Force"
    ? "#00f"
    : parameterType === "Axial_Force"
    ? "#f00"
    : parameterType === "Side_Force"
    ? "#f0f"
    : parameterType === "Torque"
    ? "#000"
    : "#00f";
}

export default getColor;
