import React, { useState } from "react";
import { Link } from "react-router-dom";
import UploadData from "./components/UploadData";
import FileDownload from "components/FileDownload";
import {
  LineChart,
  // Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useParams } from "react-router-dom";
import useAPI from "effects/useAPI";
import getMachineExtensionsByMachineId from "services/getMachineExtensionsByMachineId";
import getFrfDataByMachineId from "services/frfDatabase/getFrfDataByMachineId";
import getMachineByMachineId from "services/getMachineByMachineId";
import deleteFrfDataByExtensionId from "services/frfDatabase/deleteMaintenanceFrfDataById";
import notify from "utils/toastMsg";

function SpindleHealthMonitoring() {
  const [showMenu, setShowMenu] = useState(false);
  const { machineId } = useParams();

  const [
    machineByMachineIdLoading,
    machineByMachineIdError,
    machineByMachineIdResponse,
  ] = useAPI(() => getMachineByMachineId({ machineId }));

  const [
    machineExtensionsByMachineIdLoading,
    machineExtensionsByMachineIdError,
    machineExtensionsByMachineIdResponse,
  ] = useAPI(() => getMachineExtensionsByMachineId({ machineId }));

  const [
    frfDataByMachineIdLoading,
    frfDataByMachineIdError,
    frfDataByMachineIdResponse,
    fetchAllFrfDataByMachineId,
  ] = useAPI(() => getFrfDataByMachineId({ machineId }));

  const { machineDetails } = machineByMachineIdResponse;
  const { frfDataByMachineId } = frfDataByMachineIdResponse;

  const handleDeleteFrfDataByExtensionId = async (machineExtensionId) => {
    try {
      if (frfDataByMachineId.length <= 1) {
        notify("Machine should have at least 1 frf data. ❌");
      } else {
        await deleteFrfDataByExtensionId({
          machineExtensionId,
        });
        notify("Frf Data Deleted Successfully. ✅ ");
        fetchAllFrfDataByMachineId();
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <div
      className="container"
      style={{ marginTop: "10em", marginBottom: "10em" }}
    >
      <h1 className="text-center">FRF Data</h1>
      <h1 className="mt-5" style={{ marginLeft: "3em" }}>
        {machineByMachineIdError
          ? "Error Loading Machine Name"
          : machineByMachineIdLoading
          ? "Loading Machine Name..."
          : `Machine Name: ${machineDetails.machineName}`}
      </h1>
      {frfDataByMachineIdError ? (
        <tr>
          <td colSpan="3">Error Loading FRF data</td>
        </tr>
      ) : frfDataByMachineIdLoading ? (
        <tr>
          <td colSpan="3">Loading FRF Data...</td>
        </tr>
      ) : frfDataByMachineIdResponse && frfDataByMachineId.length === 0 ? (
        <div className="d-flex flex-column justify-content-center align-items-center mt-5">
          <h1 className="text-center">Upload Machine Configuration First</h1>
          <Link
            to={`/machine-configurations/${machineId}`}
            className="btn btn-primary waves-effect m-3 mt-4"
            style={{ width: "12em", fontSize: "1.2em" }}
          >
            Go to Machine Configurations
          </Link>
        </div>
      ) : (
        <>
          {" "}
          <div className="d-flex row justify-content-center">
            <div
              className="d-flex flex-column justify-content-center"
              style={{ maxWidth: "3em" }}
            >
              <button
                className="btn btn-info waves-effect m-3"
                style={{ width: "4em" }}
              >
                XX
              </button>
              <button
                className="btn btn-info waves-effect m-3"
                style={{ width: "4em" }}
              >
                YY
              </button>
              <button
                className="btn btn-info waves-effect m-3"
                style={{ width: "4em" }}
              >
                XY
              </button>
              <button
                className="btn btn-info waves-effect m-3"
                style={{ width: "4em" }}
              >
                YX
              </button>
            </div>
            <div className="d-flex flex-column m-5">
              <LineChart
                width={800}
                height={200}
                margin={{ top: 25, right: 30, left: 10, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="degree"
                  tickCount={1}
                  interval={39}
                  label={{
                    value: "Chart Data",
                    position: "right",
                    dx: -93,
                    dy: 12,
                  }}
                />
                <YAxis />
                <Tooltip />
                <Legend />
              </LineChart>
              <LineChart
                width={800}
                height={200}
                margin={{ top: 25, right: 30, left: 10, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="degree"
                  tickCount={1}
                  interval={39}
                  label={{
                    value: "Chart Data",
                    position: "right",
                    dx: -93,
                    dy: 12,
                  }}
                />
                <YAxis />
                <Tooltip />
                <Legend />
              </LineChart>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {machineExtensionsByMachineIdLoading ? (
              <button
                className="btn btn-primary waves-effect m-3"
                style={{ width: "12em" }}
              >
                Loading Component...
              </button>
            ) : machineExtensionsByMachineIdError ? (
              <button
                className="btn btn-primary waves-effect m-3"
                style={{ width: "12em" }}
              >
                Error Loading Component...
              </button>
            ) : (
              <button
                className="btn btn-primary waves-effect m-3"
                style={{ width: "12em" }}
                onClick={() => setShowMenu(true)}
              >
                Upload New FRF Data
              </button>
            )}
          </div>
          <h1 className="text-center">History</h1>
          <div className="d-flex justify-content-center">
            <table
              id="datatable"
              className="table table-bordered text-center m-5"
              role="grid"
              aria-describedby="datatable_info"
            >
              <thead>
                <tr>
                  <th>Machine Extension Id</th>
                  <th>Upload Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {frfDataByMachineIdError ? (
                  <tr>
                    <td colSpan="3">Error Loading FRF data</td>
                  </tr>
                ) : frfDataByMachineIdLoading ? (
                  <tr>
                    <td colSpan="3">Loading FRF Data...</td>
                  </tr>
                ) : frfDataByMachineIdResponse &&
                  frfDataByMachineId.length > 0 ? (
                  frfDataByMachineId.filter(
                    (data) => data.frfXxUrl !== "dummyUrl"
                  ).length > 0 ? (
                    frfDataByMachineId
                      .filter((data) => data.frfXxUrl !== "dummyUrl")
                      .map((data, frfDatumId) => (
                        <tr key={frfDatumId}>
                          <td>{data.machineExtensionId}</td>
                          <td>{data.createdAt}</td>
                          <td>
                            <button
                              className="btn btn-danger btn-sm mr-4"
                              style={{ width: "7em" }}
                              onClick={() =>
                                handleDeleteFrfDataByExtensionId(
                                  data.machineExtensionId,
                                  data.machineId
                                )
                              }
                            >
                              Delete
                              <i className="ion ion-md-trash ml-1" />
                            </button>
                            <FileDownload url={data.frfXxUrl} />
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="3">
                        <h1>No FRF Data Available...</h1>
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan="3">
                      <h1>No FRF Data Available...</h1>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {showMenu && (
            <>
              {machineExtensionsByMachineIdLoading ? (
                <p>Loading Machine Extensions...</p>
              ) : machineExtensionsByMachineIdError ? (
                <p>Error Loading Machine Extensions</p>
              ) : (
                <UploadData
                  setShowMenu={setShowMenu}
                  machineId={machineId}
                  machineExtensionsByMachineIdResponse={
                    machineExtensionsByMachineIdResponse
                  }
                  fetchAllFrfDataByMachineId={fetchAllFrfDataByMachineId}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default SpindleHealthMonitoring;
