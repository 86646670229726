import React, { useRef, useEffect } from "react";
import { useState } from "react";
import useAPI from "effects/useAPI";
import getDigibasicFeedRate from "services/digiBasic/getDigibasicFeedRate";
import LatestSubmissionsTable from "./components/LatestSubmissionsTable";
import {
  MATERIAL_SELECTION,
  MILLING_TYPE_SELECTION,
} from "./constants/constants";
import "./index.css";

function History() {
  const targetTableRef = useRef("");
  const [feedRateToolId, setFeedRateToolId] = useState();
  const [clickedRowIndex, setClickedRowIndex] = useState(null);
  const [selectedToolData, setSelectedToolData] = useState();
  const [selectedFeedRateData, setSelectedFeedRateData] = useState();

  const [
    loadingDigibasicFeedRate,
    errorDigibasicFeedRate,
    digibasicFeedRateResponse,
  ] = useAPI(() => getDigibasicFeedRate({ feedRateToolId }), [feedRateToolId]);

  useEffect(() => {
    if (
      !loadingDigibasicFeedRate &&
      !errorDigibasicFeedRate &&
      digibasicFeedRateResponse &&
      digibasicFeedRateResponse.digibasicFeedRate.length > 0
    ) {
      setSelectedFeedRateData(digibasicFeedRateResponse.digibasicFeedRate[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digibasicFeedRateResponse, clickedRowIndex]);

  return (
    <>
      <div className="history-container">
        <h1 className="d-flex justify-content-center mb-4">History</h1>
        <LatestSubmissionsTable
          setFeedRateToolId={setFeedRateToolId}
          setSelectedToolData={setSelectedToolData}
          setClickedRowIndex={setClickedRowIndex}
        />
        <div className="details-wrapper">
          <div>
            <div className="table-wrapper p-3" style={{ background: "#fff" }}>
              <div className="d-flex justify-content-center p-3">
                <h3>Details</h3>
              </div>
              <div>
                <p className="text-bold">
                  Submission: {selectedToolData?.toolInputRefNo}
                </p>
              </div>
              <table
                id="datatable"
                className="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline text-center"
                role="grid"
                aria-describedby="datatable_info"
                ref={targetTableRef}
              >
                <tr>
                  <td colSpan={2}>
                    <h6>Input Parameters</h6>
                  </td>
                  <td role="row" className="odd" colSpan={2}>
                    <h6>Results</h6>
                  </td>
                  <td className="sorting_1" tabIndex="0" colSpan={2}>
                    <h6>Feed rate Optimisation</h6>
                  </td>
                </tr>
                <tr>
                  <td tabIndex="0" className="sorting_1 font-weight-bold">
                    Tool Type
                  </td>
                  <td>{selectedToolData?.digibasicToolType}</td>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Max Feed Force (N)
                  </td>
                  <td>
                    {selectedFeedRateData?.original?.Max_Feed_Force ?? ""}
                  </td>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Optimisation Constraint
                  </td>
                  <td>
                    {selectedToolData?.digibasicOptimisationConstraint ?? "-"}
                  </td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Tool Diameter (mm)
                  </td>
                  <td>{selectedToolData?.diameter}</td>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Max Cross Feed Force (N)
                  </td>
                  <td>
                    {selectedFeedRateData?.original?.Max_CrossFeed_Force ?? ""}
                  </td>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Constraint Magnitude
                  </td>
                  <td>
                    {selectedToolData?.selectedConstraintMagnitude ?? "-"}
                  </td>
                </tr>
                <tr>
                  <td tabIndex="0" className="sorting_1 font-weight-bold">
                    Tool Number of Flutes
                  </td>
                  <td>
                    {selectedToolData?.fluteParameters?.helixAngle?.length ??
                      "-"}
                  </td>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Max Axial Force (N)
                  </td>
                  <td>
                    {selectedFeedRateData?.original?.Max_Axial_Force ?? ""}
                  </td>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Optimised Feed rate (mm/min)
                  </td>
                  <td>{selectedFeedRateData?.optimum?.Feedrate_New ?? ""}</td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Tool Helix Angle (deg)
                  </td>
                  <td>
                    {selectedToolData?.fluteParameters?.helixAngle?.join(
                      ", "
                    ) ?? "-"}
                  </td>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Max Side Force (N)
                  </td>
                  <td>
                    {selectedFeedRateData?.original?.Max_Side_Force ?? ""}
                  </td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Tool Pitch Angle (deg)
                  </td>
                  <td>
                    {selectedToolData?.fluteParameters?.pitchAngle?.join(
                      ", "
                    ) ?? "-"}
                  </td>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Max Torque (Nm)
                  </td>
                  <td>{selectedFeedRateData?.original?.Max_Torque ?? ""}</td>
                </tr>
                <tr>
                  <td tabIndex="0" className="sorting_1 font-weight-bold">
                    Depth of Cut (mm)
                  </td>
                  <td>{selectedToolData?.cuttingParameters?.depthOfCut}</td>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Max Power (W)
                  </td>
                  <td>{selectedFeedRateData?.original?.Max_Power ?? ""}</td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Width of cut (mm)
                  </td>
                  <td>{selectedToolData?.cuttingParameters?.widthOfCut}</td>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Max Chip Thickness (mm)
                  </td>
                  <td>
                    {selectedFeedRateData?.original?.Max_ChipThickness ?? ""}
                  </td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Milling Type
                  </td>
                  <td>
                    {MILLING_TYPE_SELECTION.find(
                      (item) =>
                        item.value ===
                        selectedToolData?.cuttingParameters
                          ?.millingTypeSelection
                    )?.label ?? ""}
                  </td>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Material Removal Rate (MRR)
                  </td>
                  <td>{selectedFeedRateData?.original?.MRR ?? ""}</td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Spindle Speed (RPM)
                  </td>
                  <td>{selectedToolData?.cuttingParameters?.spindleSpeed}</td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Cutting Speed (m/min)
                  </td>
                  <td>{selectedToolData?.cuttingParameters?.cuttingSpeed}</td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Feed rate (mm/min)
                  </td>
                  <td>{selectedToolData?.cuttingParameters?.feedRate}</td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Feed per Tooth (mm/tooth)
                  </td>
                  <td>{selectedToolData?.cuttingParameters?.feedPerTooth}</td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Material Selection
                  </td>
                  <td>
                    {selectedToolData &&
                      MATERIAL_SELECTION.find(
                        (item) =>
                          item.value ===
                          selectedToolData.cuttingParameters.materialSelection
                      )?.label}
                  </td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Rake Angle
                  </td>
                  <td>{selectedToolData?.fluteParameters?.rakeAngle}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default History;
