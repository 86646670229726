import http from "utils/http";
import { organizations } from "constants/endpoints";

const deleteInvitation = ({
  invitationShortcode,
  userOrganizationInvitationId,
}) => {
  const DELETE_ENDPOINT = organizations.deleteInvitation({
    invitationId: userOrganizationInvitationId,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.delete(`${API_BASE_URL}${DELETE_ENDPOINT}`, {
    data: {
      shortcode: invitationShortcode,
    },
  });
};

export default deleteInvitation;
