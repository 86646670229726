import { React } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import "./style.css";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

function SelectModes() {
  return (
    <div className="modal-container">
      <div className="select-modes-container">
        <h2 className="title">Select Modes</h2>
        <form className="form-wrapper">
          <div className="d-flex justify-content-center">
            <h5>Please select the modes from the graph below</h5>
          </div>
          <div>
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
          </div>
          <div>
            <table className="select-modes-table table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline text-center">
              <tr className="table-first-line">
                <td>Natural Frequency (Hz)</td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
              </tr>
              <tr className="table-second-line">
                <td>Mode</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>
          <div>
            <div className="d-flex mt-3 justify-content-around">
              <button
                className="create-btn btn-primary waves-effect"
                // onClick={() => setShowUploadNewData(false)}
              >
                Back
              </button>
              <button
                // onClick={() => handleClose()}
                className="create-btn btn-success waves-effect"
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SelectModes;
