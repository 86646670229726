import http from "utils/http";
import { frfDatabase } from "constants/endpoints";

const deleteMaintenanceFrfDataById = ({ frfDatumId }) => {
  const DELETE_ENDPOINT = frfDatabase.deleteMaintenanceFrfDataById({
    frfDatumId,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.delete(`${API_BASE_URL}${DELETE_ENDPOINT}`, {
    data: {
      frfDatumId,
    },
  });
};

export default deleteMaintenanceFrfDataById;
