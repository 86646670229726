import http from "utils/http";
import { sessions } from "constants/endpoints";

const getUserSessions = () => {
  const ENDPOINT = sessions.getUserSessions();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`);
};

export default getUserSessions;
