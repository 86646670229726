import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import {
  Form,
  InputNonOptimized,
  ErrorMessage,
  SubmitButton,
  Select,
} from "components/Forms";
import notify from "utils/toastMsg";
import postRegistrationRequest from "services/postRegistrationRequest";
import ReCAPTCHA from "react-google-recaptcha";
import registrationSchema from "./schemas/registrationSchema";
import registrationWithCredsSchema from "./schemas/registrationWithCredsSchema";
import { COUNTRY_OPTIONS } from "./constants/constants";
import "./style.css";

const Register = () => {
  const history = useHistory();
  const [invalidCredentialsError, setInvalidCredentialsError] = useState("");
  const [invitationCreds, setInvitationCreds] = useState({});
  const captchaRef = useRef(null);
  const { REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY } = process.env;
  const setIsVerified = false;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const invitation = queryParams.get("invitation");
    const email = queryParams.get("email");
    const organization = queryParams.get("organization");
    const organizationId = queryParams.get("organizationId");
    const shortCode = queryParams.get("shortCode");
    const invitationId = queryParams.get("invitationId");
    if (
      invitation &&
      email &&
      organization &&
      organizationId &&
      shortCode &&
      invitationId
    ) {
      setInvitationCreds({
        invitation,
        email,
        organization,
        organizationId,
        shortCode,
        invitationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleRegisterRequestWithInvitation = async (formValues) => {
    const { email, organizationId, invitation, shortCode, invitationId } =
      invitationCreds;
    const { firstName, lastName, password, phoneNumber, country } = formValues;
    const captcha = await captchaRef.current.getValue();
    try {
      if (!captcha) {
        throw new Error("captcha is required");
      }
      await postRegistrationRequest({
        email,
        organizationId,
        invitation,
        firstName,
        lastName,
        password,
        phoneNumber,
        country,
        captcha,
        shortCode,
        invitationId,
      });
      history.push("/email-verification");
      notify("Verification email has been successfully sent. ✅");
    } catch (error) {
      captchaRef.current.reset();
      if (error.message === "captcha is required") {
        setInvalidCredentialsError("Captcha is required");
      } else if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data)
      ) {
        const validationErrors = error.response.data
          .map((errorItem) => errorItem.message)
          .join(" ");
        setInvalidCredentialsError(validationErrors);
      } else {
        setInvalidCredentialsError("An error occurred during registration.");
      }
      // eslint-disable-next-line no-console
      console.error("Error during registration:", error);
    }
  };
  /**
   * Handles registration request with form values and captcha.
   * @async
   * @function
   * @param {Object} formValues - The form values to be submitted for registration.
   * @returns {Promise<void>} - A Promise that resolves when the registration request is successful.
   * @throws {Error} - If captcha is not provided or an error occurs during registration.
   */
  const handleRegisterRequest = async (formValues) => {
    const captcha = await captchaRef.current.getValue();

    const {
      companyName,
      country,
      discountCode,
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
    } = formValues;
    try {
      if (!captcha) {
        throw new Error("captcha is required");
      }

      await postRegistrationRequest({
        companyName,
        country,
        discountCode,
        email,
        firstName,
        lastName,
        password,
        phoneNumber,
        captcha,
      });
      history.push("/email-verification");
      notify("Verification email has been successfully sent. ✅");
    } catch (error) {
      captchaRef.current.reset();
      if (error.message === "captcha is required") {
        setInvalidCredentialsError("Captcha is required");
      } else if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data)
      ) {
        const validationErrors = error.response.data
          .map((errorItem) => errorItem.message)
          .join(" ");
        setInvalidCredentialsError(validationErrors);
      } else {
        setInvalidCredentialsError("An error occurred during registration.");
      }
      // eslint-disable-next-line no-console
      console.error("Error during registration:", error);
    }
  };

  const handleVerify = () => {
    setIsVerified(true);
  };
  return (
    <>
      <a href="https://productivemachines.co.uk/">
        <img className="login-logo" src="assets/images/ll2.png" alt="logo" />
      </a>
      <div className="row align-items-center" style={{ marginBottom: "5rem" }}>
        <div className="col-">
          <div className="card" style={{ marginTop: "4rem" }}>
            <div className="card-body">
              <div className="p-1">
                <h4 className="register-header">Create Account</h4>
                <Form
                  initialValues={{
                    country: 187,
                  }}
                  validationSchema={
                    Object.keys(invitationCreds).length > 0
                      ? registrationWithCredsSchema
                      : registrationSchema
                  }
                  onSubmit={
                    Object.keys(invitationCreds).length > 0
                      ? handleRegisterRequestWithInvitation
                      : handleRegisterRequest
                  }
                >
                  <div className="d-flex register-wrapper">
                    <div className="input-field-wrapper">
                      <div className="form-group">
                        <label className="register-input-title">
                          First Name *
                        </label>
                        <div>
                          <InputNonOptimized
                            name="firstName"
                            type="text"
                            className="form-control register-input"
                            placeholder="Type first name"
                          />
                        </div>
                        <ErrorMessage name="firstName" />
                      </div>
                      <div className="form-group">
                        <label className="register-input-title">
                          Last Name *
                        </label>
                        <div>
                          <InputNonOptimized
                            name="lastName"
                            type="text"
                            className="form-control register-input"
                            placeholder="Type last name"
                          />
                        </div>
                        <ErrorMessage name="lastName" />
                      </div>
                      <div className="form-group">
                        <label className="register-input-title">E-mail *</label>
                        <div>
                          <InputNonOptimized
                            name="email"
                            type="email"
                            className="form-control register-input"
                            parsley-type="email"
                            value={invitationCreds.email}
                            placeholder={
                              invitationCreds.email || "Enter a valid e-mail"
                            }
                            disabled={invitationCreds.email}
                          />
                        </div>
                        <ErrorMessage name="email" />
                      </div>
                      <div className="form-group">
                        <label className="register-input-title">
                          Country *
                        </label>
                        <div>
                          <Select
                            name="country"
                            type="text"
                            className="form-control form-control-size-add-new-machine register-input"
                            placeholder="Select Country"
                            style={{ width: "19rem" }}
                            options={COUNTRY_OPTIONS}
                          />
                        </div>
                        <ErrorMessage name="country" />
                      </div>
                      <div className="form-group">
                        <label className="register-input-title">
                          Phone Number *
                        </label>
                        <div>
                          <InputNonOptimized
                            name="phoneNumber"
                            type="tel"
                            className="form-control register-input"
                            placeholder="Enter phone number"
                          />
                        </div>
                        <ErrorMessage name="phoneNumber" />
                      </div>
                      <div className="form-group">
                        <label className="register-input-title">
                          Company *
                        </label>
                        <div>
                          <InputNonOptimized
                            name="companyName"
                            type="text"
                            className="form-control register-input"
                            value={invitationCreds.organization}
                            placeholder={
                              invitationCreds.organization ||
                              "Enter Company Name"
                            }
                            disabled={invitationCreds.organization}
                            // value={"Siemens"}
                            // placeholder={"Siemens"}
                            // disabled={true}
                          />
                        </div>
                        <ErrorMessage name="companyName" />
                      </div>
                      <div className="form-group">
                        <label className="register-input-title">
                          Password *
                        </label>
                        <div>
                          <InputNonOptimized
                            name="password"
                            type="password"
                            className="form-control register-input"
                            placeholder="Password"
                            autoComplete="new-password"
                          />
                        </div>
                        <div style={{ width: "19rem" }}>
                          <ErrorMessage name="password" />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="register-input-title">
                          Confirm Password *
                        </label>
                        <div>
                          <InputNonOptimized
                            name="confirmPassword"
                            type="password"
                            className="form-control register-input"
                            placeholder="Password"
                          />
                        </div>
                        <div style={{ width: "19rem" }}>
                          <ErrorMessage name="confirmPassword" />
                        </div>
                      </div>
                      {/* <div className="form-group">
                        <label className="register-input-title">
                          Discount Code
                        </label>
                        <div>
                          <InputNonOptimized
                            name="discountCode"
                            type="text"
                            className="form-control register-input"
                            placeholder="Discount Code"
                          />
                        </div>
                        <ErrorMessage name="discountCode" />
                      </div> */}
                    </div>
                    {/* <div className="ml-2 mr-2 input-field-wrapper"></div> */}
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <ReCAPTCHA
                      sitekey={REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                      onChange={handleVerify}
                      ref={captchaRef}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    {invalidCredentialsError && (
                      <p className="text-danger mb-0">
                        {invalidCredentialsError}
                      </p>
                    )}
                  </div>
                  <div className="mt-4 d-flex justify-content-around">
                    <button
                      style={{ minWidth: "77.58px", borderRadius: "12px" }}
                      className="btn btn-outline-dark waves-effect waves-light"
                      onClick={() => {
                        history.push(`/`);
                      }}
                    >
                      Back
                    </button>
                    <SubmitButton className="btn btn-lg waves-effect waves-light register-button">
                      Create An Account
                    </SubmitButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
