import http from "utils/http";
import { predictiveMaintenance } from "constants/endpoints";

const postMachine = (payload) => {
  const POST_USER_ENDPOINT = predictiveMaintenance.postMachine();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_USER_ENDPOINT}`, {
    data: {
      ...payload,
    },
  });
};

export default postMachine;
