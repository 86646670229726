import React, { useState, useLayoutEffect, useEffect } from "react";
import notify from "utils/toastMsg";
import Tooling from "./components/Tooling";
import CuttingParameters from "./components/CuttingParameters";
import ProcessPrediction from "./components/ProcessPrediction";
import postDigiStabilityToolData from "services/digiStability/postDigiStabilityToolData";
import History from "./components/History";
import { Form } from "components/Forms";
import digiStabilitySchema from "./schemas/digiStabilitySchema";
import DigibasicErrorModal from "components/Modal/DigibasicSubmitError";
import "./style.css";

const DigiStability = () => {
  const windowWidth = window.innerWidth;

  const [selectedOption, setSelectedOption] = useState("Tooling");
  const [toolingDataSubmitted, setToolingDataSubmitted] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [digibasicToolId, setDigibasicToolId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cuttingDisabled, setCuttingDisabled] = useState(true);
  const [digiStabilityResponse, setDigiStabilityResponse] = useState({});
  const [errorModalText, setErrorModalText] = useState({
    errorType: "",
    message: "",
  });

  const renderComponent = (windowWidth) => {
    const componentMap = {
      Tooling: (
        <Tooling
          setSelectedOption={setSelectedOption}
          windowWidth={windowWidth}
          setCuttingDisabled={setCuttingDisabled}
        />
      ),
      DigibasicSubmitError: (
        <DigibasicErrorModal errorModalText={errorModalText} />
      ),
      "Cutting Parameters": (
        <CuttingParameters
          setSelectedOption={setSelectedOption}
          setToolingDataSubmitted={setToolingDataSubmitted}
          loading={loading}
          windowWidth={windowWidth}
        />
      ),
      "Process Prediction": (
        <ProcessPrediction
          windowWidth={windowWidth}
          digiStabilityResponse={digiStabilityResponse}
          setSelectedOption={setSelectedOption}
        />
      ),
      History: <History />,
    };

    return componentMap[selectedOption] || null;
  };

  const parseNumericProperties = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        parseNumericProperties(obj[key]);
      } else if (
        typeof obj[key] === "string" &&
        !isNaN(parseFloat(obj[key])) &&
        key !== "frfDataXx" &&
        key !== "frfDataYy"
      ) {
        obj[key] = parseFloat(obj[key]);
      }
    }
  };

  const handleDigiStabilitySubmit = async (values) => {
    parseNumericProperties(values);
    try {
      setLoading(true);
      const response = await postDigiStabilityToolData({
        ...values,
      });
      setDigiStabilityResponse(response.data.lambdaResponse);
      setSelectedOption("Process Prediction");
      notify("Tool data has been received successfully. ✅ ");
      setDigibasicToolId(response.data.digiBasicToolId);
      setToolingDataSubmitted(true);
      return response;
    } catch (err) {
      const { errorType, message } = err.response.data;
      setErrorModalText({ errorType: errorType, message: message });
      setSelectedOption("DigibasicSubmitError");
    } finally {
      localStorage.removeItem("originalNameOf-fluteParameters.frfDataXx");
      localStorage.removeItem("originalNameOf-fluteParameters.frfDataYy");
      setLoading(false);
    }
  };

  const navButtons = {
    backgroundColor: "#3498DB",
    color: "white",
    display: "block",
    width: windowWidth <= 994 ? "140px" : "170px",
    height: windowWidth <= 994 ? "120px" : "150px",
  };

  const [renderedComponent, setRenderedComponent] = useState();
  const [styleObject, setStyleObject] = useState();

  const dynamicallyGenerateStyle = (windowWidth) => {
    const calculateClassName1 = () =>
      windowWidth > 994 ? "row mt-5 mb-5" : "mt-5 mb-5";

    const calculateClassName2 = () => (windowWidth > 994 ? "col-md-2" : "ml-3");

    const calculateClassName3 = () => (windowWidth > 994 ? "0" : "40px");

    const calculateClassName4 = () =>
      windowWidth > 994
        ? "col-md-10 justify-content-center"
        : "d-flex justify-content-center";
    return {
      style1: calculateClassName1(),
      style2: calculateClassName2(),
      style3: calculateClassName3(),
      style4: calculateClassName4(),
    };
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setStyleObject(dynamicallyGenerateStyle(windowWidth));
    });

    return () => {
      window.removeEventListener("resize", () => {
        setStyleObject(dynamicallyGenerateStyle(windowWidth));
      });
    };
  }, [windowWidth]);

  useEffect(() => {
    setRenderedComponent(renderComponent(window.innerWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, selectedOption, digiStabilityResponse]);

  return (
    <>
      {loading && (
        <div>
          <h1 className="digi-stability-loading-text dots">
            We are processing your data...
          </h1>
          <div className="digi-stability-loading-spinner">
            <div
              className="spinner-border"
              style={{ color: "#3498DB", width: "3rem", height: "3rem" }}
            />
          </div>
        </div>
      )}
      <div className="container my-4" style={{ backgroundColor: "#f6f7fc" }}>
        <div className={styleObject ? styleObject.style1 : "row mt-5 mb-5"}>
          <div className={styleObject ? styleObject.style2 : "col-md-2"}>
            <div
              style={{
                marginLeft: styleObject ? styleObject.style3 : "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="d-flex justify-content-center">
                <ul
                  className={
                    windowWidth < 768
                      ? "nav d-flex w-75 justify-content-center"
                      : "nav"
                  }
                >
                  <li>
                    <button
                      type="button"
                      className="btn"
                      style={{
                        ...navButtons,
                        boxShadow:
                          selectedOption === "Tooling"
                            ? "inset 5px 5px 10px rgba(0, 0, 0, 0.25)"
                            : "inset -5px -5px 10px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(255, 255, 255, 0.45)",
                      }}
                      onClick={() => setSelectedOption("Tooling")}
                      disabled={loading || toolingDataSubmitted}
                    >
                      <h6>Tooling</h6>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn btn-info "
                      style={{
                        ...navButtons,
                        boxShadow:
                          selectedOption === "Cutting Parameters"
                            ? "inset 5px 5px 10px rgba(0, 0, 0, 0.25)"
                            : "inset -5px -5px 10px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(255, 255, 255, 0.45)",
                      }}
                      onClick={() => setSelectedOption("Cutting Parameters")}
                      disabled={
                        loading || toolingDataSubmitted || cuttingDisabled
                      }
                    >
                      <h6>Cutting Parameters</h6>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn btn-info"
                      style={{
                        ...navButtons,
                        boxShadow:
                          selectedOption === "Process Prediction"
                            ? "inset 5px 5px 10px rgba(0, 0, 0, 0.25)"
                            : "inset -5px -5px 10px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(255, 255, 255, 0.45)",
                      }}
                      onClick={() => setSelectedOption("Process Prediction")}
                      disabled={loading || !toolingDataSubmitted}
                    >
                      <h6>Process Prediction</h6>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn btn-info"
                      style={{
                        ...navButtons,
                        boxShadow:
                          selectedOption === "History"
                            ? "inset 5px 5px 10px rgba(0, 0, 0, 0.25)"
                            : "inset -5px -5px 10px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(255, 255, 255, 0.45)",
                      }}
                      onClick={() => setSelectedOption("History")}
                    >
                      <h6>History</h6>
                    </button>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignContent: "center",
                }}
              >
                <button
                  className="btn btn-info digi-stability-refresh-this-session-button"
                  type="button"
                  onClick={() => window.location.reload(false)}
                >
                  <h6
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    Refresh This Session
                  </h6>
                </button>
              </div>
            </div>
          </div>
          <div
            className={
              styleObject
                ? styleObject.style4
                : "col-md-10 justify-content-center"
            }
          >
            <Form
              initialValues={{
                digiStabilityToolTypeId: 1,
                diameter: 20,
                fluteParameters: {
                  helixAngle: 30,
                  noOfFlutes: 5,
                  rakeAngle: 10,
                  frfDataXx: "",
                  frfDataYy: "",
                },
                cuttingParameters: {
                  millingTypeSelection: 2,
                  materialSelection: 2,
                  widthOfCut: 10,
                  cuttingSpeed: 20000,
                },
              }}
              validationSchema={digiStabilitySchema}
              onSubmit={handleDigiStabilitySubmit}
            >
              {renderedComponent}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigiStability;
