import http from "utils/http";
import { files } from "constants/endpoints";

const uploadFile = ({ attachment }) => {
  var formData = new FormData();
  formData.append("attachment", attachment);

  const POST_USER_ENDPOINT = files.uploadFile();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_USER_ENDPOINT}`, {
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export default uploadFile;
