import React from "react";
import "./styles.css";
import getDigiStabilityToolTypes from "services/digiStability/getDigiStabilityToolTypes";
import useAPI from "effects/useAPI";
import { useFormContext } from "react-hook-form";
import { InputWithTooltip, Select, ErrorMessage } from "components/Forms";
import toolingSchema from "./schemas/toolingSchema";
import notify from "utils/toastMsg";
import FileUpload from "components/FileUpload";

function Tooling(props) {
  const all = useFormContext();

  const [
    loadingDigiStabilityToolTypes,
    errorDigiStabilityToolTypes,
    digiStabilityToolTypesResponse,
  ] = useAPI(() => getDigiStabilityToolTypes());

  const { digiStabilityToolTypes } = digiStabilityToolTypesResponse;

  let toolTypeOptions = [];

  if (!loadingDigiStabilityToolTypes && !errorDigiStabilityToolTypes) {
    digiStabilityToolTypes.map((option) => {
      return toolTypeOptions.push({
        key: option.digistabilityToolTypeId,
        value: option.digistabilityToolTypeId,
        label: option.digistabilityToolType,
      });
    });
  }

  const toolingButtonValidation = async () => {
    try {
      await toolingSchema.validate(all.getValues(), {
        abortEarly: false,
      });
      props.setSelectedOption("Cutting Parameters");
      props.setCuttingDisabled(false);
    } catch (error) {
      error.errors.map((errorMessage) => {
        notify(`${errorMessage} ❌`);
        // eslint-disable-next-line no-console
        console.error({ errorMessage });
        return null;
      });
      props.setCuttingDisabled(true);
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div className={props.windowWidth > 994 ? "col-md-9" : "col-md-11"}>
        <h2
          className="d-flex justify-content-center"
          style={{
            marginTop: props.windowWidth <= 994 && "30px",
            marginBottom: props.windowWidth <= 994 && "30px",
          }}
        >
          Tooling
        </h2>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Tool Type:</label>
          <div className="col-sm-7">
            {loadingDigiStabilityToolTypes ? (
              <div className="d-flex flex-row">
                <h6 className="ml-4 mr-4">Loading Tool Types...</h6>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : errorDigiStabilityToolTypes ? (
              <h6 className="ml-4">Error Loading Tool Types.</h6>
            ) : (
              <Select
                name="digiStabilityToolTypeId"
                type="number"
                options={toolTypeOptions}
                className="form-control"
                placeholder="Shape"
              />
            )}
            <ErrorMessage name="digibasicToolTypeId" />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Tool Diameter (mm):</label>
          <div className="col-sm-7">
            <InputWithTooltip
              name="diameter"
              type="number"
              className="form-control"
              placeholder="Diameter (D)"
              tooltipMessage="Enter the diameter of the tool: Between 1 and 100 in millimeters"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Number of Flutes:</label>
          <div className="col-sm-7">
            <InputWithTooltip
              name="fluteParameters.noOfFlutes"
              min="1"
              max="10"
              maxLength="2"
              className="form-control"
              type="number"
              placeholder="Number of Flutes"
              tooltipMessage="Enter number of flutes: Between 1 and 10"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">
            Tool Helix Angle (deg):
          </label>
          <div className="col-sm-7">
            <InputWithTooltip
              key={`fluteParameters.helixAngle`}
              name={`fluteParameters.helixAngle`}
              className="form-control"
              type="number"
              placeholder={"Helix Angle"}
              tooltipMessage="Enter the helix angle of the tool: Between 0 and 60 in degrees"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">
            Maximum Spindle Speed for Machine (RPM):
          </label>
          <div className="col-sm-7">
            <InputWithTooltip
              name="cuttingParameters.cuttingSpeed"
              className="form-control"
              type="number"
              placeholder="Spindle Speed"
              tooltipMessage="Enter the maximum spindle speed for the machine: Between 10000 and 40000 in RPM"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Rake Angle (deg):</label>
          <div className="col-sm-7">
            <InputWithTooltip
              name="fluteParameters.rakeAngle"
              className="form-control"
              type="number"
              placeholder="Rake Angle"
              tooltipMessage="Enter the maximum rake angle for the machine: Between -90 and 90 in degrees"
            />
          </div>
        </div>
        <div
          className="form-group"
          style={{ marginLeft: "4.4em", marginRight: "4.4em" }}
        >
          <div>
            <label>Upload the FRF in the feed direction (.frf/.txt)</label>
            <FileUpload
              name="fluteParameters.frfDataXx"
              className="form-control"
              placeholder="Diameter (D)"
            />
            <ErrorMessage name="diameter" />
          </div>
        </div>
        <div
          className="form-group"
          style={{ marginLeft: "4.4em", marginRight: "4.4em" }}
        >
          <div>
            <label>
              Upload the FRF in the cross-feed direction (.frf/.txt)
            </label>
            <FileUpload
              name="fluteParameters.frfDataYy"
              className="form-control"
            />
            <div className="mt-3">
              <ErrorMessage name="fluteParameters.frfDataYy" />
            </div>
          </div>
        </div>
        <div className="mt-5 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-info waves-effect waves-light"
            style={{
              width: "200px",
              backgroundColor: "#3498DB",
              color: "#fff",
            }}
            onClick={toolingButtonValidation}
          >
            Next
          </button>
        </div>
      </div>
      <div className="d-flex" style={{ marginLeft: "-100px" }}>
        <img
          style={{
            maxHeight: "300px",
          }}
          src={"assets/images/stabilityToolPage.png"}
          alt="Milling process"
        />
      </div>
    </div>
  );
}

export default Tooling;
