import React, { useCallback } from "react";
import {
  Form,
  Input,
  ErrorMessage,
  SubmitButton,
  Radio,
  WatchForm,
} from "components/Forms";
import FileUpload from "components/FileUpload";
import ManualEntryFields from "./components/ManualEntryFields";
import toolHolderInputTypes from "constants/toolHolderInputTypes";
import toolInputTypes from "constants/toolInputTypes";
import cuttingToolSchema from "./schemas/cuttingToolSchema";
import debounce from "utils/debounce";
import notify from "utils/toastMsg";
import postMachineExtensions from "services/predictiveMaintenance/postMachineExtensions";
import { useParams } from "react-router-dom";

const ToolingUploadInfo = ({
  setShowMenu,
  fetchAllMachineExtensionsByMachineId,
}) => {
  const { machineId } = useParams();

  const handleAddTool = async (values, evt, methods) => {
    try {
      values = {
        ...values,
        machineId: machineId,
      };
      const response = await postMachineExtensions(values);
      const currentCuttingToolIds =
        localStorage.getItem("cuttingToolIds") || "[]";
      const currentToolIds = JSON.parse(currentCuttingToolIds);
      const cuttingToolId = response.data.cuttingToolId;
      const newCuttingToolIds = [...currentToolIds, cuttingToolId];
      setTimeout(() => {
        localStorage.setItem(
          "cuttingToolIds",
          JSON.stringify(newCuttingToolIds)
        );
        localStorage.removeItem("toolData");
        localStorage.removeItem("originalNameOf-toolDatasheet");
        localStorage.removeItem("originalNameOf-cuttingToolFRFxxUpload");
        localStorage.removeItem("originalNameOf-cuttingToolFRFyyUpload");
      }, 375);
      notify("Tool added successfully. ✅");
    } catch (err) {
      notify("Something went wrong. ❌");
      throw err;
    } finally {
      methods.reset();
      fetchAllMachineExtensionsByMachineId();
      setShowMenu(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveFormState = useCallback(
    debounce((methods) => {
      localStorage.setItem("toolData", JSON.stringify(methods.getValues()));
    }, 1500),
    []
  );

  // const rawToolData = localStorage.getItem("toolData");
  // let savedToolData;
  // try {
  // savedToolData = JSON.parse(rawToolData);
  // } catch (error) {
  // savedToolData = {
  //   toolDataInputSelection: toolInputTypes.URL,
  //   toolHolderDataInputSelection: toolHolderInputTypes.URL,
  // };
  // }

  return (
    <Form
      initialValues={{
        toolHolderDataInputSelection: toolHolderInputTypes.URL,
        toolDataInputSelection: toolInputTypes.URL,
        toolParameters: { helixOption: "1", pitchOption: "1", rakeOption: "1" },
        // ...savedToolData,
      }}
      validationSchema={cuttingToolSchema}
      onSubmit={handleAddTool}
    >
      <WatchForm name="">
        {(formValues, methods) => {
          saveFormState(methods);
        }}
      </WatchForm>
      <div className="form-group">
        <label>Operation Name</label>
        <Input
          name="operationName"
          type="text"
          className="form-control"
          placeholder="Operation Name"
        />
        <ErrorMessage name="operationName" />
      </div>
      <hr className="separator" />
      <div className="form-group">
        <label>Tool Holder Name</label>
        <Input
          name="toolHolderName"
          type="text"
          className="form-control"
          placeholder="Tool Holder Name"
        />
        <ErrorMessage name="toolHolderName" />
      </div>
      <label
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        Please select your method of upload.
      </label>
      <div
        className="row cardss"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="form-group">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <Radio
              name="toolHolderDataInputSelection"
              options={[
                {
                  value: toolHolderInputTypes.URL,
                  label: "Tool Holder Web URL",
                },
                {
                  value: toolHolderInputTypes.ISO,
                  label: "Tool Holder ISO Number",
                },
              ]}
            />
          </div>
        </div>
      </div>
      <WatchForm name="toolHolderDataInputSelection">
        {(toolHolderDataInputSelection) => {
          if (toolHolderDataInputSelection === toolHolderInputTypes.URL) {
            return (
              <div className="form-group">
                <label>Tool Holder Web URL</label>
                <Input
                  name="toolHolderUrl"
                  type="text"
                  className="form-control"
                  placeholder="Tool Holder Web URL"
                />
                <ErrorMessage name="toolHolderUrl" />
              </div>
            );
          } else if (
            toolHolderDataInputSelection === toolHolderInputTypes.ISO
          ) {
            return (
              <div className="form-group">
                <label>Tool Holder ISO Number</label>
                <Input
                  name="toolHolderIsoNo"
                  type="text"
                  className="form-control"
                  placeholder="Tool Holder ISO Number"
                />
                <ErrorMessage name="toolHolderIsoNo" />
              </div>
            );
          }
          return null;
        }}
      </WatchForm>
      <hr className="separator" />
      <div className="form-group">
        <label>Cutting Tool Name</label>
        <Input
          name="cuttingToolName"
          type="text"
          className="form-control"
          placeholder="Tool Name"
        />
        <ErrorMessage name="cuttingToolName" />
      </div>
      <div className="form-group">
        <label>Tool number as it is defined in CAM</label>
        <Input
          name="cuttingToolNumber"
          type="number"
          className="form-control"
          placeholder="Tool Number as it is defined in CAM or CNC"
        />
        <ErrorMessage name="cuttingToolNumber" />
      </div>
      <label style={{ display: "flex", justifyContent: "center" }}>
        Please select your method of upload.
      </label>
      <div
        className="row cardss"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="form-group">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <Radio
              name="toolDataInputSelection"
              options={[
                { value: toolInputTypes.URL, label: "Tool Web URL" },
                {
                  value: toolInputTypes.ISO,
                  label: "Tool ISO Number",
                },
                {
                  value: toolInputTypes.DATASHEET,
                  label: "Data Sheet",
                },
                {
                  value: toolInputTypes.MANUAL,
                  label: "Manual Entry ",
                },
              ]}
            />
          </div>
          <ErrorMessage name="toolNumber" />
        </div>
      </div>
      <WatchForm name="toolDataInputSelection">
        {(toolDataInputSelection) => {
          if (toolDataInputSelection === toolInputTypes.URL) {
            return (
              <div className="form-group">
                <label>Tool Web URL</label>
                <Input
                  name="toolUrl"
                  type="text"
                  className="form-control"
                  placeholder="Tool Web URL"
                />
                <ErrorMessage name="toolUrl" />
              </div>
            );
          } else if (toolDataInputSelection === toolInputTypes.ISO) {
            return (
              <div className="form-group">
                <label>Tool ISO Number</label>
                <Input
                  name="toolIsoNo"
                  type="text"
                  className="form-control"
                  placeholder="Tool ISO Number"
                />
                <ErrorMessage name="toolIsoNo" />
              </div>
            );
          } else if (toolDataInputSelection === toolInputTypes.DATASHEET) {
            return (
              <div className="form-group">
                <label>Data Sheet</label>
                <FileUpload name="toolDatasheet" className="form-control" />
                <ErrorMessage name="toolDatasheet" />
              </div>
            );
          } else if (toolDataInputSelection === toolInputTypes.MANUAL) {
            return <ManualEntryFields />;
          }
          return null;
        }}
      </WatchForm>
      <hr className="separator" />
      <div className="form-group">
        <label>Stick out Length</label>
        <Input
          name="stickOutLength"
          className="form-control form-control-sm"
          type="float"
          placeholder="Stick out Length (mm)"
        />
        <ErrorMessage name="stickOutLength" />
      </div>
      <div className="form-group">
        <label>Spindle Name</label>
        <Input
          name="spindleName"
          type="text"
          className="form-control"
          placeholder="Tool Holder Name"
        />
        <ErrorMessage name="spindleName" />
      </div>
      <hr className="separator mt-4" />
      <div>
        <label className="mt-3">Frequency Response Function (FRF)</label>
        <p className="text-muted">
          Please upload the cutting tool FRF data {""}
          based on Machine Tool Coordinate Frame.
        </p>
        <div className="form-group">
          <label>FRFxx (.frf / .txt)</label>
          <FileUpload name="cuttingToolFRFxxUpload" className="form-control" />
        </div>
        <div className="form-group">
          <label>FRFyy (.frf / .txt)</label>
          <FileUpload name="cuttingToolFRFyyUpload" className="form-control" />
        </div>
      </div>
      <SubmitButton
        type="submit"
        className="btn btn-primary btn-lg waves-effect waves-light"
      >
        Add
      </SubmitButton>
    </Form>
  );
};
export default ToolingUploadInfo;
