import http from "utils/http";
import { digiStability } from "constants/endpoints";

const getDigiStabilityToolDataByOrgId = ({ startIndex }) => {
  const ENDPOINT = digiStability.getDigiStabilityToolDataByOrgId();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}?startIndex=${startIndex}`);
};

export default getDigiStabilityToolDataByOrgId;
