import { useFormContext } from "react-hook-form";
import get from "utils/get";

const Select = ({ options, name, ...rest }) => {
  const { register, formState } = useFormContext();
  const fieldError = Boolean(get(formState.errors, name));

  return (
    <select
      {...register(name)}
      {...rest}
      {...(fieldError ? { style: { borderColor: "#a01" } } : {})}
    >
      {options.map(({ key, value, label }) => (
        <option key={key} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};

export default Select;
