import http from "utils/http";
import { user } from "constants/endpoints";

const deleteLoginSession = () => {
  const ENDPOINT = user.deleteLoginSession();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.delete(`${API_BASE_URL}${ENDPOINT}`);
};

export default deleteLoginSession;
