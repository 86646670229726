import * as yup from "yup";

const mafCodeValidationSchema = yup.object().shape({
  mfaCode: yup
    .string()
    .label("MFA Code")
    .required()
    .typeError("Please fill in the MFA code field"),
});

export default mafCodeValidationSchema;
