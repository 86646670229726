import http from "utils/http";
import { frfDatabase } from "constants/endpoints";

const getMaintenanceFrfDataByMachineId = ({ machineId, frfTableOffset }) => {
  const GET_ENDPOINT = frfDatabase.getMaintenanceFrfDataByMachineId({
    machineId,
    frfTableOffset,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(
    `${API_BASE_URL}${GET_ENDPOINT}?frfTableOffset=${frfTableOffset}`
  );
};

export default getMaintenanceFrfDataByMachineId;
