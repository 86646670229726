import * as yup from "yup";

const registrationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .label("First Name")
    .typeError("First Name must be a text."),
  lastName: yup
    .string()
    .required()
    .label("Last Name")
    .typeError("Last Name must be a text."),
  password: yup
    .string()
    .min(8)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must include at least one capital letter, one number, and one special symbol. Allowed special symbols is: @, $, !, %, *, ?, &"
    )
    .required()
    .label("Password")
    .typeError("Password must be at least 8 characters."),
  confirmPassword: yup
    .string()
    .min(8)
    .required()
    .label("Confirm Password")
    .typeError("Confirm Password must be a text.")
    .test(
      "doesConfirmPasswordMatchWithPassword",
      "The confirmation password does not match with the entered password",
      function test(confirmPassword) {
        const { password } = this.parent;
        if (confirmPassword !== password) {
          return false;
        }
        return true;
      }
    ),
  phoneNumber: yup
    .number()
    .required()
    .label("Phone Number")
    .typeError("Phone Number is a required field."),
  // jobTitle: yup
  //   .string()
  //   .required()
  //   .label("Job Title")
  //   .typeError("Job Title is a required field."),
  email: yup
    .string()
    .email()
    .required()
    .label("Email")
    .typeError("Email must be a valid email address."),
  //   companyName: yup
  //     .string()
  //     .required()
  //     .label("Company Name")
  //     .typeError("Company Name is a required field."),
  // differentPartReferencesInYear: yup
  //   .string()
  //   .required()
  //   .label("How many different part references a year ?")
  //   .typeError(
  //     "How many different part references a year ? is a required field."
  //   ),
  // machineToolCount: yup
  //   .string()
  //   .required()
  //   .label("How many machine tools do you run ?")
  //   .typeError("How many machine tools do you run ? is a required field."),
  // partsCountInYear: yup
  //   .string()
  //   .required()
  //   .label("How many parts do you make a year ?")
  //   .typeError("How many parts do you make a year ? is a required field."),
  // camPackageUsed: yup.array(),
  // hearAboutUs: yup
  //   .string()
  //   .required()
  //   .label("Where Did You Hear About Us ?")
  //   .typeError("Where Did You Hear About Us ? is a required field."),
  // doYouWorkWithOurPartners: yup
  //   .string()
  //   .required()
  //   .label("Do you work with our partners ?")
  //   .typeError("Do you work with our partners ? is a required field."),
  country: yup
    .string()
    .required()
    .label("Country")
    .typeError("Country is a required field."),
  discountCode: yup
    .string()
    .label("Discount code")
    .typeError("Discount code should be string."),
});

export default registrationSchema;
