import React from "react";
import deleteSessionCuttingToolById from "services/cuttingTools/deleteSessionCuttingToolById";

const DeleteCuttingToolEntry = ({ toolId, fetchCuttingTools }) => {
  const handleDeleteCuttingTool = async () => {
    try {
      await deleteSessionCuttingToolById({ toolId });

      const cuttingToolIds = JSON.parse(localStorage.getItem("cuttingToolIds"));
      const indexOfDeletedTool = cuttingToolIds.indexOf(toolId);
      cuttingToolIds.splice(indexOfDeletedTool, 1);
      localStorage.setItem("cuttingToolIds", JSON.stringify(cuttingToolIds));

      fetchCuttingTools();
      // eslint-disable-next-line no-undef
      $(`.bs-example-modal-center${toolId}`).modal("hide");
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      <i
        className="ion ion-md-trash delete-icon"
        data-toggle="modal"
        data-target={`.bs-example-modal-center${toolId}`}
        style={{ cursor: "pointer" }}
      ></i>
      <div
        id="modal"
        className={`modal show bs-example-modal-center${toolId}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mt-0 text-center">
                Are you sure want to delete this cutting tool entry{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-footer d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light"
                onClick={handleDeleteCuttingTool}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteCuttingToolEntry;
