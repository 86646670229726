/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router";
import getGravatarImg from "utils/getGravatarImg";
import Authentication from "services/Authentication";
import deleteLoginSession from "services/deleteLoginSession";

let auth = new Authentication();

const Header = ({ enlarged, setEnlarged }) => {
  let history = useHistory();
  const jwtPayload = auth.getAccessTokenPayload() || {};

  const firstName = jwtPayload.firstName ?? "";
  const lastName = jwtPayload.lastName ?? "";

  // useEffect(() => {
  //   const handleTabClose = (event) => {
  //     handleDeleteLoginSession();
  //     localStorage.clear();
  //   };
  //   window.addEventListener("beforeunload", handleTabClose);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleTabClose);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleDeleteLoginSession = async () => {
    try {
      await deleteLoginSession().then(() => {
        auth.logout();
      });
      history.push("/login");
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className="topbar">
      <div className="topbar-left d-none d-lg-block">
        <div className="text-center">
          <a
            className="logo"
            style={{ cursor: "pointer" }}
            href="http://www.productivemachines.co.uk"
          >
            <img src="assets/images/ll2.png" height="60px" alt="logo" />
          </a>
        </div>
      </div>
      <nav className="navbar-custom">
        <ul className="list-inline float-right mb-0">
          {/* <button
            className="btn btn-primary btn-lg waves-effect waves-light mr-4 mb-3"
            style={{
              borderRadius: "0.5rem",
              backgroundColor: "#2250fc",
              color: "white",
              fontWeight: "600",
            }}
            type="button"
            onClick={() => history.push("/subscription")}
          >
            Subscriptions
          </button> */}
          <li className="list-inline-item dropdown notification-list nav-user">
            <a
              className="nav-link dropdown-toggle arrow-none waves-effect"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img
                src={getGravatarImg(firstName, lastName)}
                alt="user"
                className="rounded-circle"
              />
              <span
                className="d-none d-md-inline-block ml-2"
                style={{ color: "#424858" }}
              >
                {firstName} {lastName}
                <i className="mdi mdi-chevron-down"></i>{" "}
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown">
              <button
                className="dropdown-item"
                onClick={() => {
                  history.push(`/user-edit`);
                }}
              >
                <i className="dripicons-user text-muted"></i> Profile
              </button>
              {/* <button
                className="dropdown-item"
                onClick={() => {
                  history.push(`/subscription`);
                }}
              >
                <i className="dripicons-wallet text-muted"></i> My Subscriptions
              </button> */}
              <button
                className="dropdown-item"
                onClick={() => handleDeleteLoginSession()}
              >
                <div className="dropdown-divider"></div>
                <i className="dripicons-exit text-muted"></i> Logout
              </button>
            </div>
          </li>
        </ul>
        <ul className="list-inline menu-left mb-0">
          <li className="list-inline-item">
            <button
              type="button"
              className="button-menu-mobile open-left waves-effect"
              onClick={() => {
                setEnlarged(!enlarged);
              }}
            >
              <i className="mdi mdi-menu"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
