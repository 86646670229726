import React from "react";
import getContactFormMessages from "services/getContactFormMessages";
import getBookingRequests from "services/getBookingRequests";
import useAPI from "effects/useAPI";
import { CSVLink } from "react-csv";
import "../../../App.css";

const AdminContactForm = () => {
  const [errorMessages, loadingMessages, messages] = useAPI(() =>
    getContactFormMessages()
  );
  const [errorBookingRequests, loadingBookingRequests, bookingRequests] =
    useAPI(() => getBookingRequests());

  if (errorMessages || errorBookingRequests) {
    return <p>Error Loading Data</p>;
  }

  if (loadingMessages || loadingBookingRequests) {
    return <p>Loading</p>;
  }
  const { contactFormMessages } = messages;
  const { demoRequests } = bookingRequests;

  return (
    <div style={{ marginTop: 150 }}>
      <div
        className="row cardss"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-xl-9">
          <div className="card" style={{ marginBottom: "100px" }}>
            <div className="card-body">
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="mt-0 header-title mb-4">Messages</h4>
                  <CSVLink
                    className="btn btn-success waves-effect waves-light"
                    style={{ width: 150, marginBottom: 15 }}
                    data={[
                      [
                        "Full Name",
                        "Email",
                        "Phone Number",
                        "Message",
                        "Created At",
                      ],
                      ...contactFormMessages.map(
                        ({
                          fullName,
                          email,
                          mobilePhone,
                          message,
                          createdAt,
                        }) => [fullName, email, mobilePhone, message, createdAt]
                      ),
                    ]}
                    filename={"CustomerMessages.csv"}
                  >
                    Download Csv File
                  </CSVLink>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Full Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Message</th>
                      <th scope="col">Received At</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {contactFormMessages.length === 0 ? (
                      <h2>No messages so far...</h2>
                    ) : (
                      contactFormMessages.map((sender, key) => {
                        return (
                          <tr key={key}>
                            <td>{sender.fullName}</td>
                            <td>{sender.email}</td>
                            <td>{sender.mobilePhone}</td>
                            <td>{sender.message}</td>
                            <td>{sender.createdAt}</td>
                            <td></td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade bs-example-modal-sm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                Add New user
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row cardss"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-xl-9">
          <div className="card" style={{ marginBottom: "100px" }}>
            <div className="card-body">
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="mt-0 header-title mb-4">Booking Request</h4>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Surname</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Company</th>
                      <th scope="col">Job Title</th>
                      <th scope="col">Cam Software</th>
                      <th scope="col">Problem</th>
                      <th scope="col">Description</th>
                      <th scope="col">Requested At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {demoRequests.length === 0 ? (
                      <h2>No demo requests...</h2>
                    ) : (
                      demoRequests.map((booking, key) => {
                        return (
                          <tr key={key}>
                            <td>{booking.name}</td>
                            <td>{booking.surname}</td>
                            <td>{booking.email}</td>
                            <td>{booking.phone}</td>
                            <td>{booking.company}</td>
                            <td>{booking.jobTitle}</td>
                            <td>{booking.camSoftware}</td>
                            <td>{booking.problem}</td>
                            <td>{booking.description}</td>
                            <td>{booking.createdAt}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade bs-example-modal-sm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                Add New user
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminContactForm;
