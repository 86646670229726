import React from "react";
import { useForm } from "react-hook-form";
import {
  Form,
  Input,
  InputNonOptimized,
  ErrorMessage,
  SubmitButton,
} from "components/Forms";
import notify from "utils/toastMsg";
import Authentication from "services/Authentication";
import patchUserDetails from "services/patchUserDetails/patchUserDetails";
import patchUserPassword from "services/patchUserPassword";
import {
  schemaBasicDetails,
  schemaChangePassword,
} from "./schemas/userDetailsSchema";

const UserProfile = () => {
  const { reset } = useForm();
  let auth = new Authentication();
  const jwtPayload = auth.getAccessTokenPayload();
  const handleUpdateUserDetails = async (formValues) => {
    const { firstName, lastName, email } = formValues;
    if (
      firstName === jwtPayload.firstName &&
      lastName === jwtPayload.lastName &&
      email === jwtPayload.email
    ) {
      notify("No changes made", { type: "info" });
      return;
    }
    try {
      const response = await patchUserDetails({ firstName, lastName, email });
      if (response.status === 200) {
        auth.loginWithAdamos(response.data.accessToken); // setting the refresh token
        notify("User details updated successfully", { type: "success" });
        reset();
        return;
      }
      notify("Something went wrong", { type: "error" });
    } catch (err) {
      throw err;
    }
  };

  const handleUpdateUserPassword = async (formValues) => {
    const { currentPassword, newPassword } = formValues;
    await patchUserPassword({
      newPassword,
      currentPassword,
    })
      .then((res) => {
        if (res.status === 200) {
          notify("Password Changed successfully", { type: "success" });
          return;
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          notify("Incorrect current password", { type: "error" });
          return;
        }
        notify("Something went wrong", { type: "error" });
      });
  };
  return (
    <div>
      <div style={{ marginTop: 150 }}>
        <div
          className="row cardss"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-xl-8 ">
            <div className="card " style={{ justifyContent: "flex-end" }}>
              <div className="card-body">
                <h4 className="mt-0 header-title mb-4">Basic Details</h4>
                <Form
                  initialValues={{
                    firstName: jwtPayload.firstName,
                    lastName: jwtPayload.lastName,
                    email: jwtPayload.email,
                  }}
                  validationSchema={schemaBasicDetails}
                  onSubmit={handleUpdateUserDetails}
                >
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      First Name
                    </label>
                    <div className="col-sm-10">
                      <Input
                        name="firstName"
                        type="text"
                        className="form-control"
                      />
                      <ErrorMessage name="firstName" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Last Name
                    </label>
                    <div className="col-sm-10">
                      <Input name="lastName" className="form-control" />
                      <ErrorMessage name="lastName" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-email-input"
                      className="col-sm-2 col-form-label"
                    >
                      Email
                    </label>
                    <div className="col-sm-10">
                      <Input
                        type="email"
                        name="email"
                        parsley-type="email"
                        className="form-control"
                      />
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <SubmitButton
                    type="submit"
                    className="btn btn-success waves-effect waves-light float-right"
                  >
                    Save
                  </SubmitButton>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="row cardss"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-xl-8 ">
            <div className="card " style={{ justifyContent: "flex-end" }}>
              <div className="card-body">
                <Form
                  initialValues={{}}
                  validationSchema={schemaChangePassword}
                  onSubmit={handleUpdateUserPassword}
                >
                  <h4 className="mt-0 header-title mb-4">Change Password</h4>
                  <div className="form-group row">
                    <label
                      htmlFor="example-password-input"
                      className="col-sm-2 col-form-label"
                    >
                      Current Password
                    </label>
                    <div className="col-sm-10">
                      <InputNonOptimized
                        name="currentPassword"
                        className="form-control"
                        type="password"
                        placeholder="Type first your Current password"
                      />
                      <ErrorMessage name="currentPassword" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-password-input"
                      className="col-sm-2 col-form-label"
                    >
                      New Password
                    </label>
                    <div className="col-sm-10">
                      <InputNonOptimized
                        name="newPassword"
                        className="form-control"
                        type="password"
                      />
                      <ErrorMessage name="newPassword" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-password-input"
                      className="col-sm-2 col-form-label"
                    >
                      Confirm new Password
                    </label>
                    <div className="col-sm-10">
                      <InputNonOptimized
                        name="confirmPassword"
                        className="form-control"
                        type="password"
                      />
                      <ErrorMessage name="confirmPassword" />
                    </div>
                  </div>
                  <SubmitButton
                    type="submit"
                    className="btn btn-success waves-effect waves-light float-right"
                  >
                    Save
                  </SubmitButton>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
