import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import useForm from "effects/useForm";
import updatePassword from "services/passwordRecovery/updatePassword";
import notify from "utils/toastMsg";

const RecoverPassword = () => {
  let history = useHistory();
  const { shortcode } = useParams();
  const [invalidCredentialsError, setInvalidCredentialsError] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    email,
    emailErrorMessage,
    password,
    passwordErrorMessage,
    setEmail,
    setPassword,
    isValid,
    confirmPassword,
    confirmPasswordErrorMessage,
    setConfirmPassword,
  } = useForm({
    email: {
      validation: (email) => {
        if (email === "") {
          return "Email is required";
        }
      },
      initialValue: "",
    },
    password: {
      validation: (password) => {
        if (password === "") {
          return "Password is required";
        }
      },
      initialValue: "",
    },
    confirmPassword: {
      validation: (confirmPassword) => {
        if (confirmPassword === "") {
          return "Confirm Password is required";
        }
      },
      initialValue: "",
    },
  });

  const handleUpdate = (event) => {
    event.preventDefault();
    setLoading(true);
    updatePassword({ email, password, confirmPassword, shortcode })
      .then(() => {
        notify("Your password has been succesfully changed. ✅ ");
        setLoading(false);
        history.push("/login");
      })
      .catch((err) => {
        setInvalidCredentialsError("Incorrect Email or Password");
      });
  };

  return (
    <>
      <img className="login-logo" src="assets/images/ll2.png" alt="logo" />
      <div
        className="mt-5"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="col-lg-5 mt-5">
          <div className="card mb-0 mt-5">
            <div className="card-body">
              <div className="mb-4 text-center">
                <h3 className="mb-0" style={{ fontFamily: "Poppins" }}>
                  Reset Password
                </h3>
                <p
                  className="my-2 mt-4"
                  style={{ fontFamily: "Montserrat", fontSize: "16px" }}
                >
                  Please enter your new password in the fields below to create a
                  new password for your account.{" "}
                </p>
              </div>
              <form>
                <div className="form-group">
                  <label>Enter Your Email</label>
                  {emailErrorMessage && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "-7px",
                      }}
                    >
                      {emailErrorMessage}
                    </p>
                  )}
                  <div className="uk-position-relative w-100">
                    <span className="uk-form-icon">
                      <i className="icon-feather-mail"></i>
                    </span>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="name@example.com"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Enter New Password</label>
                  <div className="uk-position-relative w-100">
                    <span className="uk-form-icon">
                      <i className="icon-feather-lock"></i>
                    </span>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="********"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                    />
                  </div>
                </div>
                {passwordErrorMessage && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "7px",
                    }}
                  >
                    {passwordErrorMessage}
                  </p>
                )}
                {invalidCredentialsError && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "-7px",
                    }}
                  >
                    {invalidCredentialsError}
                  </p>
                )}
                <div className="form-group">
                  <label>Confirm New Password</label>
                  <div className="uk-position-relative w-100">
                    <span className="uk-form-icon">
                      <i className="icon-feather-lock"></i>
                    </span>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="********"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      value={confirmPassword}
                    />
                  </div>
                  {confirmPasswordErrorMessage && (
                    <p className="text-danger mb-0">
                      {confirmPasswordErrorMessage}
                    </p>
                  )}
                </div>
                <div
                  className="mt-4 uk-flex-middle uk-grid-small"
                  data-uk-grid
                  style={{ justifyContent: "center" }}
                >
                  {invalidCredentialsError && (
                    <div className="login-error-wrapper">
                      <p className="text-danger">{invalidCredentialsError}</p>
                    </div>
                  )}
                  <div className="mt-4">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Link to="/login">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg w-100"
                          disabled={!isValid || loading}
                          onClick={handleUpdate}
                        >
                          {loading ? "Sending Email.." : "Update Password"}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverPassword;
