import { XestTable } from "components/CustomComponents";
import React from "react";
import { camelCase, mapKeys } from "lodash";
import getDigibasicTools from "services/getDigibasicTools";

const LatestSubmissionsTable = (props) => {
  const { setFeedRateToolId, setSelectedToolData } = props;
  const toCamelCase = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => toCamelCase(v));
    } else if (obj !== null && obj.constructor === Object) {
      return mapKeys(obj, (value, key) => camelCase(key));
    }
    return obj;
  };
  const columns = [
    {
      dataIndex: "tool_input_ref_no",
      title: "Reference No",
      filterType: {
        dbCol: "digibasic_tools.tool_input_ref_no",
        type: "string",
      },
      width: 40,
      ellipsis: { showTitle: true },
    },
    {
      dataIndex: "created_at",
      title: "Created At",
      filterType: {
        dbCol: "digibasic_tools.created_at",
        type: "date",
      },
      width: 40,
      ellipsis: { showTitle: true },
    },
    {
      title: "Actions",
      fixed: "right",
      width: 15,
      render(text, record) {
        return (
          <button
            type="button"
            className="btn btn-info btn-sm"
            style={{ width: "7em", cursor: "pointer" }}
            onClick={() => {
              const { digibasic_tool_id } = record;
              setFeedRateToolId(digibasic_tool_id);
              setSelectedToolData(toCamelCase(record));
            }}
          >
            Show Data
          </button>
        );
      },
    },
  ];
  const params = {
    apiCallFn: async (queryParams) => {
      let data, error;
      await getDigibasicTools({ queryParams })
        .then(async (res) => {
          data = res.data;
        })
        .catch((err) => (error = err));

      return {
        data,
        error,
      };
    },
    tableNum: 1,
    deps: [1],
    initialPageSize: 5,
    initialFilters: [],
    initialSortCriteria: null,
    config: {
      filtersKeyGen() {
        return "dt--feed-rate-latest-submissions";
      },
    },
  };
  return <XestTable columns={columns} params={params} />;
};

export default LatestSubmissionsTable;
