import * as yup from "yup";

export const schemaBasicDetails = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .label("First Name")
    .typeError("First Name must be a text."),
  lastName: yup
    .string()
    .required()
    .label("Last Name")
    .typeError("Last Name must be a text."),
  email: yup
    .string()
    .email()
    .required()
    .label("Email")
    .typeError("Email must be a valid email address."),
});
export const schemaChangePassword = yup.object().shape({
  currentPassword: yup
    .string()
    .min(8)
    .required("Current password is required.")
    .label("Current Password"),
  newPassword: yup
    .string()
    .min(8)
    .required()
    .label("New Password")
    .typeError("password must be minimum 8 character length a."),
  confirmPassword: yup
    .string()
    .min(8)
    .required()
    .label("Confirm password")
    .typeError("Confirm password must be minimum 8 character length and match.")
    .test(
      "Does New Password Match Confirmation Password",
      "The confirmation password does not match with the entered password",
      function test(confirmPassword) {
        const { newPassword } = this.parent;
        return confirmPassword === newPassword;
      }
    ),
});
