import { React, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import notify from "utils/toastMsg";
import AddNewMachine from "./components/AddNewMachine";
import MachineToolCard from "./components/MachineToolCard";
import ToolingDatabase from "./components/ToolingDatabase";
import ToolpathOptimisation from "./components/ToolpathOptimisation";
import DeleteMachine from "./components/DeleteMachine";
import getMachines from "services/getMachines";
import useAPI from "effects/useAPI";
import deleteMachineById from "services/deleteMachineById";

const options = {
  menu: {
    "": "Menu",
    ToolingDatabase: "Tooling Database",
    ToolpathOptimisation: "Toolpath Optimisation",
    SpindleHealthMonitoring: "Spindle Health Monitoring",
  },
  configure: {
    "": "Configure",
    MachineTool: "Machine Tool Identity",
    Controller: "Controller Settings",
    Spindle: "Spindle Settings",
    Edit: "Edit Machine",
    Delete: "Delete Machine Tool",
  },
};

function PredictiveMaintenance() {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedMenuValue, setSelectedMenuValue] = useState("");
  const [showComponent, setShowComponent] = useState(false);

  const [machinesLoading, machinesError, machinesResponse, fetchAllMachines] =
    useAPI(() => getMachines());

  const { machines } = machinesResponse;

  const handleClose = () => {
    setShowMenu(false);
  };

  const [activeMachineId, setActiveMachineId] = useState(null);

  const renderSelectedComponent = () => {
    switch (selectedMenuValue) {
      case "ToolingDatabase":
        return <ToolingDatabase />;
      case "ToolpathOptimisation":
        return <ToolpathOptimisation />;
      case "MachineTool":
        return <MachineToolCard />;
      case "Delete":
        return (
          <DeleteMachine
            handleMachineToolDelete={handleMachineToolDelete}
            setShowComponent={setShowComponent}
          />
        );
      default:
        return null;
    }
  };

  const handleMachineToolDelete = async () => {
    try {
      await deleteMachineById({
        machineId: activeMachineId,
      });
      notify("Machine Tool deleted successfully. ✅ ");
      fetchAllMachines();
      handleClose();
      setSelectedMenuValue("close-modal");
      setShowMenu(false);
    } catch (err) {
      throw err;
    }
  };

  if (machinesLoading) {
    return (
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (machinesError) {
    return <div>Oops! Something went wrong. Please try again.</div>;
  }
  return (
    <div className="home-predictive-maintenance">
      <div className="header-border-predictive-maintenance d-flex flex-row justify-content-between align-items-center">
        <div></div>
        <h1 className="heading" style={{ marginLeft: "11rem" }}>
          Shop Floor
        </h1>
        <Link
          className="btn btn-primary d-flex flex-column align-items-center justify-content-center rounded mr-3"
          style={{
            fontSize: "15px",
          }}
          to="/"
        >
          Back to Dashboard
        </Link>
      </div>
      <div className="row custom-container-predictive-maintenance">
        {machinesLoading ? (
          <div className="d-flex align-items-center"></div>
        ) : machines.length === 0 ? (
          <div style={{ fontSize: "large" }}>
            <p>There are no machines right now ! </p>
            <p>Add some machines to see them here</p>
          </div>
        ) : (
          machines.map((machine, machineId) => {
            return (
              <div
                className="d-flex machine-list-predictive-maintenance"
                key={machineId}
              >
                <MachineToolCard
                  machine={machine}
                  setSelectedMenuValue={setSelectedMenuValue}
                  setShowComponent={setShowComponent}
                  handleMachineToolDelete={handleMachineToolDelete}
                  setActiveMachineId={setActiveMachineId}
                  options={options}
                  fetchAllMachines={fetchAllMachines}
                />
              </div>
            );
          })
        )}
        <div
          className="d-flex flex-column justify-content-center align-items-center add-new-machine-predictive-maintenance"
          onClick={() => setShowMenu(true)}
        >
          <h1 style={{ marginTop: "0em" }}>+</h1>
          <h5 style={{ marginTop: "-1em" }}>Add New Machine</h5>
        </div>
      </div>
      {showMenu && (
        <AddNewMachine
          handleClose={handleClose}
          fetchAllMachines={fetchAllMachines}
        />
      )}
      {showComponent && (
        <div className="component-popup">{renderSelectedComponent()}</div>
      )}
    </div>
  );
}

export default PredictiveMaintenance;
