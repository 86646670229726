import React, { useState } from "react";
import "./styles.css";
import infoIcon from "assets/svg-icons/info.svg";
import getDigibasicToolTypes from "services/digiBasic/getDigibasicToolTypes";
import useAPI from "effects/useAPI";
import { useFormContext } from "react-hook-form";
import debounce from "utils/debounce";
import {
  Input,
  InputNonOptimized,
  Select,
  ErrorMessage,
  Radio,
  WatchForm,
} from "components/Forms";
import toolingSchema from "./schemas/toolingSchema";
import notify from "utils/toastMsg";

function Tooling(props) {
  const all = useFormContext();

  const [message, setMessage] = useState("");

  const [
    loadingDigibasicToolTypes,
    errorDigibasicToolTypes,
    digibasicToolTypesResponse,
  ] = useAPI(() => getDigibasicToolTypes());

  if (loadingDigibasicToolTypes) {
    return <p>Loading</p>;
  }
  if (errorDigibasicToolTypes) {
    return <p>Something went wrong.</p>;
  }

  const { digibasicToolTypes } = digibasicToolTypesResponse;
  let toolTypeOptions = [];
  digibasicToolTypes.map((option) => {
    return toolTypeOptions.push({
      key: option.digibasicToolTypeId,
      value: option.digibasicToolTypeId,
      label: option.digibasicToolType,
    });
  });

  const toolingButtonValidation = async () => {
    try {
      await toolingSchema.validate(all.getValues(), {
        abortEarly: false,
      });
      props.setSelectedOption("cuttingParameters");
      props.setCuttingDisabled(false);
    } catch (error) {
      notify(`${error.message} ❌`);
      props.setCuttingDisabled(true);
      // eslint-disable-next-line no-console
      console.error({ error });
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <div className={props.windowWidth > 994 ? "col-md-9" : "col-md-11"}>
          <h2
            className="d-flex justify-content-center"
            style={{
              marginTop: props.windowWidth <= 994 && "30px",
              marginBottom: props.windowWidth <= 994 && "30px",
            }}
          >
            Tooling
          </h2>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Tool Type:</label>
            <div className="col-sm-7">
              <Select
                name="digibasicToolTypeId"
                type="number"
                options={toolTypeOptions}
                className="form-control"
                placeholder="Shape"
              />
              <ErrorMessage name="digibasicToolTypeId" />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">
              Tool Diameter (mm):
            </label>
            <div className="col-sm-7">
              <InputNonOptimized
                name="diameter"
                type="number"
                className="form-control"
                placeholder="Diameter (D)"
              />
              <ErrorMessage name="diameter" />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Flute Length:</label>
            <div className="col-sm-7">
              <InputNonOptimized
                name="fluteLength"
                type="number"
                className="form-control"
                placeholder="Flute Length"
              />
              <ErrorMessage name="fluteLength" />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Number of Flutes:</label>
            <div className="col-sm-7">
              <InputNonOptimized
                name="fluteParameters.noOfFlutes"
                min="1"
                max="10"
                maxLength="2"
                className="form-control"
                type="number"
                placeholder="Number of Flutes"
                onKeyPress={debounce(() => {
                  const values = all.getValues();
                  const { fluteParameters } = values || null;
                  const newPitchAngle = [
                    ...(fluteParameters.pitchAngle || []),
                  ].slice(0, fluteParameters.noOfFlutes);
                  all.setValue(`fluteParameters.pitchAngle`, newPitchAngle);
                  const newHelixAngle = [...fluteParameters.helixAngle].slice(
                    0,
                    fluteParameters?.noOfFlutes
                  );
                  all.setValue(`fluteParameters.helixAngle`, newHelixAngle);
                }, 1100)}
              />
              <ErrorMessage name="fluteParameters.noOfFlutes" />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">
              Helix Angle (deg):
            </label>
            <div className="col-sm-7">
              <div className="form-row mb-1">
                <div
                  className="btn-group btn-group-toggle ml-1"
                  data-toggle="buttons"
                >
                  <Radio
                    name="fluteParameters.helixOption"
                    options={[
                      { value: 1, label: "Constant" },
                      {
                        value: 2,
                        label: "Variable",
                      },
                    ]}
                    onChange={() => {
                      const values = all.getValues();
                      const helixAngles =
                        values?.fluteParameters?.helixAngle ?? [];
                      helixAngles.forEach((angle, idx) => {
                        all.setValue(`fluteParameters.helixAngle[${idx}]`, "");
                      });
                    }}
                  />
                  <ErrorMessage name="fluteParameters.helixAngle" />
                  <img
                    className="info-button ml-1"
                    onClick={() =>
                      setMessage("Some information about helix angle variable.")
                    }
                    onMouseLeave={() => setMessage("")}
                    src={infoIcon}
                    alt="Information Icon"
                  />
                  {message && (
                    <div
                      className="message-box d-flex flex-column"
                      style={{
                        width: "10rem",
                        backgroundColor: "#dae0e4",
                      }}
                    >
                      {message}
                      <div className="d-flex justify-content-center">
                        <img
                          style={{
                            height: "12rem",
                          }}
                          src="assets/images/Diameter.png"
                          alt="Card cap"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group form-row mb-1 w-100">
                <div
                  className="col-sm-2 row mt-2"
                  key="flute-helix-0"
                  style={{ minWidth: "100px", marginLeft: "3px" }}
                >
                  <InputNonOptimized
                    key={`fluteParameters.helixAngle[0]`}
                    name={`fluteParameters.helixAngle[0]`}
                    className="form-control form-control-sm mb-1 mr-1"
                    type="number"
                    placeholder={`Flute #1`}
                  />
                  <ErrorMessage name={`fluteParameters.helixAngle[0]`} />
                </div>
                <WatchForm name="fluteParameters.noOfFlutes">
                  {(noOfFlutes, { formValues: { fluteParameters } }) => {
                    if (fluteParameters?.helixOption > 1) {
                      let out = [];
                      for (let i = 1; i < noOfFlutes; i++) {
                        if (noOfFlutes > 10) {
                          noOfFlutes = 10;
                        }
                        out.push(
                          <div
                            className="col-sm-2 row ml-1 mt-2"
                            key={`flute-helix-${i}`}
                            style={{ minWidth: "100px", marginLeft: "1px" }}
                          >
                            <InputNonOptimized
                              key={`fluteParameters.helixAngle[${i}]`}
                              name={`fluteParameters.helixAngle[${i}]`}
                              className="form-control form-control-sm mb-1 mr-1"
                              type="number"
                              placeholder={`Flute #${i + 1}`}
                            />
                            <ErrorMessage
                              name={`fluteParameters.helixAngle[${i}]`}
                            />
                            <ErrorMessage
                              name={`fluteParameters.helixOption`}
                            />
                          </div>
                        );
                      }
                      return out;
                    }
                  }}
                </WatchForm>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">
              Tool Pitch Angle (deg):
            </label>
            <div className="col-sm-7">
              <div className="form-row">
                <div className="btn-group-toggle ml-1" data-toggle="buttons">
                  <Radio
                    name="fluteParameters.pitchOption"
                    options={[
                      { value: 1, label: "Constant" },
                      {
                        value: 2,
                        label: "Variable",
                      },
                    ]}
                    onChange={() => {
                      const values = all.getValues();
                      const pitchAngles =
                        values?.fluteParameters?.pitchAngle ?? [];
                      pitchAngles.forEach((angle, idx) => {
                        all.setValue(`fluteParameters.pitchAngle[${idx}]`, "");
                      });
                    }}
                  />
                  <ErrorMessage name="fluteParameters.pitchAngle" />
                </div>
              </div>
              <div className="form-group form-row w-100">
                <WatchForm name="fluteParameters.noOfFlutes">
                  {(noOfFlutes, { formValues: { fluteParameters } }) => {
                    let out = [];
                    if (Number(fluteParameters.pitchOption) > 1) {
                      for (let i = 0; i < noOfFlutes; i++) {
                        if (noOfFlutes > 30) {
                          noOfFlutes = 30;
                        }
                        out.push(
                          <div
                            className="col-sm-2 row ml-1 mt-2"
                            key={`flute-pitch-angle-${i}`}
                            style={{ minWidth: "100px" }}
                          >
                            <InputNonOptimized
                              key={`fluteParameters.pitchAngle[${i}]`}
                              name={`fluteParameters.pitchAngle[${i}]`}
                              className="form-control form-control-sm mb-1 mr-1"
                              type="number"
                              placeholder={`Flute #${i + 1}`}
                            />
                            <ErrorMessage name="fluteParameters.pitchAngle" />
                          </div>
                        );
                      }
                    }
                    return out;
                  }}
                </WatchForm>
              </div>
            </div>
          </div>
          {/* TODO: Need check and add this properties also Line:263-327 */}
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Rake Angle (deg):</label>
            <div className="col-sm-7">
              <InputNonOptimized
                name="fluteParameters.rakeAngle"
                type="number"
                className="form-control"
                placeholder="Rake Angle"
              />
              <div>
                <ErrorMessage name="fluteParameters.rakeAngle" />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="form-row">
              <WatchForm name="fluteParameters.noOfFlutes">
                {(noOfFlutes, { formValues: { fluteParameters } }) => {
                  if (fluteParameters.rakeOption > 1) {
                    let out = [];
                    for (let i = 1; i < noOfFlutes; i++) {
                      if (noOfFlutes > 30) {
                        noOfFlutes = 30;
                      }
                      out.push(
                        <div
                          className="col-sm-2 row ml-1 mt-2"
                          key={`flute-rake-angle${i}`}
                        >
                          <Input
                            key={`fluteParameters.rakeAngle[${i}]`}
                            name={`fluteParameters.rakeAngle[${i}]`}
                            className="form-control form-control-sm mb-1 mr-1"
                            type="number"
                            placeholder={`Flute #${i + 1}`}
                          />
                          <ErrorMessage name="fluteParameters.rakeAngle" />
                        </div>
                      );
                    }
                    return out;
                  }
                }}
              </WatchForm>
            </div>
          </div>
          <div className="mt-5 d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-info waves-effect waves-light"
              style={{
                width: "200px",
                backgroundColor: "#3498DB",
                color: "#fff",
              }}
              onClick={toolingButtonValidation}
            >
              Next
            </button>
          </div>
        </div>
        <div style={{ marginLeft: "-7rem" }}>
          <img
            style={{
              height: "30rem",
              display: props.windowWidth <= 994 && "none",
            }}
            src="assets/images/Diameter.png"
            alt="Card cap"
          />
        </div>
      </div>
    </>
  );
}

export default Tooling;
