const MATERIAL_SELECTION = [
  { key: 1, value: 1, label: "AISI P20 Mold Steel" },
  { key: 2, value: 2, label: "AISI 4340 Steel" },
  { key: 3, value: 3, label: "AISI 1045 Steel" },
  { key: 4, value: 4, label: "Aluminium 6061-T6" },
  { key: 5, value: 5, label: "Aluminium 7050-T74" },
  { key: 6, value: 6, label: "Titanium Ti6Al4V" },
  { key: 7, value: 7, label: "Inconel 718" },
];

const MILLING_TYPE_SELECTION = [
  { key: 1, value: 1, label: "Up Milling" },
  { key: 2, value: 2, label: "Down Milling" },
  { key: 3, value: 3, label: "Slotting" },
];

export { MATERIAL_SELECTION, MILLING_TYPE_SELECTION };
