import React, { useState, useCallback, useEffect } from "react";
import uploadFile from "services/files/uploadFile";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
// import humanFileSize from "utils/humanFileSize";

const FileUpload = ({ name }) => {
  const [loading, setLoading] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const { setValue, watch } = useFormContext();
  const fileValue = watch(name);

  useEffect(() => {
    if (!fileValue) {
      setDroppedFiles([]);
    }
  }, [fileValue]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      try {
        setLoading(true);
        return uploadFile({
          attachment: acceptedFiles[0],
        }).then((response) => {
          const { uploadUrl } = response.data;
          const originalFileName = acceptedFiles[0].name;
          const localStorageKey = `originalNameOf-${name}`;
          setValue(name, uploadUrl, { shouldValidate: true });
          setDroppedFiles([...droppedFiles, ...acceptedFiles]);
          window.localStorage.setItem(localStorageKey, originalFileName);
          setLoading(false);

          return response;
        });
      } catch (err) {
        setLoading(false);
        // eslint-disable-next-line no-console
        console.log("Error uploading file");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, setValue, fileValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const originalFileName = localStorage.getItem(`originalNameOf-${name}`);
  let file = "";
  if (originalFileName) {
    file = <li>{originalFileName}</li>;
  }
  if (droppedFiles[0]) {
    const path = droppedFiles[0].path;
    // const sizeInBytes = droppedFiles[0].size;
    // const sizeInKiloBytes = humanFileSize(sizeInBytes, true);

    file = (
      <li key={path}>
        {path}
        {/* - {sizeInKiloBytes}{" "} */}
      </li>
    );
  }

  const handleDeleteFileUpload = (event) => {
    event.stopPropagation();
    setDroppedFiles([]);

    return window.localStorage.removeItem(`originalNameOf-${name}`);
  };

  return (
    <div
      {...getRootProps({
        className: loading ? "dropzone2 disabled" : "dropzone2",
      })}
    >
      {loading ? (
        <div className="spinner-centered ">
          <i className="fa fa-spinner fa-spin fa-2x fa-fw"></i>
          <p style={{ marginTop: "5px" }}>Uploading File. Please wait..</p>
        </div>
      ) : (
        <>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              {originalFileName ? (
                <>
                  <p className="text-muted">
                    Drag 'n' drop a new file here, or click to select a new file
                    to replace your upload
                  </p>
                </>
              ) : (
                <p className="text-muted">
                  Drag 'n' drop your file here, or click to select a file
                </p>
              )}
            </>
          )}
          <aside>
            <ul>{file}</ul>
            {file && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                }}
              >
                <button
                  style={{
                    border: "1px solid red",
                    color: "red",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                  }}
                  className="fa fa-trash"
                  aria-hidden="true"
                  onClick={handleDeleteFileUpload}
                ></button>
              </div>
            )}
          </aside>
        </>
      )}
    </div>
  );
};

export default FileUpload;
