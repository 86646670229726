import http from "utils/http";
import { user } from "constants/endpoints";

const postNotifyMeDashboard = ({ productName }) => {
  const POST_NOTIFY_ME_ENDPOINT = user.postNotifyMeDashboard();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_NOTIFY_ME_ENDPOINT}`, {
    data: {
      productName,
    },
  });
};

export default postNotifyMeDashboard;
