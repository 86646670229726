import http from "utils/http";
import { frfDatabase } from "constants/endpoints";

const getEventLogsByMachineId = ({ machineId, eventTableOffset }) => {
  const GET_ENDPOINT = frfDatabase.getEventLogsByMachineId({ machineId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(
    `${API_BASE_URL}${GET_ENDPOINT}?eventTableOffset=${eventTableOffset}`
  );
};

export default getEventLogsByMachineId;
