import { React } from "react";
import "./style.css";

function DeleteMachine({ handleMachineToolDelete, setShowComponent }) {
  return (
    <div className="modal-container" onClick={() => setShowComponent(false)}>
      <div
        className="container-delete-machine"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h2 className="title-delete-machine">Delete Machine</h2>
        <div className="form-wrapper">
          <div className="d-flex mt-3 justify-content-around">
            <button
              onClick={() => setShowComponent(false)}
              className="btn btn-primary waves-effect button-delete-machine"
            >
              Cancel
            </button>
            <button
              className="btn btn-danger waves-effect button-delete-machine"
              type="button"
              onClick={handleMachineToolDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteMachine;
