import http from "utils/http";
import { sessions } from "constants/endpoints";

const deletePrtUploadById = ({ uploadId }) => {
  const ENDPOINT = sessions.deletePrtUploadById({
    uploadId,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.delete(`${API_BASE_URL}${ENDPOINT}`);
};

export default deletePrtUploadById;
