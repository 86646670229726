import React from "react";
import "./index.css";

const EssentialOptimizationModal = ({
  setOpenEssentialOptimizationModal,
  setSelectedEssentialOptimization,
}) => {
  return (
    <div className="essential-optimization-modal-content mt-1">
      <div className="d-flex justify-content-between align-items-baseline mb-2">
        <h4 className="notify-header" style={{ marginLeft: "9rem" }}>
          Essential Optimization Apps
        </h4>
        <button
          class="close"
          type="button"
          onClick={() => setOpenEssentialOptimizationModal(false)}
        >
          &times;
        </button>
      </div>
      <div className="d-flex flex-column mt-3">
        <div className="d-flex flex-row">
          <div className="dashboard-card">
            <div>
              <div className="card-body">
                <p className="card-heading">Feed Rate Optimizer</p>
                <p>
                  Apply optimal feed rates to optimize the{" "}
                  <strong>cutting forces</strong> of your machining process to
                  increase speed.
                </p>
                <button
                  className="btn btn-primary waves-effect waves-light get-notified-button mb-3"
                  style={{
                    marginTop: "8rem",
                  }}
                  onClick={() => {
                    setSelectedEssentialOptimization("/digi-basic");
                  }}
                >
                  Access Feed Rate app
                </button>
              </div>
            </div>
          </div>
          <div className="dashboard-card">
            <div>
              <div className="card-body">
                <p className="card-heading">Spindle Speed Optimizer</p>
                <p>
                  Apply optimal <strong>Feed Rates</strong> and{" "}
                  <strong>Spindle Speeds</strong> to achieve the fastest
                  chatter-free results (requires FRF data).
                </p>
                <button
                  className="btn btn-primary waves-effect waves-light get-notified-button mb-3"
                  style={{
                    marginTop: "8rem",
                  }}
                  onClick={() => {
                    setSelectedEssentialOptimization("/digi-stability");
                  }}
                >
                  Access Spindle Speed app
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EssentialOptimizationModal;
