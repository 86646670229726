import React, { useState } from "react";
import { Select, InputNonOptimized, SubmitButton } from "components/Forms";
import getDigibasicConstraints from "services/digiBasic/getDigibasicConstraints";
import useAPI from "effects/useAPI";

const FeedRateOptimization = ({
  optimizedFeedRate,
  digibasicResponse,
  setSelectedOption,
}) => {
  const [
    errorDigibasicConstraints,
    loadingDigibasicConstraints,
    digibasicConstraintsResponse,
  ] = useAPI(() => getDigibasicConstraints());

  const [minimumOptimizationValue, setMinimumOptimizationValue] = useState(
    digibasicResponse.OptimisationValidationRules.Min_FeedForce
  );
  // const [constraintMagnitudePlaceholder, setConstraintMagnitudePlaceholder] =
  //   useState(digibasicResponse.OptimisationValidationRules.Min_FeedForce);
  const [constraintLabel, setConstraintLabel] = useState("(N)");
  // eslint-disable-next-line no-unused-vars
  const [constraintName, setConstraintName] = useState(
    digibasicResponse.OptimisationValidationRules.Min_FeedForce
  );
  const [optimisationValidationRuleText, setOptimisationValidationRuleText] =
    useState(digibasicResponse.OptimisationValidationRules.Min_FeedForcetxt);

  if (loadingDigibasicConstraints) {
    return <p>Loading</p>;
  }
  if (errorDigibasicConstraints) {
    return <p>Something went wrong.</p>;
  }

  const { digibasicConstraints } = digibasicConstraintsResponse;

  const constraintsOptions = digibasicConstraints.map((option) => {
    return {
      key: option.optimisationConstraintId,
      value: option.optimisationConstraintId,
      label: option.optimisationConstraint,
    };
  });

  const constraintLabelSelection = (event) => {
    const constraintLabel = event.target[event.target.value - 1].textContent;
    const constraintMap = {
      "Feed Force": {
        label: "(N)",
        value: digibasicResponse.OptimisationValidationRules.Min_FeedForce,
        validationText:
          digibasicResponse.OptimisationValidationRules.Min_FeedForcetxt,
      },
      "Cross Feed Force": {
        label: "(N)",
        value: digibasicResponse.OptimisationValidationRules.Min_CrossFeedForce,
        validationText:
          digibasicResponse.OptimisationValidationRules.Min_CrossFeedForcetxt,
      },
      "Axial Force": {
        label: "(N)",
        value: digibasicResponse.OptimisationValidationRules.Min_AxialForce,
        validationText:
          digibasicResponse.OptimisationValidationRules.Min_AxialForcetxt,
      },
      "Side Force": {
        label: "(N)",
        value: digibasicResponse.OptimisationValidationRules.Min_SideForce,
        validationText:
          digibasicResponse.OptimisationValidationRules.Min_SideForcetxt,
      },
      Torque: {
        label: "(Nm)",
        value: digibasicResponse.OptimisationValidationRules.Min_Torque,
        validationText:
          digibasicResponse.OptimisationValidationRules.Min_Torquetxt,
      },
      Power: {
        label: "(W)",
        value: digibasicResponse.OptimisationValidationRules.Min_Power,
        validationText:
          digibasicResponse.OptimisationValidationRules.Min_Powertxt,
      },
      MRR: {
        label: "(cm3/min)",
        value: digibasicResponse.OptimisationValidationRules.Min_MRR,
        validationText:
          digibasicResponse.OptimisationValidationRules.Min_MRRtxt,
      },
    };

    setConstraintName(constraintLabel);
    setConstraintLabel(constraintMap[constraintLabel].label);
    setMinimumOptimizationValue(constraintMap[constraintLabel].value);
    setOptimisationValidationRuleText(
      constraintMap[constraintLabel].validationText
    );
    // setConstraintMagnitudePlaceholder(constraintMap[constraintLabel].value);
  };

  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="d-flex flex-column">
        <div className="mb-4">
          <h2 className="d-flex justify-content-center">
            Feed Rate Optimization
          </h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm justify-content-center">
              <div
                className="d-flex flex-column justify-content-start"
                style={{ maxWidth: "35em" }}
              >
                <div className="d-flex col-sm-8 mb-3">
                  <label className="col-sm-10 col-form-label">
                    Optimization Constraint:
                  </label>
                  <div className="col-sm-12">
                    <Select
                      name="optimizationParameters.digibasicOptimisationConstraintId"
                      options={constraintsOptions}
                      className="form-control col-sm-12"
                      placeholder="Shape"
                      onChange={(event) => constraintLabelSelection(event)}
                    />
                  </div>
                </div>
                <div className="d-flex col-sm-8 mb-5">
                  <label className="col-sm-10 col-form-label">
                    Constraint Magnitude:
                  </label>
                  <div className="col-sm-12">
                    <InputNonOptimized
                      name="optimizationParameters.selectedConstraintMagnitude"
                      type="float"
                      className="form-control"
                    />
                  </div>
                  <div
                    className="align-items-center d-flex"
                    style={{ fontSize: "16px" }}
                  >
                    {constraintLabel}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {minimumOptimizationValue !== "" ? (
            <div className="alert alert-info" role="alert">
              <h5 className="d-flex justify-content-center">
                {/* Entered value for optimization should be higher than{" "} */}
                {optimisationValidationRuleText}
                {" : "}
                {minimumOptimizationValue}
              </h5>
            </div>
          ) : (
            ""
          )}
          {optimizedFeedRate !== {} && optimizedFeedRate.data !== undefined && (
            <div className="alert alert-success  mb-4" role="alert">
              <h5 className="d-flex justify-content-center">
                New Feed Rate is:{" "}
                {parseFloat(
                  optimizedFeedRate.data.lambdaResponse.Optimised.Feedrate_New
                ).toFixed(2)}{" "}
                (mm/min)
              </h5>
            </div>
          )}
          <div className="d-flex justify-content-around">
            <button
              className="btn btn-info waves-effect waves-light"
              style={{
                width: "7rem",
                backgroundColor: "#3498DB",
                color: "#fff",
                height: "2.6rem",
              }}
              onClick={() => setSelectedOption("history")}
              type="button"
            >
              Go to History
            </button>
            <SubmitButton
              className="btn btn-success btn-lg waves-effect waves-light mr-4 mb-3"
              style={{ width: "7rem" }}
            >
              Submit
            </SubmitButton>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default FeedRateOptimization;
