import * as yup from "yup";

const registrationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .label("First Name")
    .typeError("First Name must be a text."),
  lastName: yup
    .string()
    .required()
    .label("Last Name")
    .typeError("Last Name must be a text."),
  password: yup
    .string()
    .min(8)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must include at least one capital letter, one number, and one special symbol. Allowed special symbols is: @, $, !, %, *, ?, &"
    )
    .required()
    .label("Password")
    .typeError("Password must be at least 8 characters."),
  phoneNumber: yup
    .number()
    .required()
    .label("Phone Number")
    .typeError("Phone Number is a required field."),
  country: yup
    .string()
    .required()
    .label("Country")
    .typeError("Country is a required field."),
  discountCode: yup
    .string()
    .label("Discount code")
    .typeError("Discount code should be string."),
});

export default registrationSchema;
