import { useFormContext } from "react-hook-form";
import get from "utils/get";

const ErrorMessage = ({ name, ...rest }) => {
  const { formState } = useFormContext();

  const fieldError = get(formState.errors, name);

  if (fieldError) {
    return <span style={{ color: "#a01" }}>{fieldError.message}</span>;
  }

  return null;
};

export default ErrorMessage;
