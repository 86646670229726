import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { InputNonOptimized, ErrorMessage } from "components/Forms";

function InputWithTooltip({ name, tooltipMessage, ...props }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const id = name.replace(/\./g, "-");

  return (
    <div>
      <InputNonOptimized id={id} name={name} {...props} />
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={id}
        toggle={toggleTooltip}
      >
        {tooltipMessage}
      </Tooltip>
      <ErrorMessage name={name} />
    </div>
  );
}

export default InputWithTooltip;
