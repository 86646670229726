import React from "react";
import {
  Form,
  InputNonOptimized,
  ErrorMessage,
  Select,
  SubmitButton,
} from "components/Forms";
import notify from "utils/toastMsg";
import "./style.css";
import * as yup from "yup";
import postEventLogsByMachineId from "services/postEventLogsByMachineId";

const schema = yup.object().shape({
  eventType: yup
    .string()
    .label("Event Type")
    .typeError("Event Type must be a number.")
    .required(),
  eventDate: yup
    .string()
    .label("Date")
    .typeError("Date must be a number.")
    .required(),
  status: yup
    .string()
    .label("Status")
    .typeError("Status must be a number.")
    .required(),
  spindleHours: yup
    .number()
    .label("Spindle Hours")
    .typeError("Spindle Hours must be a number.")
    .required(),
});

function ReportAnEvent({
  setActiveComponent,
  fetchAllLogsByMachineId,
  machineId,
}) {
  const [selectedEvent, setSelectedEvent] = React.useState("");
  const [customEvent, setCustomEvent] = React.useState("");

  const handleEventChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "custom") {
      setSelectedEvent("custom");
    } else {
      setSelectedEvent(selectedValue);
      setCustomEvent("");
    }
  };

  const handleEventSubmit = async (values, event, methods) => {
    try {
      await postEventLogsByMachineId({
        ...values,
        machineId,
        selectedEvent,
        customEvent,
      });
      notify("Event created successfully. ✅");
      fetchAllLogsByMachineId();
      setActiveComponent(null);
    } catch (err) {
      notify("Please fill all fields. ❌");
      throw err;
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center modal-container-report-an-event"
      onClick={() => setActiveComponent(null)}
    >
      <div
        className="main-container-pop-report-an-event"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="pop-up-content-report-an-event">
          <h1 className="text-center">Report An Event</h1>
          <div className="mt-5 mr-5">
            <Form
              initialValues={{
                eventType: "Faulty ball bearings",
                status: "Fault reported",
                spindleHours: "",
              }}
              validationSchema={schema}
              onSubmit={handleEventSubmit}
            >
              <div className="text-center">
                <label className="input-label fs-1r">Event Details</label>
              </div>
              <div className="input-wrapper">
                <div className="d-flex flex-row w-100 mb-2">
                  <label className="col-3 input-label">Event Type:</label>
                  <Select
                    className="form-control"
                    name="eventType"
                    options={[
                      {
                        key: 1,
                        value: "Faulty ball bearings",
                        label: "Faulty ball bearings",
                      },
                      {
                        key: 2,
                        value: "Damage to tool holder",
                        label: "Damage to tool holder",
                      },
                      {
                        key: 3,
                        value: "Damage to spindle shaft",
                        label: "Damage to spindle shaft",
                      },
                      {
                        key: 4,
                        value: "Defective bearing lubrication",
                        label: "Defective bearing lubrication",
                      },
                      {
                        key: 5,
                        value: "Clamping system failure",
                        label: "Clamping system failure",
                      },
                      {
                        key: 6,
                        value: "Cooling system failure",
                        label: "Cooling system failure",
                      },
                      {
                        key: 7,
                        value: "Excessive Noise",
                        label: "Excessive Noise",
                      },
                      {
                        key: 8,
                        value: "Collision",
                        label: "Collision",
                      },
                      {
                        key: 9,
                        value: "Unbalanced tooling assembly",
                        label: "Unbalanced tooling assembly",
                      },
                      {
                        key: 10,
                        value: "Spindle change",
                        label: "Spindle change",
                      },
                      { key: 11, value: "custom", label: "Add custom event" },
                    ]}
                    onChange={handleEventChange}
                  />
                  <ErrorMessage name="eventType" />
                </div>
                {selectedEvent === "custom" && (
                  <div className="d-flex flex-row w-100 mb-2">
                    <label className="col-3 input-label">Custom Event:</label>
                    <InputNonOptimized
                      type="text"
                      name="customEvent"
                      className="form-control"
                      value={customEvent}
                      onChange={(e) => setCustomEvent(e.target.value)}
                      placeholder="Enter your custom event"
                    />
                  </div>
                )}
                <div className="d-flex flex-row w-100 mb-3">
                  <label className="col-3 input-label">Event Date:</label>
                  <InputNonOptimized
                    type="date"
                    name="eventDate"
                    className="form-control"
                  />
                  <ErrorMessage name="eventDate" />
                </div>
                <div className="d-flex flex-row w-100">
                  <label className="col-3 input-label">Status:</label>
                  <Select
                    className="form-control"
                    name="status"
                    options={[
                      {
                        key: 1,
                        value: "Fault reported",
                        label: "Fault reported",
                      },
                      {
                        key: 2,
                        value: "Repair scheduled",
                        label: "Repair scheduled",
                      },
                      {
                        key: 3,
                        value: "Repair Complete",
                        label: "Repair Complete",
                      },
                    ]}
                  />
                  <ErrorMessage name="status" />
                </div>
                <div className="d-flex flex-row w-100 mt-3">
                  <label className="col-3 input-label">Spindle Hours:</label>
                  <InputNonOptimized
                    name="spindleHours"
                    type="number"
                    className="form-control"
                    placeholder="Spindle Hours"
                  />
                  <ErrorMessage name="spindleHours" />
                </div>
              </div>
              <div className="d-flex mt-4 justify-content-around">
                <button
                  className="btn btn-warning waves-effect"
                  onClick={() => setActiveComponent(null)}
                >
                  Cancel
                </button>
                <SubmitButton
                  className="btn btn-success waves-effect "
                  type="submit"
                  style={{ width: "75px" }}
                >
                  Save
                </SubmitButton>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportAnEvent;
