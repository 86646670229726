import { useFormContext } from "react-hook-form";
import Slider from "rc-slider";
import get from "utils/get";
import React from "react";

import "rc-slider/assets/index.css";

const FormSlider = ({
  options,
  name,
  marks,
  step,
  allowCross,
  label,
  min,
  max,
  ...rest
}) => {
  const { register, formState, setValue, getValues } = useFormContext();

  const field = get(formState.errors, name);
  const fieldError = Boolean(field);
  const registeredField = register(name);

  return (
    <div
      className="custom-control"
      onChange={() => {
        const formValues = getValues();
        setValue(name, !get(formValues, name));
      }}
    >
      <label htmlFor={name} style={{ cursor: "pointer" }}>
        {label}
      </label>
      <div>
        <Slider
          key={name}
          step={step}
          min={min}
          max={max}
          marks={marks}
          allowCross={allowCross}
          onChange={(value) =>
            registeredField.onChange({ target: { name, value } })
          }
          {...rest}
          {...(fieldError ? { style: { borderColor: "#a01" } } : {})}
        />
      </div>
    </div>
  );
};

export default FormSlider;
