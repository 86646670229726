import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Authentication from "services/Authentication";

let auth = new Authentication();

const getQueryParams = (query = null) =>
  (query || window.location.search.replace("?", ""))
    .split("&")
    .map((e) => e.split("=").map(decodeURIComponent))
    // eslint-disable-next-line no-sequences
    .reduce((r, [k, v]) => ((r[k] = v), r), {});

const AdamosLoginSuccess = () => {
  let history = useHistory();

  useEffect(() => {
    const { token } = getQueryParams();
    auth.loginWithAdamos(token);
    history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <img className="login-logo" src="assets/images/ll2.png" alt="logo" />
      <div className="account-pages m-0">
        <div className="container">
          <h4 className="login-header" style={{ marginRight: "100px" }}>
            Welcome to Productive Machines dashboard.
          </h4>
        </div>
      </div>
    </>
  );
};

export default AdamosLoginSuccess;
