import React from "react";
import "./styles/ion-rangeslider/css/ion.rangeSlider.css";
import "./styles/ion-rangeslider/css/ion.rangeSlider.min.css";
import { useState } from "react";
import {
  Select,
  ErrorMessage,
  SubmitButton,
  Radio,
  FormSlider,
} from "components/Forms";
import WatchForm from "components/Forms/WatchForm";
import { useFormContext } from "react-hook-form";
import { MILLING_TYPES, MATERIAL_SELECTION } from "./constants/constants";
import "./index.css";

const CuttingParameters = (props) => {
  const formCtx = useFormContext();

  const [image, setImage] = useState("assets/images/up-milling.png");
  const [sliderValues, setSliderValues] = useState({
    widthOfCut: 1,
  });

  // const speedType = formCtx?.getValues("cuttingParameters.speedTypeSelection");

  const millingTypesRadioHandler = (value) => {
    switch (value) {
      case 1:
        setImage("assets/images/up-milling.png");
        break;
      case 2:
        setImage("assets/images/down-milling.png");
        break;
      case 3:
        setImage("assets/images/slotting.png");
        break;
      default:
        setImage("assets/images/up-milling.png");
    }
  };

  // const SpeedTypeSliderHandler = (value) => {
  //   // if (speedType === 1) {
  //   //   formCtx.setValue("cuttingParameters.cuttingSpeed", 0);
  //   //   formCtx.setValue("cuttingParameters.spindleSpeed", value);
  //   //   setSliderValues({ ...sliderValues, spindleSpeed: value });
  //   // } else {
  //   //   formCtx.setValue("cuttingParameters.spindleSpeed", 0);
  //   formCtx.setValue("cuttingParameters.cuttingSpeed", value);
  //   setSliderValues({ ...sliderValues, cuttingSpeed: value });
  //   // }
  // };

  const widthOfCutInputOnChangeHandler = (e, diameter) => {
    const inputValue = parseFloat(e.target.value);
    let newValue = inputValue;
    if (!isNaN(inputValue)) {
      if (inputValue > diameter) {
        newValue = diameter;
      }
      if (inputValue < 0.1) {
        newValue = 0.1;
      }
    } else {
      newValue = 0.1;
    }
    setSliderValues({
      ...sliderValues,
      widthOfCut: newValue,
    });
    formCtx.setValue("cuttingParameters.widthOfCut", newValue);
  };

  // const spindleCuttingSpeedInputOnChangeHandler = (e) => {
  //   let inputValue = parseFloat(e.target.value);
  //   const maxLimit = 15000;

  //   if (isNaN(inputValue)) {
  //     inputValue = 0;
  //   }
  //   if (inputValue < 0) {
  //     inputValue = 0;
  //   }
  //   if (inputValue > maxLimit) {
  //     inputValue = maxLimit;
  //   }

  //   const updatedValues = { ...sliderValues };
  //   updatedValues.cuttingSpeed = inputValue;

  //   setSliderValues({
  //     ...sliderValues,
  //     cuttingSpeed: updatedValues.cuttingSpeed,
  //   });

  //   formCtx.setValue(
  //     "cuttingParameters.cuttingSpeed",
  //     updatedValues.cuttingSpeed
  //   );

  //   setSliderValues(updatedValues);
  // };

  return (
    <div className="d-flex flex-column">
      {props.windowWidth <= 994 && (
        <div className="align-self-center col-5 ml-5">
          <img
            style={{
              maxHeight: "350px",
              width: "100%",
              marginTop: props.windowWidth <= 994 ? "10%" : "50%",
            }}
            src={image}
            alt="Milling process"
          />
        </div>
      )}
      <div className="d-flex justify-content-center">
        <h4 className="text-center mb-4">Cutting Parameter Selection</h4>
      </div>
      <div
        className={
          props.windowWidth <= 994
            ? "d-flex justify-content-center"
            : "d-flex justify-content-start"
        }
      >
        <div
          className={
            props.windowWidth <= 994
              ? "cutting-parameters-input-container col-12 p-0"
              : "cutting-parameters-input-container col-8 pl-5"
          }
        >
          <div className="inputs">
            <div className="input-wrapper d-flex flex-row align-items-center pb-2">
              <div className="d-flex flex-row col-3">
                <label
                  className="col-form-label"
                  style={{ width: props.windowWidth < 994 && "100px" }}
                >
                  Milling Type
                </label>
              </div>
              <div
                className="input-wrapper d-flex col-9 justify-content-start align-items-center"
                style={{
                  marginLeft: props.windowWidth < 994 && "40px",
                }}
              >
                <div className="d-flex col-12 flex-row">
                  <div className="btn-group-toggle w-100" data-toggle="buttons">
                    <Radio
                      onChange={(value) => millingTypesRadioHandler(value)}
                      name="cuttingParameters.millingTypeSelection"
                      options={MILLING_TYPES}
                    />
                  </div>
                  <ErrorMessage name="cuttingParameters.millingTypeSelection" />
                </div>
              </div>
            </div>
            <div className="input-wrapper d-flex flex-row align-items-center mb-3">
              <div className="d-flex flex-column col-3 align-items-center">
                <label
                  className="col-form-label"
                  style={{ width: props.windowWidth < 994 && "100px" }}
                >
                  Width of cut (mm):
                </label>
              </div>
              <WatchForm name="cuttingParameters.millingTypeSelection">
                {(millingTypeSelection, { formValues: { diameter } }) => {
                  if (
                    millingTypeSelection === 1 ||
                    millingTypeSelection === 2
                  ) {
                    return (
                      <div className="d-flex col-9 align-items-center">
                        <div className="d-flex flex-column col-10">
                          <FormSlider
                            onChange={(value) => {
                              setSliderValues({
                                ...sliderValues,
                                widthOfCut: value,
                              });
                              formCtx.setValue(
                                "cuttingParameters.widthOfCut",
                                value
                              );
                            }}
                            name="cuttingParameters.widthOfCut"
                            min={0.1}
                            max={diameter}
                            step={0.1}
                            marks={{
                              0.1: "0.1",
                              [diameter]: diameter,
                            }}
                            value={sliderValues.widthOfCut}
                            allowCross={false}
                            style={{
                              width: 290,
                              marginLeft:
                                props.windowWidth <= 994 ? "-6px" : "-1em",
                            }}
                          />
                        </div>
                        <div
                          className="d-flex flex-row ml-5 col-2 align-items-center"
                          style={{ height: 35 }}
                        >
                          <input
                            style={{ width: 52 }}
                            className={
                              props.windowWidth > 994
                                ? "slider-input mt-4 mb-3 ml-3"
                                : "slider-input mt-4 mb-3"
                            }
                            type="number"
                            step="0.1"
                            value={sliderValues.widthOfCut}
                            onChange={(e) =>
                              widthOfCutInputOnChangeHandler(e, diameter)
                            }
                            onBlur={(e) => {
                              if (e.target.value === "") {
                                e.target.value = "0";
                                setSliderValues({
                                  ...sliderValues,
                                  widthOfCut: 0,
                                });
                                formCtx.setValue(
                                  "cuttingParameters.widthOfCut",
                                  0
                                );
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                              }
                            }}
                            max={diameter}
                          />
                        </div>
                      </div>
                    );
                  }
                  if (millingTypeSelection === 3) {
                    return (
                      <div className="d-flex flex-column col-7 align-items-center ml-1">
                        <FormSlider
                          className={
                            props.windowWidth > 994
                              ? "disabled-field-fullscreen"
                              : "disabled-field-tablet"
                          }
                          onChange={(value) => {
                            setSliderValues({
                              ...sliderValues,
                              widthOfCut: value,
                            });
                            formCtx.setValue(
                              "cuttingParameters.widthOfCut",
                              value
                            );
                          }}
                          name="cuttingParameters.widthOfCut"
                          disabled={true}
                          min={0.1}
                          max={diameter}
                          marks={{
                            0: "0",
                            [diameter]: diameter,
                          }}
                          value={diameter}
                          allowCross={false}
                          style={{
                            width: 290,
                            marginLeft:
                              props.windowWidth <= 994 ? "-6px" : "-1em",
                          }}
                        />
                      </div>
                    );
                  }
                }}
              </WatchForm>
            </div>
            {/* <div className="input-wrapper d-flex flex-row align-items-center mb-3">
              <div className="d-flex flex-column col-3 align-items-center">
                {speedType === 1 ? (
                  <label
                    className="col-form-label"
                    style={{ width: props.windowWidth < 994 && "100px" }}
                  >
                    Maximum Speed (RPM):
                  </label>
                ) : (
                  <label
                    className="col-form-label"
                    style={{ width: props.windowWidth < 994 && "100px" }}
                  >
                    Cutting Speed (mm/min):
                  </label>
                )}
              </div>
              <div className="d-flex col-9 align-items-center">
                <div className="d-flex flex-column col-10">
                  <FormSlider
                    onChange={(value) => SpeedTypeSliderHandler(value)}
                    value={sliderValues.cuttingSpeed}
                    name={"cuttingParameters.cuttingSpeed"}
                    min={0}
                    max={15000}
                    marks={CUTTING_SPEED_MARKS}
                    style={{ width: 290, marginLeft: "-1em" }}
                  />
                  <div className="mt-4">
                    <ErrorMessage name="cuttingParameters.cuttingSpeed" />
                  </div>
                </div>
                <div
                  className="d-flex flex-row ml-5 col-2 align-items-center"
                  style={{ height: 35 }}
                >
                  <input
                    className={
                      props.windowWidth > 994
                        ? "slider-input mt-4 mb-3"
                        : "slider-input mt-4 mb-3"
                    }
                    type="number"
                    step="0.1"
                    value={sliderValues.cuttingSpeed}
                    style={{ width: 52 }}
                    onChange={(e) => spindleCuttingSpeedInputOnChangeHandler(e)}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        const updatedValues = { ...sliderValues };
                        updatedValues.cuttingSpeed = 0;
                        setSliderValues(updatedValues);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    max={15000}
                  />
                </div>
              </div>
            </div> */}
            <div className="d-flex ml-4 justify-content-center"></div>
            <div className="input-wrapper d-flex flex-row align-items-center mb-3">
              <div className="d-flex flex-column col-3 align-items-center">
                <label
                  className="col-form-label"
                  style={{ width: props.windowWidth < 994 && "100px" }}
                >
                  Material Selection:
                </label>
              </div>
              <div className="d-flex col-10">
                <Select
                  name="cuttingParameters.materialSelection"
                  options={MATERIAL_SELECTION}
                  className="form-control ml-3 w-75"
                  placeholder="Shape"
                />
              </div>
            </div>
          </div>
          <div
            style={{ marginLeft: "5em" }}
            className="d-flex align-items-center justify-content-center"
          >
            <SubmitButton
              type="submit"
              className="btn btn-info waves-effect waves-light"
              style={{
                width: "200px",
                backgroundColor: "#3398DB",
                color: "#fff",
              }}
              disabled={props.loading}
            >
              Submit
            </SubmitButton>
          </div>
        </div>
        {props.windowWidth > 994 && (
          <div className="align-self-start col-4 ml-3 mt-5">
            <img
              style={{
                maxHeight: "350px",
                width: "600px",
                marginTop: "2rem",
                marginLeft: "-6rem",
              }}
              src={image}
              alt="Milling process"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CuttingParameters;
