import React, { useState } from "react";
import FileDownload from "components/FileDownload";

const ViewCuttingTool = ({ cuttingToolDetails, cuttingToolId }) => {
  const [isViewCuttingToolModalOpen, setIsViewCuttingToolModalOpen] =
    useState(false);

  const watchModalStatus = () => {
    setIsViewCuttingToolModalOpen(!isViewCuttingToolModalOpen);
  };
  return (
    <>
      <i
        className="ion ion-md-eye view-icon"
        data-toggle="modal"
        data-target={`#view-session-modal-${cuttingToolId}`}
        style={{ cursor: "pointer" }}
      ></i>
      <div
        id={`view-session-modal-${cuttingToolId}`}
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={watchModalStatus}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                className="row cardss-view"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-12">
                  <div className="card m-b-30">
                    <div className="card-body">
                      <h4 className="mt-0 header-title text-center">Tooling</h4>
                      <p className="text-muted m-b-30 text-center">
                        Please see the cutting tool and tool holder information
                        below.
                      </p>
                      <div className="tabs">
                        <div className="tab">
                          <label
                            className="tab-label-view"
                            for={`cutting-tool-details=${cuttingToolId}`}
                          >
                            {cuttingToolDetails.cuttingToolName}
                          </label>
                          <div className="">
                            <ul>
                              <li className="view-list">
                                <strong>cuttingToolId: </strong>
                                {cuttingToolDetails.cuttingToolId}
                              </li>
                              <li className="view-list">
                                <strong>operationName: </strong>
                                {cuttingToolDetails.operationName}
                              </li>
                              <li className="view-list">
                                <strong>toolHolderName: </strong>
                                {cuttingToolDetails.toolHolderName}
                              </li>
                              {cuttingToolDetails.toolHolderUrl && (
                                <li className="view-list">
                                  <strong>toolHolderUrl: </strong>
                                  {cuttingToolDetails.toolHolderUrl}
                                </li>
                              )}
                              {cuttingToolDetails.toolHolderIsoNo && (
                                <li className="view-list">
                                  <strong>toolHolderIsoNo: </strong>
                                  {cuttingToolDetails.toolHolderIsoNo}
                                </li>
                              )}
                              <li className="view-list">
                                <strong>cuttingToolName: </strong>
                                {cuttingToolDetails.cuttingToolName}
                              </li>
                              <li className="view-list">
                                <strong>cuttingToolNumber: </strong>
                                {cuttingToolDetails.cuttingToolNumber}
                              </li>
                              {cuttingToolDetails.toolUrl && (
                                <li className="view-list">
                                  toolUrl: {cuttingToolDetails.toolUrl}
                                </li>
                              )}
                              {cuttingToolDetails.toolIsoNo && (
                                <li className="view-list">
                                  toolIsoNo: {cuttingToolDetails.toolIsoNo}
                                </li>
                              )}
                              {cuttingToolDetails.toolDatasheet && (
                                <li className="view-list">
                                  <strong>toolDatasheet: </strong>
                                  <FileDownload
                                    url={cuttingToolDetails.toolDatasheet}
                                  />
                                </li>
                              )}
                              {Object.keys(
                                cuttingToolDetails.toolParameters || {}
                              ).length > 3 && (
                                <li className="view-list">
                                  <strong>toolParameters: </strong>
                                  {JSON.stringify(
                                    cuttingToolDetails.toolParameters
                                  )}
                                </li>
                              )}
                              <li className="view-list">
                                <strong>cuttingToolFRFxxUpload: </strong>
                                <FileDownload
                                  url={
                                    cuttingToolDetails.cuttingToolFRFxxUpload
                                  }
                                />
                              </li>
                              <li className="view-list">
                                <strong> cuttingToolFRFyyUpload: </strong>
                                <FileDownload
                                  url={
                                    cuttingToolDetails.cuttingToolFRFyyUpload
                                  }
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
                onClick={watchModalStatus}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        ;
      </div>
    </>
  );
};

export default ViewCuttingTool;
