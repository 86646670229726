import React, { useState } from "react";
import useAPI from "effects/useAPI";
import { useHistory } from "react-router";
import sessionTypes from "constants/sessionTypes";
import getUserSessions from "services/sessions/getUserSessions";
import getSessionById from "services/getSessionById";
import notify from "utils/toastMsg";
// import FileDownload from "components/FileDownload";
import ViewSession from "components/Modal/ViewSession";
import memoize from "lodash.memoize";

const getSessionByIdMemo = memoize(getSessionById);

const Dashboard = () => {
  const [sessionsToFetch, setSessionsToFetch] = useState([]);

  let history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [userSessionsLoading, userSessionsError, allUserSessions] = useAPI(() =>
    getUserSessions()
  );

  let userSessions;
  if (userSessionsLoading) {
    userSessions = [];
  } else {
    userSessions = allUserSessions.userSessions;
  }

  const [sessionLoading, sessionError, sessions] = useAPI(
    () => {
      return Promise.all(
        sessionsToFetch.map((sessionId) => getSessionByIdMemo(sessionId))
      );
    },
    [sessionsToFetch],
    null
  );
  if (sessionError) {
    notify("Something went wrong. Please try again");
    return <h1>Error</h1>;
  }

  let sessionDetails;
  if (sessionLoading) {
    sessionDetails = [];
  } else {
    sessionDetails = sessions;
  }

  const handleSessionTypeChange = (selectedType) => {
    const previousSessionType = JSON.parse(
      localStorage.getItem("sessionForm")
    )?.sessionType;
    if (previousSessionType === selectedType) {
      return;
    }
    const localStorageKeysToRemove = [
      "cuttingToolIds",
      "sessionForm",
      "toolData",
    ];

    localStorageKeysToRemove.forEach((element) =>
      localStorage.removeItem(element)
    );

    let allLocalStorageKeys = [];
    for (let key in localStorage) {
      allLocalStorageKeys.push(key);
    }
    const keysToDelete = allLocalStorageKeys.filter((key) =>
      key.includes("originalNameOf")
    );
    keysToDelete.forEach((key) => {
      localStorage.removeItem(key);
    });
  };

  return (
    <div style={{ marginTop: 100 }}>
      <div
        className="row cardss "
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <div className="col-xl-7">
          <div className="card">
            <div className="card-body">
              <h4 className="mt-0 header-title mb-4">
                Start A New Upload Session
              </h4>
              <div className="row cardss">
                <p className=" m-b-30 text-center">
                  Please start a session by selecting a solution type below.{" "}
                  {/*add "text-muted" to the class */}
                </p>
              </div>
              <div className="container">
                <div className="row cardss-flex">
                  <div className="col-md-4">
                    <p>1. Upload a CAM File</p>
                    <button
                      className="dashboard-button"
                      type="submit"
                      onClick={() => {
                        handleSessionTypeChange(sessionTypes.CAM);

                        history.push({
                          pathname: "/session-input",
                          state: sessionTypes.CAM,
                        });
                      }}
                    >
                      CAM
                    </button>
                  </div>
                  <div className="col-md-4">
                    <p>2. Upload Tool Path Files</p>
                    <button
                      className="dashboard-button"
                      type="submit"
                      onClick={() => {
                        handleSessionTypeChange(sessionTypes.TOOLPATH);
                        history.push({
                          pathname: "/session-input",
                          state: sessionTypes.TOOLPATH,
                        });
                      }}
                    >
                      TOOL PATH
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row cardss"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-xl-7">
          <div className="card" style={{ marginBottom: "100px" }}>
            <div className="card-body">
              <h4 className="mt-0 header-title mb-4">Latest Uploads</h4>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Session Reference</th>
                      <th scope="col">Upload Type</th>
                      <th scope="col">Machine</th>
                      <th scope="col">Material</th>
                      <th scope="col">Request Date </th>
                      <th scope="col">Status</th>
                      <th scope="col">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userSessions.map((session, idx) => {
                      return (
                        <tr key={idx}>
                          <th scope="row">{session.sessionReferenceNo}</th>
                          <td>{session.sessionType}</td>
                          <td>
                            {`${session.machineBrand}  ${session.machineModel}`}
                          </td>
                          <td>{session.materialName}</td>
                          <td>{session.createdAt}</td>
                          <td>
                            <div className="progress" style={{ height: 5 }}>
                              <div
                                className={
                                  session.progressStatusId === 2
                                    ? "progress-bar  bg-warning"
                                    : session.progressStatusId === 3
                                    ? "progress-bar  bg-success"
                                    : "progress-bar  bg-danger"
                                }
                                role="progressbar"
                                style={{
                                  width: `${session.progressStatusId * 33.3}%`,
                                }}
                                aria-valuenow={session.progressStatusId * 33.3}
                                aria-valuemin="0"
                                aria-valuemax="99.9"
                              ></div>
                            </div>
                          </td>

                          <td>
                            <ViewSession
                              sessionId={session.userSessionId}
                              setIsModalOpen={(isOpen) => {
                                if (isOpen) {
                                  setSessionsToFetch([
                                    ...sessionsToFetch,
                                    session.userSessionId,
                                  ]);
                                }
                              }}
                              sessionLoading={sessionLoading}
                              sessionDetails={
                                sessionDetails.find(
                                  (detail) =>
                                    detail.data.sessionDetails.userSessionId ===
                                    session.userSessionId
                                )?.data?.sessionDetails ?? {}
                              }
                            />{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default Dashboard;
