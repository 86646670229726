import http from "utils/http";
import { files } from "constants/endpoints";

const downloadFile = ({ ownerId, fileName }) => {
  const endpoint = files.downloadFile({ ownerId, fileName });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${endpoint}`, { responseType: "blob" });
};

export default downloadFile;
