import React from "react";
import { Input, ErrorMessage, Radio, Select } from "components/Forms";
import debounce from "utils/debounce";
import WatchForm from "components/Forms/WatchForm";
import { useFormContext } from "react-hook-form";

const ManualEntryFields = () => {
  const all = useFormContext();

  return (
    <>
      <div className="form-group">
        <label>Cutting Tool Types</label>
        <Select
          className="form-control"
          name="toolParameters.cuttingToolType"
          options={[
            { key: 1, value: 1, label: "Solid" },
            { key: 2, value: 2, label: "Indexible" },
          ]}
        />
        <ErrorMessage name="toolType" />
      </div>

      <div className="form-group">
        <label>Cutting Tool</label>
        <Select
          className="form-control"
          name="toolParameters.cuttingTool"
          options={[
            { key: 1, value: 1, label: "Flat End Mill" },
            { key: 2, value: 2, label: "Ball End Mill" },
            { key: 3, value: 3, label: "Bull Nose Mill" },
            { key: 4, value: 4, label: "Taper End Mill" },
            { key: 5, value: 5, label: "Taper Ball End Mill" },
            { key: 6, value: 6, label: "Cone End Mill" },
            { key: 7, value: 7, label: "Rounded End Mill" },
            { key: 8, value: 8, label: "General End Mill" },
            { key: 9, value: 9, label: "Inverted Cone End Mill" },
          ]}
        />
        <ErrorMessage name="toolParameters.cuttingToolType" />
      </div>

      <div className="form-group row">
        <div className="col-sm-6">
          <div>
            <label>Diameter</label>
            <Input
              name="toolParameters.diameter"
              className="form-control form-control-sm"
              type="number"
              placeholder="Diameter (mm)"
            />
          </div>

          <ErrorMessage name="toolParameters.diameter" />
        </div>
        <div className="col-sm-6">
          <label>Taper Angle</label>
          <Input
            name="toolParameters.taperAngle"
            className="form-control form-control-sm"
            type="number"
            placeholder="Taper Angle (deg)"
          />
          <ErrorMessage name="toolParameter.taperAngle" />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-6">
          <label>Corner Radius</label>
          <Input
            name="toolParameters.cornerRadius"
            className="form-control form-control-sm"
            type="number"
            placeholder="Corner Radius (mm)"
          />
          <ErrorMessage name="toolParameters.cornerRadius" />
        </div>

        <div className="col-sm-6">
          <label>Tip Angle</label>
          <Input
            name="toolParameters.tipAngle"
            className="form-control form-control-sm"
            type="number"
            placeholder="Tip Angle (deg)"
          />
          <ErrorMessage name="toolParameters.tipAngle" />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          <label>Lead Distance</label>
          <Input
            name="toolParameters.leadDistance"
            className="form-control form-control-sm"
            type="number"
            placeholder="Lead Distance (mm)"
          />
          <ErrorMessage name="toolParameters.leadDistance" />
        </div>

        <div className="col-sm-6">
          <label>Tool Length</label>
          <Input
            name="toolParameters.toolLength"
            className="form-control form-control-sm"
            type="number"
            placeholder="Tool Length (mm)"
          />
          <ErrorMessage name="toolParameters.toolLength" />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-6">
          <label>Stick out Length</label>
          <Input
            name="toolParameters.stickOutLength"
            className="form-control form-control-sm"
            type="float"
            placeholder="Stick out Length (mm)"
          />
          <ErrorMessage name="toolParameters.sticKOutLength" />
        </div>

        <div className="col-sm-6">
          <label>Flute Length</label>
          <Input
            name="toolParameters.fluteLength"
            className="form-control form-control-sm"
            type="number"
            placeholder="Flute Length (mm)"
          />
          <ErrorMessage name="toolParameters.fluteLength" />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-6">
          <label>Number of Flutes</label>
          <Input
            name="toolParameters.noOfFlutes"
            maxLength="2"
            className="form-control form-control-sm"
            type="number"
            placeholder="Number of Flutes"
            onKeyPress={debounce(() => {
              const values = all.getValues();
              const { toolParameters } = values || null;

              const newRakeAngle = [...toolParameters.rakeAngle].slice(
                0,
                toolParameters.noOfFlutes
              );
              all.setValue(`toolParameters.rakeAngle`, newRakeAngle);

              const newPitchAngle = [
                ...(toolParameters.pitchAngle || []),
              ].slice(0, toolParameters.noOfFlutes);
              all.setValue(`toolParameters.pitchAngle`, newPitchAngle);

              const newHelixAngle = [...toolParameters.helixAngle].slice(
                0,
                toolParameters.noOfFlutes
              );
              all.setValue(`toolParameters.helixAngle`, newHelixAngle);
            }, 1100)}
          />
          <ErrorMessage name="toolParameters.noOfFlutes" />
        </div>
      </div>

      <label>Helix Angle </label>
      <div className="grid">
        <div className="form-row">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <Radio
              name="toolParameters.helixOption"
              options={[
                { value: "1", label: "Constant" },
                {
                  value: "2",
                  label: "Variable",
                },
              ]}
              onChange={() => {
                const values = all.getValues();
                const helixAngles = values?.toolParameters?.helixAngle ?? [];
                helixAngles.forEach((angle, idx) => {
                  all.setValue(`toolParameters.helixAngle[${idx}]`, "");
                });
              }}
            />
            <ErrorMessage name="toolParameters.helixAngle" />
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-2 row ml-1 mt-2  ">
            <Input
              key={`toolParameters.helixAngle[0]`}
              name={`toolParameters.helixAngle[0]`}
              className="form-control form-control-sm mb-1 mr-1"
              type="number"
              placeholder={`Flute`}
            />
          </div>
          <WatchForm name="toolParameters.noOfFlutes">
            {(noOfFlutes, { formValues: { toolParameters } }) => {
              if (toolParameters.helixOption > 1) {
                let out = [];

                for (let i = 1; i < noOfFlutes; i++) {
                  if (noOfFlutes > 30) {
                    noOfFlutes = 30;
                  }
                  out.push(
                    <div
                      className="col-sm-2 row ml-1 mt-2"
                      key={`flute-helix-${i}`}
                    >
                      <Input
                        key={`toolParameters.helixAngle[${i}]`}
                        name={`toolParameters.helixAngle[${i}]`}
                        className="form-control form-control-sm mb-1 mr-1"
                        type="number"
                        placeholder={`Flute #${i + 1}`}
                      />
                      <ErrorMessage name="toolParameters.helixAngle" />
                    </div>
                  );
                }
                return out;
              }
            }}
          </WatchForm>
        </div>
      </div>

      <label className="mt-3">Pitch Angle </label>
      <div className="grid">
        <div className="form-row">
          <div className=" btn-group-toggle" data-toggle="buttons">
            <Radio
              name="toolParameters.pitchOption"
              options={[
                { value: "1", label: "Constant" },
                {
                  value: "2",
                  label: "Variable",
                },
              ]}
              onChange={() => {
                const values = all.getValues();
                const pitchAngles = values?.toolParameters?.pitchAngle ?? [];
                pitchAngles.forEach((angle, idx) => {
                  all.setValue(`toolParameters.pitchAngle[${idx}]`, "");
                });
              }}
            />
            <ErrorMessage name="toolParameters.pitchAngle" />
          </div>
        </div>
        <div className="form-row">
          <WatchForm name="toolParameters.noOfFlutes">
            {(noOfFlutes, { formValues: { toolParameters } }) => {
              let out = [];
              if (Number(toolParameters.pitchOption) > 1) {
                for (let i = 0; i < noOfFlutes; i++) {
                  if (noOfFlutes > 30) {
                    noOfFlutes = 30;
                  }
                  out.push(
                    <div
                      className="col-sm-2 row ml-1 mt-2"
                      key={`flute-pitch-angle-${i}`}
                    >
                      <Input
                        key={`toolParameters.pitchAngle[${i}]`}
                        name={`toolParameters.pitchAngle[${i}]`}
                        className="form-control form-control-sm mb-1 mr-1"
                        type="number"
                        placeholder={`Flute #${i + 1}`}
                      />
                      <ErrorMessage name="toolParameters.pitchAngle" />
                    </div>
                  );
                }
              }
              return out;
            }}
          </WatchForm>
        </div>
      </div>

      <label className="mt-3 mb-n3">Rake Angle </label>
      <div className="grid">
        <div className="form-row">
          <div className="col-sm-2 row ml-1 mt-2  ">
            <Input
              key={`toolParameters.rakeAngle`}
              name={`toolParameters.rakeAngle`}
              className="form-control form-control-sm mb-1 mr-1"
              type="number"
              placeholder={`Flute`}
            />
          </div>
          <WatchForm name="toolParameters.noOfFlutes">
            {(noOfFlutes, { formValues: { toolParameters } }) => {
              if (toolParameters.rakeOption > 1) {
                let out = [];

                for (let i = 1; i < noOfFlutes; i++) {
                  if (noOfFlutes > 30) {
                    noOfFlutes = 30;
                  }
                  out.push(
                    <div
                      className="col-sm-2 row ml-1 mt-2"
                      key={`flute-rake-angle${i}`}
                    >
                      <Input
                        key={`toolParameters.rakeAngle[${i}]`}
                        name={`toolParameters.rakeAngle[${i}]`}
                        className="form-control form-control-sm mb-1 mr-1"
                        type="number"
                        placeholder={`Flute #${i + 1}`}
                      />
                      <ErrorMessage name="toolParameters.rakeAngle" />
                    </div>
                  );
                }
                return out;
              }
            }}
          </WatchForm>
        </div>
      </div>
    </>
  );
};

export default ManualEntryFields;
