import http from "utils/http";
import { digiStability } from "constants/endpoints";

const postDigiStabilityToolData = ({
  digiStabilityToolTypeId,
  diameter,
  numberOfFlutes,
  fluteParameters,
  cuttingParameters,
}) => {
  const POST_USER_ENDPOINT = digiStability.postDigiStabilityToolData();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_USER_ENDPOINT}`, {
    data: {
      digiStabilityToolTypeId,
      diameter,
      numberOfFlutes,
      fluteParameters,
      cuttingParameters,
    },
  });
};

export default postDigiStabilityToolData;
