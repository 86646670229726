import React, { createContext, useContext, useEffect, useState } from "react";
import getSubscriptionByUserId from "services/subscriptions/getSubscriptionByUserId";
import { useLocation } from "react-router-dom";

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const location = useLocation();
  const [subscription, setSubscription] = useState({
    subscriptionStatus: "You currently do not have any active subscription",
    subscriptionProduct: null,
  });
  const [isLoading, setIsLoading] = useState();

  const fetchUserSubscription = async () => {
    try {
      setIsLoading(true);
      const subscriptionData = await getSubscriptionByUserId();
      if (subscriptionData?.data.subscription.length !== 0) {
        setSubscription(subscriptionData?.data.subscription[0]);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const pollingInterval = 2 * 60 * 60 * 1000;

  useEffect(() => {
    if (location.pathname !== "/login" && location.pathname !== "/register") {
      fetchUserSubscription();
    }
    const intervalId = setInterval(fetchUserSubscription, pollingInterval);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <SubscriptionContext.Provider value={subscription}>
      {isLoading ? (
        <div className="spinner-centered ">
          <i className="fa fa-spinner fa-spin fa-2x fa-fw"></i>
        </div>
      ) : (
        children
      )}
    </SubscriptionContext.Provider>
  );
};
