import { React, useState, useCallback } from "react";
import EditMachine from "../EditMachine";
import { Link } from "react-router-dom";
import "./style.css";

const Button = ({ className, onClick, children }) => {
  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
};

function MachineToolCard({
  machine,
  setSelectedMenuValue,
  setShowComponent,
  options,
  setActiveMachineId,
  fetchAllMachines,
}) {
  const { machineName, machineId } = machine;
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [showEditMachineModal, setShowEditMachineModal] = useState(false);

  const handleMenuClick = useCallback(() => {
    setExpandedMenu(expandedMenu === "menu" ? null : "menu");
  }, [expandedMenu]);

  const handleConfigClick = useCallback(() => {
    setExpandedMenu(expandedMenu === "config" ? null : "config");
  }, [expandedMenu]);

  return (
    <div className="machine-card-machine-tool-card">
      <h3 className="mb-4 machine-card-name-machine-tool-card">
        {machineName}
      </h3>
      <div className="d-flex justify-content-around machine-card-button-machine-tool-card">
        <Button
          className="btn btn-primary toggle-button-machine-tool-card dropdown-toggle"
          onClick={handleMenuClick}
        >
          Menu
        </Button>
        {expandedMenu === "menu" && (
          <div className="expanded-menu-button-container-machine-tool-card">
            <Button
              className="btn btn-secondary waves-effect"
              // onClick={() => {
              //   setSelectedMenuValue("ToolingDatabase");
              //   setShowComponent(true);
              //   setMenuExpanded(false);
              // }}
            >
              {options.menu.ToolingDatabase}
            </Button>
            {/* <Link
              to={`/frf-database/${machineId}`}
              className="btn btn-secondary waves-effect"
            >
              FRF database
            </Link> */}
            <Button className="btn btn-secondary waves-effect">
              FRF Database
            </Button>
            <Button
              className="btn btn-secondary waves-effect"
              // onClick={() => {
              //   setSelectedMenuValue("ToolpathOptimisation");
              //   setShowComponent(true);
              //   setMenuExpanded(false);
              // }}
            >
              {options.menu.ToolpathOptimisation}
            </Button>
            <Link
              className="btn btn-primary expanded-menu-button-bottom-edge-machine-tool-card"
              to={`/spindle-health-monitoring/${machineId}`}
            >
              {options.menu.SpindleHealthMonitoring}
            </Link>
          </div>
        )}
        <Button
          className="btn btn-primary toggle-button-machine-tool-card dropdown-toggle"
          onClick={handleConfigClick}
        >
          Configure
        </Button>
        {expandedMenu === "config" && (
          <div className="expanded-menu-button-container-right-machine-tool-card">
            <Button
              className="btn btn-secondary waves-effect"
              // onClick={() => {
              //   setSelectedMenuValue("MachineTool");
              //   setShowComponent(true);
              //   setConfigurationExpanded(false);
              // }}
            >
              {options.configure.MachineTool}
            </Button>
            <Link
              to={`/machine-configurations/${machineId}`}
              className="btn btn-primary expanded-menu-button-middle-machine-tool-card"
            >
              Machine Configurations
            </Link>
            <Button
              className="btn btn-secondary waves-effect"
              // onClick={() => {
              //   setSelectedMenuValue("Spindle");
              //   setShowComponent(true);
              //   setConfigurationExpanded(false);
              // }}
            >
              {options.configure.Spindle}
            </Button>
            <Button
              className="btn btn-secondary waves-effect"
              // onClick={() => {
              //   setSelectedMenuValue("Controller Settings");
              //   setShowComponent(true);
              //   setConfigurationExpanded(false);
              // }}
            >
              {options.configure.Controller}
            </Button>
            <Button
              className="btn btn-primary expanded-menu-button-bottom-edge-machine-tool-card"
              onClick={() => {
                setShowEditMachineModal(true);
              }}
            >
              {options.configure.Edit}
            </Button>
            <Button
              className="btn btn-primary expanded-menu-button-bottom-edge-machine-tool-card"
              onClick={() => {
                setSelectedMenuValue("Delete");
                setShowComponent(true);
                setActiveMachineId(machineId);
                handleConfigClick();
              }}
            >
              {options.configure.Delete}
            </Button>
          </div>
        )}
      </div>
      {showEditMachineModal && (
        <EditMachine
          machine={machine}
          fetchAllMachines={fetchAllMachines}
          setShowEditMachineModal={setShowEditMachineModal}
        />
      )}
    </div>
  );
}

export default MachineToolCard;
