import React from "react";
import notify from "utils/toastMsg";
import "./index.css";
import {
  Form,
  InputNonOptimized,
  Select,
  ErrorMessage,
  SubmitButton,
} from "components/Forms";
import postOrganizationInvitation from "services/Organizations/postOrganizationInvitation";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
    .label("Email")
    .typeError("Email must be a valid email address."),
  userRoleId: yup
    .number()
    .required()
    .label("Role")
    .typeError("Role must be a valid role."),
  message: yup
    .string()
    .required()
    .label("Message")
    .typeError("Message must be a valid message."),
});

const SendInvitationModal = ({ setOpenInvitationModal }) => {
  const handleSendInvitation = async (values) => {
    try {
      await postOrganizationInvitation(values);
      notify("Success! You successfully send invitation ✅");
      setOpenInvitationModal(false);
    } catch (error) {
      error.response.data.map((errorObj) => {
        notify(`${errorObj.message} ❌`);
        // eslint-disable-next-line no-console
        console.error({ errorMessage: errorObj.message });
        return null;
      });
    }
  };

  return (
    <div className="notify-content mt-1">
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-baseline">
          <h4 className="notify-header">Send Invitation</h4>
          <button
            className="close"
            type="button"
            onClick={() => setOpenInvitationModal(false)}
          >
            &times;
          </button>
        </div>
        <div>
          <Form
            initialValues={{
              email: "",
              userRoleId: 2,
              message: "",
            }}
            onSubmit={handleSendInvitation}
            validationSchema={schema}
          >
            <div>
              <label className="col-3 input-label mt-3">Email</label>
              <InputNonOptimized
                name="email"
                className="form-control"
                placeholder="Email"
              />
              <ErrorMessage name="email" />
            </div>
            <div>
              <label className="col-3 input-label mt-3">Role</label>
              <Select
                className="form-control"
                name="userRoleId"
                options={[
                  {
                    key: 1,
                    value: 1,
                    label: "Admin",
                  },
                  {
                    key: 2,
                    value: 2,
                    label: "User",
                  },
                  {
                    key: 3,
                    value: 3,
                    label: "Guest",
                  },
                ]}
              />
              <ErrorMessage name="userRoleId" />
            </div>
            <div>
              <label className="col-3 input-label mt-3">Message</label>
              <InputNonOptimized
                name="message"
                className="form-control"
                placeholder="Message"
              />
              <ErrorMessage name="message" />
            </div>
            <SubmitButton type="submit" className="btn notify-btn mt-4">
              Send Invitation
            </SubmitButton>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SendInvitationModal;
