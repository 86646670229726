import http from "utils/http";
import { sessions } from "constants/endpoints";

const getPrtUploadsByUploadIds = ({ uploadIds }) => {
  const ENDPOINT = sessions.getPrtUploadsByUploadIds({
    uploadIds,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`);
};

export default getPrtUploadsByUploadIds;
