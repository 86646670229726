import React, { useState } from "react";
import { Form, Input } from "../../components/Forms";
import FileDownload from "components/FileDownload";
import sessionTypes from "constants/sessionTypes";

const ViewSession = ({ sessionDetails, setIsModalOpen, sessionId }) => {
  const [isViewSessionModalOpen, setIsViewSessionModalOpen] = useState(false);
  const hasSessionLoaded = Object.keys(sessionDetails).length > 0;

  const watchModalStatus = () => {
    setIsViewSessionModalOpen(!isViewSessionModalOpen);
    setIsModalOpen(!isViewSessionModalOpen);
  };

  const { sessionType } = sessionDetails;
  let partFileUploadTitle = "Tool Path Upload ";
  if (sessionType === sessionTypes.CAD) {
    partFileUploadTitle = "3D Model (CAD) File Upload";
  }
  if (sessionType === sessionTypes.CAM) {
    partFileUploadTitle = "CAM File Upload";
  }
  if (sessionType === sessionTypes.GCODE) {
    partFileUploadTitle = "NC File (Gcode) Upload";
  }

  return (
    <>
      <i
        className="ion ion-md-eye view-icon"
        data-toggle="modal"
        data-target={`#view-session-modal-${sessionId}`}
        style={{ cursor: "pointer" }}
        onClick={watchModalStatus}
      ></i>
      <div
        id={`view-session-modal-${sessionId}`}
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={watchModalStatus}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {hasSessionLoaded && (
                <Form
                  initialValues={{
                    machineToolBrand: sessionDetails.machineBrand,
                    machineToolModel: sessionDetails.machineModel,
                    spindleModelName: sessionDetails.spindleName,
                    maxSpindleSpeed: sessionDetails.spindleSpeed,
                    maxTorque: sessionDetails.maxTorque,
                    maxPower: sessionDetails.maxPower,
                    dimensionalTolerance: sessionDetails.dimensionalTolerance,
                    Ra: sessionDetails.surfaceRoughness,
                    controllerBrand: sessionDetails.controllerBrand,
                    controllerModel: sessionDetails.controllerModel,
                    materialName: sessionDetails.materialName,
                    fixturingMethods: sessionDetails.fixturingInfo,
                    notes: sessionDetails.notes,
                  }}
                  onSubmit={() => null}
                >
                  <div
                    className="row cardss-view"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "black",
                    }}
                  >
                    <div className="col-lg-12">
                      <div className="card m-b-0">
                        <div className="card-body">
                          <p
                            className="m-b-0"
                            style={{ marginBottom: "-0.5rem" }}
                          >
                            <strong>Session Ref: </strong>
                            <span>{sessionDetails.sessionReferenceNo}</span>
                          </p>

                          {/* <p>
                            Request Type : <span>{sessionDetails.SessionTypeId}</span>
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row cardss-view"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="col-lg-12">
                      <div className="card m-b-30">
                        <div className="card-body">
                          <h4 className="mt-0 header-title text-center">
                            CNC Machine Tool
                          </h4>

                          <div className="row">
                            <div className="form-group col-md-4">
                              <label>Machine Tool Brand</label>
                              <Input
                                name="machineToolBrand"
                                type="text"
                                className="form-control"
                                placeholder="Machine Tool Brand"
                                disabled={true}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label>Machine Tool Model</label>
                              <Input
                                name="machineToolModel"
                                type="text"
                                className="form-control"
                                placeholder="Machine Tool Model"
                                disabled={true}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label>Spindle Model Name</label>
                              <Input
                                name="spindleModelName"
                                type="text"
                                className="form-control"
                                placeholder="Spindle Model Name"
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-4">
                              <label>Max Spindle Speed (RPM)</label>
                              <Input
                                name="maxSpindleSpeed"
                                type="number"
                                className="form-control"
                                placeholder=" Max Spindle Speed"
                                disabled={true}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label>Max Torque (Nm)</label>
                              <Input
                                name="maxTorque"
                                type="number"
                                className="form-control"
                                placeholder=" Max Torque"
                                disabled={true}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label>Max Power (kW)</label>
                              <Input
                                name="maxPower"
                                type="number"
                                className="form-control"
                                placeholder=" Max Power"
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row cardss-view"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="col-lg-12">
                      <div className="card m-b-30">
                        <div className="card-body">
                          <h4 className="mt-0 header-title text-center">
                            {partFileUploadTitle}
                          </h4>

                          <div className="form-group">
                            <label>Part File</label>:&nbsp;
                         
                        

                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">S3 Upload Ref</th>
                          <th scope="col">Upload</th>
                          <th scope="col">Optimization Constraint</th> 
                        </tr>
                      </thead>
                      <tbody>
                        {sessionDetails.prtUploads.map((upload) => {
                          return (
                            <tr>
                              <td>{upload.prtUpload}</td>
                              <td><FileDownload url={upload.prtUpload}/></td>
                              <td>{upload.optimisationConstraint}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row cardss-view"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="col-lg-12">
                      <div className="card m-b-30">
                        <div className="card-body">
                          <h4 className="mt-0 header-title text-center">
                            Tolerances
                          </h4>

                          <div className="row">
                            <div className="form-group col-md-6">
                              <label>
                                Dimensional Tolerance (<span>&#181;</span>m)
                              </label>
                              <Input
                                name="dimensionalTolerance"
                                type="number"
                                className="form-control"
                                placeholder="Dimensional Tolerance"
                                disabled={true}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                Surface Roughness (Ra <span>&#181;</span>m)
                              </label>
                              <Input
                                name="Ra"
                                type="number"
                                className="form-control"
                                placeholder="Surface Roughness"
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row cardss-view"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="col-lg-12">
                      <div className="card m-b-30">
                        <div className="card-body">
                          <h4 className="mt-0 header-title text-center">
                            Controller
                          </h4>

                          <div className="row">
                            <div className="form-group col-md-6">
                              <label>Controller Brand</label>
                              <Input
                                name="controllerBrand"
                                type="text"
                                className="form-control"
                                placeholder="e.g: Siemens"
                                disabled={true}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>Controller Model</label>
                              <Input
                                name="controllerModel"
                                type="text"
                                className="form-control"
                                placeholder="e.g: Sinumerik"
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row cardss-view"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="col-lg-12">
                      <div className="card m-b-30">
                        <div className="card-body">
                          <h4 className="mt-0 header-title text-center">
                            Workpiece
                          </h4>

                          <div className="form-group">
                            <label>Material Name</label>
                            <Input
                              name="materialName"
                              type="text"
                              className="form-control"
                              placeholder="Full Material Name"
                              disabled={true}
                            />
                          </div>
                          {Boolean(sessionDetails.stdUpload) && (
                            <div className="form-group">
                              <label>Stock Part Geometry Upload</label>:&nbsp;
                              <FileDownload url={sessionDetails.stdUpload} />
                            </div>
                          )}
                          {Boolean(sessionDetails.materialDimensions.shape) && (
                            <div className="grid">
                              <div className="form-row">
                            { sessionDetails.materialDimensions.shape && 

                              <div className="col-sm-3">
                                <label>Shape</label>
                                  <Input
                                    name="materialDimensions.shape"
                                    value={
                                      sessionDetails.materialDimensions.shape
                                    }
                                    type="text"
                                    className="form-control"
                                    placeholder="Shape"
                                    disabled={true}
                                  />
                                </div>}
                            { sessionDetails.materialDimensions.height &&
                               <div className="col-sm-3">
                                <label>Height</label>
                                  <Input
                                    name="materialDimensions.height"
                                    value={
                                      sessionDetails.materialDimensions.height
                                    }
                                    type="text"
                                    className="form-control"
                                    placeholder="n/a"
                                    disabled={true}
                                  />
                                </div>}
                              { sessionDetails.materialDimensions.width &&
                                <div className="col-sm-3">
                                  <label>Width</label>
                                  <Input
                                    name="materialDimensions.width"
                                    value={
                                      sessionDetails.materialDimensions.width
                                    }
                                    type="text"
                                    className="form-control"
                                    placeholder="n/a"
                                    disabled={true}
                                  />
                                </div>}
                              { sessionDetails.materialDimensions.length && 
                                <div className="col-sm-3">
                                  <label>Length</label>
                                  <Input
                                    name="materialDimensions.length"
                                    value={
                                      sessionDetails.materialDimensions.length
                                    }
                                    type="text"
                                    className="form-control"
                                    placeholder="n/a"
                                    disabled={true}
                                  />
                                </div>}
                                {sessionDetails.materialDimensions.radius &&   
                                  <div className="col-sm-3">
                                    <label>Radius</label>
                                    <Input
                                      name="materialDimensions.radius"
                                      value={
                                        sessionDetails.materialDimensions.radius
                                      }
                                      type="text"
                                      className="form-control"
                                      placeholder="n/a"
                                      disabled={true}
                                    />
                                </div> }
                            
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row cardss-view"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="col-lg-12">
                      <div className="card m-b-30">
                        <div className="card-body">
                          <h4 className="mt-0 header-title text-center">
                            Fixturing
                          </h4>

                          <div className="form-group">
                            <label>Fixturing Methods and Tools</label>
                            <Input
                              name="fixturingMethods"
                              type="text"
                              className="form-control"
                              placeholder="Fixturing Methods and Tools"
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row cardss-view"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="col-lg-12">
                      <div className="card m-b-30">
                        <div className="card-body">
                          <h4 className="mt-0 header-title text-center">
                            Tooling
                          </h4>
                          <p className="text-muted m-b-30 text-center">
                            Please see the cutting tool and tool holder
                            information below.
                          </p>
                          {sessionDetails.cuttingTools.map(
                            (cuttingTool, idx) => {
                              return (
                                <div className="tabs">
                                  <div className="tab">
                                    <input
                                      type="checkbox"
                                      id={`cutting-tool-details=${idx}`}
                                    />
                                    <label
                                      className="tab-label"
                                      id={`cutting-tool-details=${idx}`}
                                      for={`cutting-tool-details=${idx}`}
                                    >
                                      {cuttingTool.cuttingToolName}
                                    </label>

                                    <div className="tab-content">
                                      <ul>
                                        <li className="view-list">
                                          <strong>cuttingToolId: </strong>
                                          {cuttingTool.cuttingToolId}
                                        </li>
                                        <li className="view-list">
                                          <strong>operationName: </strong>
                                          {cuttingTool.operationName}
                                        </li>
                                        <li className="view-list">
                                          <strong>toolHolderName: </strong>
                                          {cuttingTool.toolHolderName}
                                        </li>
                                        {cuttingTool.toolHolderUrl && (
                                          <li className="view-list">
                                            <strong>toolHolderUrl: </strong>
                                            {cuttingTool.toolHolderUrl}
                                          </li>
                                        )}
                                        {cuttingTool.toolHolderIsoNo && (
                                          <li className="view-list">
                                            <strong>toolHolderIsoNo: </strong>
                                            {cuttingTool.toolHolderIsoNo}
                                          </li>
                                        )}
                                        <li className="view-list">
                                          <strong>cuttingToolName: </strong>
                                          {cuttingTool.cuttingToolName}
                                        </li>
                                        <li className="view-list">
                                          <strong>cuttingToolNumber: </strong>
                                          {cuttingTool.cuttingToolNumber}
                                        </li>
                                        {cuttingTool.toolUrl && (
                                          <li className="view-list">
                                            <strong> toolUrl:</strong>{" "}
                                            {cuttingTool.toolUrl}
                                          </li>
                                        )}
                                        {cuttingTool.toolIsoNo && (
                                          <li className="view-list">
                                            <strong> toolIsoNo: </strong>
                                            {cuttingTool.toolIsoNo}
                                          </li>
                                        )}
                                        {cuttingTool.toolDatasheet && (
                                          <li className="view-list">
                                            <strong>toolDatasheet: </strong>
                                            <FileDownload
                                              url={cuttingTool.toolDatasheet}
                                            />
                                          </li>
                                        )}
                                        {Object.keys(
                                          cuttingTool.toolParameters || {}
                                        ).length > 3 && (
                                          <li className="view-list">
                                            <strong>toolParameters: </strong>
                                            {JSON.stringify(
                                              cuttingTool.toolParameters
                                            )}
                                          </li>
                                        )}
                                        <li className="view-list">
                                          <strong>
                                            cuttingToolFRFxxUpload:{" "}
                                          </strong>
                                          <FileDownload
                                            url={
                                              cuttingTool.cuttingToolFrFxxUpload
                                            }
                                          />
                                        </li>
                                        <li className="view-list">
                                          <strong>
                                            {" "}
                                            cuttingToolFRFyyUpload:{" "}
                                          </strong>
                                          <FileDownload
                                            url={
                                              cuttingTool.cuttingToolFrFyyUpload
                                            }
                                          />
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row cardss"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="col-lg-6">
                      <div className="card m-b-30">
                        <div className="card-body">
                          <div className="form-group">
                            <label>Notes</label>
                            <Input
                              name="notes"
                              type="text"
                              className="form-control"
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
                onClick={watchModalStatus}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        ;
      </div>
    </>
  );
};

export default ViewSession;
