const FEED_RATE_MARKS = {
  0: "0",
  40000: "40000",
};

const FEED_PER_TOOTH_MARKS = {
  0: "0",
  1: "1",
};

const SPINDLE_SPEED_MARKS = {
  0: "0",
  40000: "40000",
};

const CUTTING_SPEED_MARKS = {
  0: "0",
  15000: "15000",
};

const MILLING_TYPES = [
  { value: 1, label: "Up Milling" },
  { value: 2, label: "Down Milling" },
  { value: 3, label: "Slotting" },
];

const MATERIAL_SELECTION = [
  { key: 1, value: 1, label: "AISI P20 Mold Steel" },
  { key: 2, value: 2, label: "AISI 4340 Steel" },
  { key: 3, value: 3, label: "AISI 1045 Steel" },
  { key: 4, value: 4, label: "Aluminium 6061-T6" },
  { key: 5, value: 5, label: "Aluminium 7050-T74" },
  { key: 6, value: 6, label: "Titanium Ti6Al4V" },
  { key: 7, value: 7, label: "Inconel 718" },
];

const SPEED_TYPES = [
  { value: 1, label: "Spindle Speed (RPM)" },
  { value: 2, label: "Cutting Speed (m/min)" },
];

const FEED_TYPES = [
  { value: 1, label: "Feed rate (mm/min)" },
  { value: 2, label: "Feed per Tooth (mm/tooth)" },
];

export {
  MATERIAL_SELECTION,
  FEED_RATE_MARKS,
  FEED_PER_TOOTH_MARKS,
  SPINDLE_SPEED_MARKS,
  CUTTING_SPEED_MARKS,
  MILLING_TYPES,
  SPEED_TYPES,
  FEED_TYPES,
};
