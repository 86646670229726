import React from "react";
import { Redirect, Route } from "react-router-dom";
import Authentication from "services/Authentication";

const auth = new Authentication();

const AuthRoute = ({ children, ...rest }) => {
  const isAuthenticated = auth.isAuthenticated();
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  return <Route {...rest}>{children}</Route>;
};

export default AuthRoute;
