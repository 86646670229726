import { useCallback } from "react";
import set from "utils/set";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors?.inner?.reduce((allErrors, currentError) => {
            const newErrorObj = {
              type: currentError.type ?? "validation",
              message: currentError.message,
            };

            const errors = set(allErrors, currentError.path, newErrorObj);

            return { ...errors };
          }, {}),
        };
      }
    },
    [validationSchema]
  );

export default useYupValidationResolver;
