import http from "utils/http";
import { user } from "constants/endpoints";

const deleteNotifyMeById = ({ idToDelete }) => {
  const ENDPOINT = user.deleteNotifyMeById();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.delete(`${API_BASE_URL}${ENDPOINT}?idToDelete=${idToDelete}`);
};

export default deleteNotifyMeById;
