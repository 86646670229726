import * as yup from "yup";

const digiBasicSchema = yup.object().shape({
  digiStabilityToolTypeId: yup
    .number()
    .required()
    .label("Digi Stability Tool Type Id")
    .typeError("Tool type id should be a number"),
  diameter: yup
    .number()
    .min(1, "Tool Diameter cannot be less than 1mm")
    .max(100, "Tool Diameter cannot be higher than 100mm")
    .required()
    .label("Diameter")
    .typeError("Tool Diameter cannot be empty")
    .test(
      "is Diameter correct",
      "Diameter value should equal or bigger than width of cut and should positive",
      function test() {
        const { widthOfCut } = this.parent.cuttingParameters;
        const { diameter } = this.parent;
        return diameter >= widthOfCut && diameter > 0;
      }
    ),
  fluteParameters: yup.object().shape({
    noOfFlutes: yup
      .number()
      .integer("Should be positive digit number")
      .min(1)
      .max(10)
      .required()
      .label("Number Of Flutes")
      .typeError("Number of flutes cannot be empty"),
    helixAngle: yup
      .number()
      .integer("Should be positive digit number")
      .min(0)
      .max(60)
      .required()
      .label("Helix Angle")
      .typeError("Helix Angle cannot be empty"),
    rakeAngle: yup
      .number()
      .integer("Should be positive digit number")
      .min(-90)
      .max(90)
      .required()
      .label("Rake Angle")
      .typeError("Rake Angle cannot be empty"),
    frfDataXx: yup
      .string()
      .required("FRF in the feed direction is required")
      .label("FRF in the feed direction"),
    frfDataYy: yup
      .string()
      .required("FRF in the cross-feed direction is required")
      .label("FRF in the cross-feed direction"),
  }),
  cuttingParameters: yup.object().shape({
    millingTypeSelection: yup
      .number()
      .required()
      .label("Milling Type Selection")
      .typeError("Milling Type Selection cannot be empty"),
    cuttingSpeed: yup
      .number()
      .integer("Should be positive digit number")
      .min(10000)
      .max(40000)
      .required()
      .label("Spindle Speed")
      .typeError("Spindle speed cannot be empty"),
    widthOfCut: yup
      .number()
      .min(0.1, "Width Of Cut cannot be less than 0.1mm")
      .label("Width Of Cut")
      .typeError("Width Of Cut cannot be empty")
      .test(
        "is Width of Cut value valid",
        "Width of Cut value should less than Tool Diameter value and should positive",
        function test() {
          const { widthOfCut } = this.parent;
          const { diameter } = this.from[1].value;
          if (widthOfCut <= diameter && widthOfCut > 0) {
            return true;
          }
          return false;
        }
      ),
    materialSelection: yup
      .number()
      .required()
      .label("Material Selection")
      .typeError("Material Selection cannot be empty"),
  }),
});

export default digiBasicSchema;
