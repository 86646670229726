import React, { useRef, useEffect } from "react";
import { useState } from "react";
import useAPI from "effects/useAPI";
import getDigiStabilityToolDataByOrgId from "services/digiStability/getDigiStabilityToolDataByOrgId";
import getDigiStabilityFeedRate from "services/digiStability/getDigiStabilityFeedRate";
import {
  MATERIAL_SELECTION,
  MILLING_TYPE_SELECTION,
} from "./constants/constants";
import "./index.css";
// import CustomDot from "./components/CustomDot";
// import getClickedPoint from "./components/getClickedPoint";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  ResponsiveContainer,
} from "recharts";

function History() {
  const targetTableRef = useRef("");
  const pageSize = 5;

  const [startIndex, setStartIndex] = useState(0);
  const [currentList, setCurrentList] = useState(0);
  const [feedRateToolId, setFeedRateToolId] = useState();
  const [clickedRowIndex, setClickedRowIndex] = useState(null);
  const [selectedToolData, setSelectedToolData] = useState();
  const [chartDataToBeDisplayed, setChartDataToBeDisplayed] = useState();

  const [
    loadingDigiStabilityToolData,
    errorDigiStabilityToolData,
    digiStabilityToolDataResponse,
  ] = useAPI(
    () => getDigiStabilityToolDataByOrgId({ startIndex }),
    [startIndex]
  );

  const [
    loadingDigiStabilityFeedRate,
    errorDigiStabilityFeedRate,
    digiStabilityFeedRateResponse,
  ] = useAPI(
    () => getDigiStabilityFeedRate({ feedRateToolId }),
    [feedRateToolId]
  );

  const { digiStabilityToolData, totalOfDigiStabilityToolData } =
    digiStabilityToolDataResponse;

  useEffect(() => {
    setStartIndex(currentList * pageSize);
  }, [currentList]);

  const totalPages = Math.ceil(
    totalOfDigiStabilityToolData?.totalRecords / pageSize
  );

  const handleClickRow = (digistabilityToolDataHistory, index) => {
    setFeedRateToolId(digistabilityToolDataHistory.digistabilityToolId);
    setSelectedToolData(digistabilityToolDataHistory);
    setClickedRowIndex(index);
  };

  useEffect(() => {
    if (
      !loadingDigiStabilityFeedRate &&
      !errorDigiStabilityFeedRate &&
      digiStabilityFeedRateResponse &&
      digiStabilityFeedRateResponse?.digiStabilityFeedRate.length > 0
    ) {
      const transformDataForChart = () => {
        const transformedData = Object.entries(
          digiStabilityFeedRateResponse?.digiStabilityFeedRate[0].original
        ).map(([name, Stability_Boundary]) => ({
          name,
          Stability_Boundary,
        }));
        return transformedData;
      };

      setChartDataToBeDisplayed(transformDataForChart());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digiStabilityFeedRateResponse, clickedRowIndex]);

  const CustomTooltip = ({ payload }) => {
    if (payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid grey",
            backgroundColor: "white",
            width: "200px",
          }}
        >
          <p className="label">Stability Boundary</p>
          <p
            style={{ color: "#3182bd" }}
          >{`Spindle Speed (RPM): ${payload[0].payload.name}`}</p>
          <p
            style={{ color: "#3182bd" }}
          >{`Depth Of Cut (mm): ${payload[0].value.toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {loadingDigiStabilityToolData ? (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <h1>Loading Data...</h1>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : errorDigiStabilityToolData ? (
        <p>Something went wrong.</p>
      ) : (
        <div className="history-container">
          <h1 className="d-flex justify-content-center mb-4">History</h1>
          <div className="card-wrapper">
            <div className="card">
              <div className="d-flex justify-content-center p-2">
                <h4>Latest Submissions</h4>
              </div>
              <h5 className="ml-3">You are on page: {currentList + 1}</h5>
              <div className="card-body">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper container-fluid"
                >
                  <div className="row">
                    <table
                      id="datatable"
                      className="table table-bordered text-center"
                      role="grid"
                      aria-describedby="datatable_info"
                    >
                      <thead>
                        <tr>
                          {/* <td className="font-weight-bold">
                      Download Original Data
                    </td>
                    <td className="font-weight-bold">
                      Download Optimized Data
                    </td> */}
                          <td className="font-weight-bold">Reference No</td>
                          <td className="font-weight-bold">Submission Date</td>
                        </tr>
                      </thead>
                      <tbody>
                        {digiStabilityToolData.map(
                          (digistabilityToolDataHistory, index) => {
                            const isClicked = clickedRowIndex === index;
                            return (
                              <tr
                                key={index}
                                style={{
                                  cursor: "pointer",
                                  border: isClicked
                                    ? "2.5px solid rgb(43, 142, 211)"
                                    : "none",
                                }}
                                onClick={() => {
                                  handleClickRow(
                                    digistabilityToolDataHistory,
                                    index
                                  );
                                }}
                              >
                                {/* <td onClick={handleRefNoClick}>
                            <CSVLink
                            className="btn btn-info waves-effect waves-light"
                            data={csvArray}
                            filename={"original-data.csv"}
                            onClick={() => {
                              csvWrite(
                                digistabilityToolDataHistory,
                                "original"
                              );
                            }}
                          >
                            Download
                          </CSVLink>
                          </td>
                          <td onClick={handleRefNoClick}>
                            <CSVLink
                            className="btn btn-info waves-effect waves-light"
                            data={csvArray}
                            filename={"optimum-data.csv"}
                            onClick={() => {
                              csvWrite(
                                digistabilityToolDataHistory,
                                "optimized"
                              );
                            }}
                          >
                            Download
                          </CSVLink>
                          </td> */}
                                <td>
                                  {digistabilityToolDataHistory.toolInputRefNo}
                                </td>
                                <td>
                                  {digistabilityToolDataHistory.createdAt} (UTC)
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex align-items-center flex-column">
                    <p
                      className="dataTables_info ml-1"
                      id="datatable_info"
                      role="status"
                      aria-live="polite"
                    >
                      You Have {totalOfDigiStabilityToolData?.totalRecords}{" "}
                      Records In Total
                    </p>
                    {totalOfDigiStabilityToolData?.totalRecords !== 0 ? (
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="datatable_paginate"
                      >
                        <ul className="pagination">
                          <li
                            className={
                              currentList === 0
                                ? "paginate_button page-item previous disabled"
                                : null
                            }
                            id="datatable_previous"
                          >
                            <button
                              type="button"
                              aria-controls="datatable"
                              className="page-link"
                              disabled={currentList === 0 ? true : false}
                              onClick={() => setCurrentList(currentList - 1)}
                            >
                              Prev Page
                            </button>
                          </li>
                          <li
                            className={
                              currentList + 1 >= totalPages
                                ? "paginate_button page-item previous disabled"
                                : "none"
                            }
                            id="datatable_next"
                          >
                            <button
                              type="button"
                              aria-controls="datatable"
                              className="page-link"
                              disabled={
                                currentList + 1 >= totalPages ? true : false
                              }
                              onClick={() => setCurrentList(currentList + 1)}
                            >
                              Next Page
                            </button>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="details-wrapper ml-5">
            <div
              className="table-wrapper p-3 ml-3"
              style={{ background: "#fff" }}
            >
              <div className="d-flex justify-content-center p-3">
                <h3>Details</h3>
              </div>
              <div>
                <p className="text-bold">
                  Submission: {selectedToolData?.toolInputRefNo}
                </p>
              </div>
              <table
                id="datatable"
                className="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline text-center"
                role="grid"
                aria-describedby="datatable_info"
                ref={targetTableRef}
              >
                <tr>
                  <td colSpan={2}>
                    <h6>Input Parameters</h6>
                  </td>
                  <td role="row" className="odd" colSpan={4}>
                    <h6>Results</h6>
                  </td>
                </tr>
                <tr>
                  <td tabIndex="0" className="sorting_1 font-weight-bold">
                    Tool Type
                  </td>
                  <td>{selectedToolData?.digistabilityToolType}</td>
                  <td rowSpan={8}>
                    <div
                      className="highlight-bar-charts"
                      style={{
                        width: "50rem",
                      }}
                    >
                      <ResponsiveContainer width="100%" height={600}>
                        <LineChart
                          width={800}
                          height={400}
                          data={chartDataToBeDisplayed}
                          syncId="anyId"
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="name"
                            height={50}
                            label={{
                              value: "Spindle Speed (rpm)",
                              position: "right",
                              dx: -120,
                            }}
                          />
                          <YAxis
                            width={60}
                            label={{
                              value: "Depth Of Cut (mm)",
                              position: "insideTopLeft",
                              angle: -90,
                              dy: 100,
                            }}
                          />
                          <Tooltip content={<CustomTooltip />} />
                          <Line
                            type="monotone"
                            dataKey="Stability_Boundary"
                            dot={false}
                            activeDot={false}
                            animationDuration={300}
                          />
                          <Brush dataKey={"name"} width={695} />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Tool Diameter (mm)
                  </td>
                  <td>{selectedToolData?.diameter}</td>
                </tr>
                <tr>
                  <td tabIndex="0" className="sorting_1 font-weight-bold">
                    Number of Flutes
                  </td>
                  <td>{selectedToolData?.fluteParameters?.noOfFlutes}</td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Tool Helix Angle (deg)
                  </td>
                  <td>{selectedToolData?.fluteParameters?.helixAngle}</td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Milling Type
                  </td>
                  <td>
                    {selectedToolData !== undefined
                      ? MILLING_TYPE_SELECTION.find(
                          (item) =>
                            item.value ===
                            selectedToolData.cuttingParameters
                              .millingTypeSelection
                        )?.label || ""
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Width of Cut (mm)
                  </td>
                  <td>{selectedToolData?.cuttingParameters?.widthOfCut}</td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Cutting Speed (m/min)
                  </td>
                  <td>{selectedToolData?.cuttingParameters?.cuttingSpeed}</td>
                </tr>
                <tr>
                  <td className="sorting_1 font-weight-bold" tabIndex="0">
                    Material Selection
                  </td>
                  <td>
                    {selectedToolData !== undefined
                      ? MATERIAL_SELECTION.find(
                          (item) =>
                            item.value ===
                            selectedToolData.cuttingParameters.materialSelection
                        )?.label || ""
                      : ""}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default History;
