import http from "utils/http";
import { organizations } from "constants/endpoints";

const patchApproveJoinTeamRequestStatusByOrgId = ({ shortcode }) => {
  const ENDPOINT = organizations.patchApproveJoinTeamRequestStatusByOrgId({
    shortcode,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.patch(`${API_BASE_URL}${ENDPOINT}`, {});
};

export default patchApproveJoinTeamRequestStatusByOrgId;
