import React from "react";
import notify from "utils/toastMsg";
import "./index.css";
import putUserOrganization from "services/putUserOrganization";

const RemoveOrganizationUserModal = ({
  fetchAllOrganizationMembers,
  setRemoveOrganizationUserModal,
  organizationUserToRemove,
}) => {
  const handlePutUserOrganization = async ({ organizationUserToRemove }) => {
    try {
      await putUserOrganization({ userId: organizationUserToRemove });
      fetchAllOrganizationMembers();
      notify("User removed from organization ✅");
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notify("Error removing user from organization ❌");
    } finally {
      setRemoveOrganizationUserModal(false);
    }
  };

  return (
    <div className="notify-content mt-1">
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-baseline">
          <h4 className="notify-header">Remove user from organization?</h4>
          <button
            className="close"
            type="button"
            onClick={() => setRemoveOrganizationUserModal(false)}
          >
            &times;
          </button>
        </div>
        <div>
          <div className="d-flex justify-content-around">
            <button
              className="btn btn-danger"
              onClick={() =>
                handlePutUserOrganization({ organizationUserToRemove })
              }
            >
              Yes
            </button>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => setRemoveOrganizationUserModal(false)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveOrganizationUserModal;
