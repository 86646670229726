import { useFormContext } from "react-hook-form";

const Radio = ({ options, name, onChange, defaultValue, ...rest }) => {
  const { setValue, watch } = useFormContext();
  const field = watch(name);

  return (
    <div
      className="btn-group btn-group-toggle"
      data-toggle="buttons"
      style={{ display: "flex", justifyContent: "center" }}
    >
      {options.map(({ key, value, label }, index) => {
        return (
          <label
            className={`btn btn-light btn-lg ${
              field === value ? "active" : ""
            }`}
            key={key || index}
            onClick={() => {
              onChange?.(value);
              setValue(name, value);
            }}
          >
            {label}
          </label>
        );
      })}
    </div>
  );
};

export default Radio;
