import { useEffect } from "react";
import getFileAsJson from "services/files/getFileAsJson";
// import notify from "utils/toastMsg";

const GetFileAsJson = ({ frfXxUrl, frfYyUrl, onFileLoad }) => {
  useEffect(() => {
    const fetchFile = async (url) => {
      const [ownerId, fileName] = url.split("/");
      const response = await getFileAsJson({ ownerId, fileName });
      return response.data.fileData;
    };

    const fetchFiles = async () => {
      const frfXxData = await fetchFile(frfXxUrl);
      const frfYyData = await fetchFile(frfYyUrl);
      onFileLoad({ frfXxData, frfYyData });
      // notify("FRF loaded successfully, plotting it on chart now. ✅ ");
    };

    fetchFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frfXxUrl, frfYyUrl]);

  return null;
};

export default GetFileAsJson;
