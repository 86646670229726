import * as yup from "yup";

const frfDataSchema = yup.object().shape({
  frfXxUrl: yup
    .string()
    // .required()
    .label("frfXx Data Url")
    .typeError("frfXxUrl cannot be empty"),
  frfYyUrl: yup
    .string()
    // .required()
    .label("frfYy Data Url")
    .typeError("frfYyUrl cannot be empty"),
});

export default frfDataSchema;
