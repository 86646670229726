import http from "utils/http";
import { digiBasic } from "constants/endpoints";

const getDigibasicToolTypes = () => {
  const ENDPOINT = digiBasic.getDigibasicToolTypes();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`);
};

export default getDigibasicToolTypes;
