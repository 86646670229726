import React, { useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router";
import postCompleteRegistration from "services/postCompleteRegistration";
import * as yup from "yup";
import { Form, SubmitButton } from "components/Forms";
import notify from "utils/toastMsg";

const schema = yup.object().shape({});

const EmailVerificationCompleted = () => {
  const history = useHistory();
  const { registrationShortcode, invitedOrg, shortcode, invitationId } =
    useParams();
  const [loading, setLoading] = useState("");

  const handleSubmit = () => {
    try {
      postCompleteRegistration({
        registrationShortcode,
        invitedOrg,
        shortcode,
        invitationId,
      }).then(() => {
        setLoading(false);
        notify("Your have successfully created a profile. ✅ ");
        history.push("/login");
      });
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <div
      className="modal-container"
      style={{
        background: "#fff",
      }}
    >
      <Form
        initialValues={{}}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        <div className="form-group">
          <div className="d-flex justify-content-center align-items-center flex-column mt-5">
            <h2 className="text-center" style={{ paddingTop: "2.5em" }}>
              Click Button Below to Activate Your Productive Machines Account
            </h2>
            <SubmitButton
              className="btn btn-success btn-lg waves-effect waves-light mt-5"
              style={{ width: "auto" }}
              type="submit"
              disabled={loading}
            >
              {shortcode !== "undefined" && invitationId !== "undefined"
                ? "Activate Account and Accept Invitation"
                : "Activate Account"}
            </SubmitButton>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EmailVerificationCompleted;
