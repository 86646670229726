import React, { useState } from "react";
import downloadFile from "services/files/downloadFile";
import saveFile from "utils/saveFile";
import notify from "utils/toastMsg";

const FileDownload = ({ buttonText, url, ...rest }) => {
  const [loading, setLoading] = useState(false);
  if (!url) {
    return null;
  }

  const [ownerId, fileName] = url.split("/");

  return (
    <>
      {Boolean(loading) ? (
        <button {...rest} className="btn btn-info btn-sm" disabled>
          Downloading...
        </button>
      ) : (
        <button
          {...rest}
          className="btn btn-info btn-sm"
          onClick={async () => {
            setLoading(true);
            const response = await downloadFile({ ownerId, fileName });
            const contentDisposition = response.headers["content-disposition"];
            const originalFileName = contentDisposition
              .split("=")[1]
              .split('"')[1];
            saveFile(originalFileName, response);
            setLoading(false);
            notify("Downloaded successfully. ✅ ");
          }}
        >
          {buttonText ? buttonText : `Download`}
          <i className="ti-download" />
        </button>
      )}
    </>
  );
};

export default FileDownload;
