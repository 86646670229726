import * as yup from "yup";

const cadToGcodeSchema = yup.object().shape({
  prtUploadIds: yup
    .array()
    .required()
    .min(1, "There must be at least one upload")
    .label("File Upload")
    .typeError("Part File Upload must be a url."),
  machineBrand: yup
    .string()
    .required()
    .label("Machine Brand")
    .typeError("Machine Brand must be a text."),
  machineModel: yup
    .string()
    .required()
    .label("Machine Model")
    .typeError("Machine Model must be a text."),
  spindleName: yup
    .string()
    .label("Spindle Name")
    .typeError("Spindle name is a required field."),
  spindleSpeed: yup
    .string()
    .required()
    .label("Spindle Speed")
    .typeError("Spindle speed must be a number."),
  maxTorque: yup
    .number()
    .label("Max Torque")
    .typeError("Max Torque Id must be a number."),
  maxPower: yup
    .number()
    .label("Max Power")
    .typeError("Max Power must be a number."),
  dimensionalTolerance: yup
    .number()
    .required()
    .label("Dimensional Tolerance")
    .typeError("Dimensional Tolerance must be a number."),
  surfaceRoughness: yup
    .number()
    .required()
    .label("Surface Roughness")
    .typeError("Surface Roughness must be a number."),
  controllerBrand: yup
    .string()
    .label("Controller Brand")
    .typeError("Controller Brand must be a text."),
  controllerModel: yup
    .string()
    .label("Controller Model")
    .typeError("Controller Model must be a text."),
  materialName: yup
    .string()
    .required()
    .label("Material Name")
    .typeError("Material Name must be a text."),
stdUpload: yup
  .string()
  .required()
  .label("std Upload")
  .typeError("Std Upload must be a url text."),
materialDimensions: yup
  .object()
  .shape({
    shape: yup
      .string()
      .typeError("Shape must be a text.")
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      ),
    height: yup
      .number()
      .typeError("Height must be a number.")
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      ),
    radius: yup
      .number()
      .typeError("Radius must be a number.")
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      ),
    width: yup
      .number()
      .typeError("Width must be a number.")
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      ),
    length: yup
      .number()
      .typeError("Length must be a number.")
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      ),
  })
  .label("Material Dimensions")
  .typeError("Material Dimensions must be an object CAD."),
  fixturingInfo: yup
    .string()
    .required()
    .label("Fixturing Info")
    .typeError("Fixturing Info must be a text."),
  notes: yup.string().label("Notes").typeError("Notes Section must be a text."),
  sessionType: yup
    .string()
    .required()
    .label("Session Type")
    .typeError("Session Type must be a text."),
  cuttingToolIds: yup
    .array()
    .min(1, "There must be at least one tool entry")
    .required()
    .label("Cutting Tools"),
});

export default cadToGcodeSchema;
