import http from "utils/http";
import { digiStability } from "constants/endpoints";

const getDigiStabilityFeedRate = ({ feedRateToolId }) => {
  const ENDPOINT = digiStability.getDigiStabilityFeedRate();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(
    `${API_BASE_URL}${ENDPOINT}?feedRateToolId=${feedRateToolId}`
  );
};

export default getDigiStabilityFeedRate;
