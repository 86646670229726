import React, { useCallback } from "react";
import { Form, ErrorMessage, SubmitButton, Radio } from "components/Forms";
import WatchForm from "components/Forms/WatchForm";
import FileUpload from "components/FileUpload";
import optimizationConstraints from "constants/optimizationConstraints";
import prtUploadSchema from "./schemas/prtUploadSchema";
import postPrtUpload from "services/sessions/postPrtUpload";
import debounce from "utils/debounce";
import notify from "utils/toastMsg";

const PrtUploadSection = ({
  handleModalAfterAddingTool,
  fetchPrtUploads,
  partFileUploadDescription,
}) => {
  const handleAddPrtUpload = async (values, evt, methods) => {
    try {
      const response = await postPrtUpload(values);
      const currentPrtUploadIds = localStorage.getItem("prtUploadIds") || "[]";
      const currentUploadIds = JSON.parse(currentPrtUploadIds);
      const prtUploadId = response.data.prtUploadId;
      const newPrtUploadIds = [...currentUploadIds, prtUploadId];

      setTimeout(() => {
        localStorage.setItem("prtUploadIds", JSON.stringify(newPrtUploadIds));
        fetchPrtUploads();
        localStorage.removeItem("uploadData");
        localStorage.removeItem("originalNameOf-prtUpload");
        localStorage.removeItem("prtUpload");
      }, 375);

      methods.reset();
      notify("Your upload is added succesfully. ✅ ");

      handleModalAfterAddingTool(newPrtUploadIds);
    } catch (err) {
      throw err;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveFormState = useCallback(
    debounce((methods) => {
      localStorage.setItem("uploadData", JSON.stringify(methods.getValues()));
    }, 1500),
    []
  );

  const rawUploadData = localStorage.getItem("uploadData");
  let savedUploadData;
  try {
    savedUploadData = JSON.parse(rawUploadData);
  } catch (error) {
    savedUploadData = {
      optimisationConstraintId: optimizationConstraints.FORCE_PREDICTION.value,
    };
  }

  return (
    <Form
      initialValues={{
        optimisationConstraintId:
          optimizationConstraints.FORCE_PREDICTION.value,
        ...savedUploadData,
      }}
      validationSchema={prtUploadSchema}
      onSubmit={handleAddPrtUpload}
    >
      <WatchForm name="">
        {(formValues, methods) => {
          saveFormState(methods);
        }}
      </WatchForm>
      <div>
        <label
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Please select your Optimization Constraint.
        </label>
        <div
          className="row cardss"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="form-group">
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              <Radio
                name="optimisationConstraintId"
                options={[
                  {
                    value: optimizationConstraints.FORCE_PREDICTION.value,
                    label: optimizationConstraints.FORCE_PREDICTION.label,
                  },
                  {
                    value:
                      optimizationConstraints.MILING_CHATTER_VIBRATION.value,
                    label:
                      optimizationConstraints.MILING_CHATTER_VIBRATION.label,
                  },
                  {
                    value: optimizationConstraints.MILING_FEED_RATE.value,
                    label: optimizationConstraints.MILING_FEED_RATE.label,
                  },
                ]}
              />
            </div>
            <ErrorMessage name="optimisationConstraintId" />
          </div>
        </div>
        <hr className="separator" />
        <div className="form-group">
          <label
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Upload Section
          </label>
          <p className="text-muted">{partFileUploadDescription}</p>
          <FileUpload name="prtUpload" className="form-control" />
          <ErrorMessage name="prtUpload" />
        </div>
      </div>

      <SubmitButton
        type="submit"
        className="btn btn-primary btn-lg waves-effect waves-light"
      >
        Save
      </SubmitButton>
    </Form>
  );
};
export default PrtUploadSection;
