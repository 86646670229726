import React, { useCallback, useState } from "react";
import { useLocation, useHistory } from "react-router";
import ToolingUploadInfo from "./components/ToolingUploadInfo";
import PrtUploadSection from "./components/PrtUploadSection";
import {
  Form,
  Input,
  Select,
  ErrorMessage,
  SubmitButton,
  WatchForm,
} from "components/Forms";
import notify from "utils/toastMsg";
import sessionSchemaProvider from "./schemas/sessionSchemaProvider";
import getSessionCuttingToolByIds from "services/cuttingTools/getSessionCuttingToolByIds";
import getPrtUploadsByUploadIds from "services/sessions/getPrtUploadsByUploadIds";
import useAPI from "effects/useAPI";
import sessionTypes from "constants/sessionTypes";
import workpieceShapes from "constants/workpieceShapes";
import DeleteCuttingToolEntry from "components/Modal/DeleteCuttingToolEntry";
import DeletePrtUploadEntry from "components/Modal/DeletePrtUploadEntry";
import ViewCuttingTool from "components/Modal/ViewCuttingTool";
import debounce from "utils/debounce";
import postUserSession from "services/sessions/postUserSession";
import FileUpload from "components/FileUpload";

const SessionForm = () => {
  let history = useHistory();
  const location = useLocation();
  const sessionType = location.state;
  const [workpieceShape, setWorkpieceShape] = useState(workpieceShapes.SQUARE);

  let partFileUploadTitle = "Tool Path Upload";
  let partFileUploadDescription =
    "The format should be either: Siemens NX CAM Format (.CLS) OR CATIA Format (.APTSOURCE File)";

  if (sessionType === sessionTypes.CAD) {
    partFileUploadTitle = "3D Model (CAD) File Upload";
    partFileUploadDescription =
      "The format should be either: Siemens NX CAM Format (.CLS) OR CATIA Format (.APTSOURCE File)";
  }
  if (sessionType === sessionTypes.CAM) {
    partFileUploadTitle = "CAM File Upload";
    partFileUploadDescription =
      "The format of each tool path should be either: Siemens NX CAM Format (.PRT) OR Other CAM Software";
  }
  if (sessionType === sessionTypes.GCODE) {
    partFileUploadTitle = "NC File (Gcode) Upload";
    partFileUploadDescription =
      "The format should be Numerical Control (NC) programming language (.h)";
  }

  const schema = sessionSchemaProvider(sessionType);
  const rawSavedSessionForm = localStorage.getItem("sessionForm");
  let savedSessionForm;
  try {
    savedSessionForm = JSON.parse(rawSavedSessionForm);
  } catch (error) {
    savedSessionForm = {};
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveFormState = useCallback(
    debounce((formValues) => {
      localStorage.setItem("sessionForm", JSON.stringify(formValues));
    }, 1500),
    []
  );

  const handleMaterialShapeChange = (formValues) => {
    if (formValues.materialDimensions?.shape === workpieceShapes.SQUARE) {
      //we might need to unregister the field  or use SetValue (double check this)
      formValues.materialDimensions.radius = "";
    }
    if (formValues.materialDimensions?.shape === workpieceShapes.CYLINDRICAL) {
      formValues.materialDimensions.length = "";
      formValues.materialDimensions.width = "";
    }

    setWorkpieceShape(formValues.materialDimensions?.shape);
  };

  const renderCylindricalWorkpieceInputs = () => {
    return (
      <div className="col-sm-3">
        <Input
          name="materialDimensions.radius"
          type="text"
          className="form-control"
          placeholder="Radius"
        />
        <ErrorMessage name="materialDimensions.raduis" />
      </div>
    );
  };

  const renderSquareWorkpieceInputs = () => {
    return (
      <>
        <div className="col-sm-3">
          <Input
            name="materialDimensions.width"
            type="text"
            className="form-control"
            placeholder="Width"
          />
          <ErrorMessage name="materialDimensions.width" />
        </div>

        <div className="col-sm-3">
          <Input
            name="materialDimensions.length"
            type="text"
            className="form-control"
            placeholder="Length"
          />
          <ErrorMessage name="materialDimensions.length" />
        </div>
      </>
    );
  };

  //TOOD: forPRTUPLOAD
  const prtUploadIdsGlobal = localStorage.getItem("prtUploadIds") || "[]";
  const uploadIdsGlobal = JSON.parse(prtUploadIdsGlobal);
  const [prtUploadsLoading, prtUploadsError, allPrtUploads, fetchPrtUploads] =
    useAPI(() => {
      const prtUploadIds = localStorage.getItem("prtUploadIds") || "[]";
      let uploadIds;
      try {
        uploadIds = JSON.parse(prtUploadIds);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      return getPrtUploadsByUploadIds({ uploadIds });
    });

  const handleModalAfterAddingUpload = (newPrtUploadIds) => {
    // eslint-disable-next-line no-undef
    $(".bs-example-modal-sm").modal("hide");

    setValue("prtUploadIds", newPrtUploadIds);
  };

  const cuttingToolIdsGlobal = localStorage.getItem("cuttingToolIds") || "[]";
  const toolIdsGlobal = JSON.parse(cuttingToolIdsGlobal);
  const [
    cuttingToolsLoading,
    cuttingToolsError,
    allCuttingTools,
    fetchCuttingTools,
  ] = useAPI(() => {
    const cuttingToolIds = localStorage.getItem("cuttingToolIds") || "[]";
    const toolIds = JSON.parse(cuttingToolIds);
    return getSessionCuttingToolByIds({ cuttingToolIds: toolIds });
  });
  if (cuttingToolsLoading || prtUploadsLoading) {
    return <p>Loading..</p>;
  }
  if (cuttingToolsError || prtUploadsError) {
    return <p>Sorry, something went wrong. Please try again.</p>;
  }
  const { cuttingTools } = allCuttingTools;
  const { prtUploads } = allPrtUploads;

  let setValue;
  const handleModalAfterAddingTool = (newCuttingToolIds) => {
    // eslint-disable-next-line no-undef
    $(".bs-example-modal-sm").modal("hide");

    setValue("cuttingToolIds", newCuttingToolIds);
  };

  const handleSessionSubmit = (values, event, methods) => {
    try {
      const cuttingToolIds = JSON.parse(localStorage.getItem("cuttingToolIds"));
      const prtUploadIds = JSON.parse(localStorage.getItem("prtUploadIds"));
      return postUserSession({
        ...values,
        sessionType,
        prtUploadIds,
        cuttingToolIds,
      }).then((response) => {
        methods.reset();

        setTimeout(() => {
          localStorage.removeItem("prtUploadIds");
          localStorage.removeItem("cuttingToolIds");
          localStorage.removeItem("sessionForm");
          localStorage.removeItem("toolData");
          localStorage.removeItem("uploadData");
          localStorage.removeItem("originalNameOf-prtUpload");
          localStorage.removeItem("originalNameOf-stdUpload");
          localStorage.removeItem("originalNameOf-toolDatasheet");
          localStorage.removeItem("originalNameOf-cuttingToolFRFxxUpload");
          localStorage.removeItem("originalNameOf-cuttingToolFRFyyUpload");
        }, 1500);

        history.push("/");
        notify("Your inputs are saved successfully. ✅ ");

        return response;
      });
    } catch (err) {}
  };

  return (
    <>
      <div style={{ marginTop: 95 }}>
        <Form
          initialValues={{
            ...savedSessionForm,
            cuttingToolIds: toolIdsGlobal,
            prtUploadIds: uploadIdsGlobal,
            sessionType,
          }}
          validationSchema={schema}
          onSubmit={handleSessionSubmit}
        >
          <WatchForm name="">
            {(formValues, methods) => {
              handleMaterialShapeChange(formValues);
              saveFormState(formValues);
              setValue = methods.setValue;
            }}
          </WatchForm>
          <div
            className="row cardss"
            style={{
              display: "flex",
              justifyContent: "center",
              color: "black",
            }}
          >
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-body">
                  <h4 className="mt-0 header-title text-center">
                    {sessionType}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row cardss"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-body">
                  <h4 className="mt-0 header-title text-center">
                    CNC Machine Tool
                  </h4>
                  <p className="text-muted m-b-10">
                    Please provide your machine tool information
                  </p>

                  <div className="form-group">
                    <label>Machine Tool Brand</label>
                    <Input
                      name="machineBrand"
                      type="text"
                      className="form-control"
                      placeholder="Machine Tool Brand"
                    />
                    <ErrorMessage name="machineBrand" />
                  </div>
                  <div className="form-group">
                    <label>Machine Tool Model</label>
                    <Input
                      name="machineModel"
                      type="text"
                      className="form-control"
                      placeholder="Machine Tool Model"
                    />
                    <ErrorMessage name="machineModel" />
                  </div>
                  <div className="form-group">
                    <label>Spindle Model Name</label>
                    <Input
                      name="spindleName"
                      type="text"
                      className="form-control"
                      placeholder="Spindle Model Name"
                    />
                    <ErrorMessage name="spindleName" />
                  </div>
                  <div className="form-group">
                    <label>Max Spindle Speed (RPM)</label>
                    <Input
                      name="spindleSpeed"
                      type="number"
                      className="form-control"
                      placeholder=" Max Spindle Speed"
                    />
                    <ErrorMessage name="spindleSpeed" />
                  </div>
                  <div className="form-group">
                    <label>Max Torque (Nm)</label>
                    <Input
                      name="maxTorque"
                      type="number"
                      className="form-control"
                      placeholder=" Max Torque"
                    />
                    <ErrorMessage name="maxTorque" />
                  </div>
                  <div className="form-group">
                    <label>Max Power (kW)</label>
                    <Input
                      name="maxPower"
                      type="number"
                      className="form-control"
                      placeholder=" Max Power"
                    />
                    <ErrorMessage name="maxPower" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row cardss"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-body">
                  <h4 className="mt-0 header-title text-center">Controller</h4>
                  <p className="text-muted m-b-10">
                    Please provide machine tool controller information below.
                  </p>
                  <div className="form-group">
                    <label>Controller Brand</label>
                    <Input
                      name="controllerBrand"
                      type="text"
                      className="form-control"
                      placeholder="e.g: Siemens"
                    />
                    <ErrorMessage name="controllerBrand" />
                  </div>
                  <div className="form-group">
                    <label>Controller Model</label>
                    <Input
                      name="controllerModel"
                      type="text"
                      className="form-control"
                      placeholder="e.g: Sinumerik"
                    />
                    <ErrorMessage name="controllerModel" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row cardss"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-body">
                  <h4 className="mt-0 header-title text-center">Tooling</h4>
                  <p className="text-muted m-b-10">
                    Please add the cutting tool and tool holder information
                    below.
                  </p>
                  {Boolean(cuttingTools.length) && (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Operation Name</th>
                          <th scope="col">Tool Name</th>
                          <th scope="col">Tool Holder</th>
                          <th scope="col">
                            Tool number as it is defined in CAM
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cuttingTools.map((tool) => {
                          return (
                            <tr>
                              <td>{tool.operationName}</td>
                              <td>{tool.cuttingToolName}</td>
                              <td>{tool.toolHolderName}</td>
                              <td> {tool.cuttingToolNumber}</td>
                              <th>
                                {" "}
                                <ViewCuttingTool
                                  cuttingToolId={tool.cuttingToolId}
                                  cuttingToolDetails={tool}
                                />
                                <DeleteCuttingToolEntry
                                  toolId={tool.cuttingToolId}
                                  fetchCuttingTools={fetchCuttingTools}
                                />
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  <button
                    type="button"
                    className="btn btn-primary btn-lg waves-effect waves-light"
                    data-toggle="modal"
                    data-target=".bs-example-modal-sm"
                  >
                    Add New Tool
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row cardss"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-body">
                  <h4 className="mt-0 header-title text-center">
                    {partFileUploadTitle}
                  </h4>

                  <p className="text-muted">
                    {" "}
                    Please upload your files based on your machine framework.
                  </p>
                  {Boolean(prtUploads.length) && (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Upload Ref</th>
                          <th scope="col">Optimization Constraint</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {prtUploads.map((upload) => {
                          return (
                            <tr>
                              <td>{upload.prtUpload}</td>
                              <td>{upload.optimisationConstraint}</td>
                              <th style={{ paddingLeft: "26px" }}>
                                <DeletePrtUploadEntry
                                  uploadId={upload.prtUploadId}
                                  fetchPrtUploads={fetchPrtUploads}
                                />
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  <button
                    type="button"
                    className="btn btn-primary btn-lg waves-effect waves-light"
                    data-toggle="modal"
                    data-target="#modal-1"
                  >
                    Add New Upload
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row cardss"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-body">
                  <h4 className="mt-0 header-title text-center">Workpiece</h4>
                  <p className="text-muted m-b-10">
                    Please provide the workpiece material information below.
                  </p>
                  <div className="form-group">
                    <label>Material Name</label>
                    <Input
                      name="materialName"
                      type="text"
                      className="form-control"
                      placeholder="Material Name"
                    />
                    <ErrorMessage name="materialName" />
                  </div>

                  {sessionType !== sessionTypes.CAM && (
                    <>
                      <div className="form-group">
                        <label>Stock Part Geometry Upload</label>
                        <p className="text-muted ">The format should be .STL</p>
                        <FileUpload name="stdUpload" className="form-control" />
                        <ErrorMessage name="stdUpload" />
                      </div>
                      <div className="grid">
                        <label>Dimensions &amp; Shape</label>

                        <div className="form-row">
                          <div className="col-sm-3">
                            <Select
                              name="materialDimensions.shape"
                              options={[
                                {
                                  key: 1,
                                  value: workpieceShapes.SQUARE,
                                  label: workpieceShapes.SQUARE,
                                },
                                {
                                  key: 2,
                                  value: workpieceShapes.CYLINDRICAL,
                                  label: workpieceShapes.CYLINDRICAL,
                                },
                              ]}
                              className="form-control"
                              placeholder="Shape"
                            />
                            <ErrorMessage name="materialDimensions.shape" />
                          </div>
                          <div className="col-sm-3">
                            <Input
                              name="materialDimensions.height"
                              type="text"
                              className="form-control"
                              placeholder="Height"
                            />
                            <ErrorMessage name="materialDimensions.height" />
                          </div>
                          {workpieceShape === workpieceShapes.SQUARE
                            ? renderSquareWorkpieceInputs()
                            : renderCylindricalWorkpieceInputs()}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="row cardss"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-body">
                  <h4 className="mt-0 header-title text-center">Fixturing</h4>
                  <p className="text-muted m-b-10">
                    Please provide available or suggested fixturing equipment
                    information below
                  </p>
                  <div className="form-group">
                    <label>Fixturing Methods and Tools</label>
                    <Input
                      name="fixturingInfo"
                      type="text"
                      className="form-control"
                      placeholder="Fixturing Methods and Tools"
                    />
                    <ErrorMessage name="fixturingInfo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row cardss"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-body">
                  <h4 className="mt-0 header-title text-center">Tolerances</h4>
                  <div className="form-group">
                    <label>
                      Dimensional Tolerance (<span>&#181;</span>m)
                    </label>
                    <Input
                      name="dimensionalTolerance"
                      type="number"
                      className="form-control"
                      placeholder="Dimensional Tolerance"
                    />

                    <ErrorMessage name="dimensionalTolerance" />
                  </div>

                  <div className="form-group">
                    <label>
                      Surface Roughness (Ra <span>&#181;</span>m)
                    </label>
                    <Input
                      name="surfaceRoughness"
                      type="number"
                      className="form-control"
                      placeholder="Surface Roughness"
                    />
                    <ErrorMessage name="surfaceRoughness" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row cardss"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-body">
                  <div className="form-group">
                    <label>Notes</label>
                    <Input
                      name="notes"
                      type="text"
                      className="form-control"
                      placeholder="Notes for our technical team"
                    />
                    <ErrorMessage name="notes" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0px 0px 90px 0px",
            }}
          >
            <div className="col-lg-3">
              <button
                type="button"
                className="btn btn-outline-primary btn-lg waves-effect waves-light"
                style={{ width: "-webkit-fill-available" }}
                onClick={() => {
                  history.push({
                    pathname: "/",
                    state: sessionTypes.GCODE,
                  });
                }}
              >
                {" "}
                <i className="fas fa-arrow-left pr-2"></i>
                Back to Dashboard
              </button>
            </div>
            <div className="col-lg-3">
              <SubmitButton
                type="submit"
                className="btn btn-success btn-lg waves-effect waves-light"
                style={{ width: "-webkit-fill-available" }}
              >
                Submit
              </SubmitButton>
            </div>
          </div>
        </Form>
        <div
          className="modal fade bs-example-modal-sm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="modal"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  Tooling
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ToolingUploadInfo
                  handleModalAfterAddingTool={handleModalAfterAddingTool}
                  fetchCuttingTools={fetchCuttingTools}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bs-example-modal-sm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="modal-1"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  {partFileUploadTitle}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <PrtUploadSection
                  handleModalAfterAddingTool={handleModalAfterAddingUpload}
                  fetchPrtUploads={fetchPrtUploads}
                  partFileUploadDescription={partFileUploadDescription}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SessionForm;
