import React from "react";
import { useLocation } from "react-router-dom";

const PageWrapper = ({ children }) => {
  let location = useLocation();
  const isHeaderVisible = ["/login", "/register"].includes(location.pathname);

  return (
    <div
      className={isHeaderVisible ? "content-page-no-sidebar" : "content-page-s"}
    >
      {children}
    </div>
  );
};

export default PageWrapper;
