import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import useYupValidationResolver from "./useYupValidationResolver";

const Form = ({
  validationSchema,
  initialValues,
  onSubmit,
  children,
  useFormOptions,
  ...rest
}) => {
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: "onChange",
    reValidateMode: "onChange",
    ...useFormOptions,
  });

  const handleSubmitWithAPIErrors = (...args) => {
    const submitCall = onSubmit(...args, methods);
    if (typeof submitCall?.then === "function") {
      // handle possible API validation issues with Yup
      submitCall.catch((err) => {
        let errors;
        // eslint-disable-next-line no-console
        console.trace(err);
        errors = err.response.data;
        if (errors) {
          errors.forEach(({ path, message }, index) => {
            methods.setError(
              path,
              { type: "APIError", message },
              { shouldFocus: index === 0 }
            );
          });
        }
      });
    } else {
      // eslint-disable-next-line no-console
      console.warn("Your <Form> onSubmit handler is missing return statement.");
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleSubmitWithAPIErrors)}
        {...rest}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
