import { useFormContext } from "react-hook-form";

const WatchForm = ({ name, children }) => {
  const { watch, ...rest } = useFormContext();
  const value = watch(name);

  return children(value, { formValues: rest.getValues(), ...rest }) ?? null;
};

export default WatchForm;
