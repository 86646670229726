import React, { useState, useLayoutEffect, useEffect } from "react";
import notify from "utils/toastMsg";
import Tooling from "./components/Tooling";
import CuttingParameters from "./components/CuttingParameters";
import ProcessPrediction from "./components/ProcessPrediction";
import FeedRateOptimization from "./components/FeedrateOptimization";
import postDigiBasicToolData from "services/digiBasic/postDigiBasicToolData";
import postDigiBasicFeedRateOptimisation from "services/digiBasic/postDigiBasicFeedRateOptimisation";
import History from "./components/History";
import { Form } from "components/Forms";
import digiBasicSchema from "./schemas/digiBasicSchema";
import feedRateOptimizationSchema from "./schemas/feedRateOptimizationSchema";
import DigibasicErrorModal from "components/Modal/DigibasicSubmitError";
import "./style.css";

const DigiBasic = () => {
  const windowWidth = window.innerWidth;
  const [selectedOption, setSelectedOption] = useState("Tooling");
  const [toolingDataSubmitted, setToolingDataSubmitted] = useState(false);
  const [digibasicToolId, setDigibasicToolId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cuttingDisabled, setCuttingDisabled] = useState(true);
  const [digibasicResponse, setDigibasicResponse] = useState({});
  const [optimizationResponse, setOptimizationResponse] = useState({});

  const initialValues = {
    diameter: 16,
    digibasicToolId: 1,
    digibasicToolTypeId: 1,
    fluteLength: 30,
    fluteParameters: {
      helixAngle: [30],
      helixOption: 1,
      noOfFlutes: 4,
      pitchAngle: [0],
      pitchOption: 1,
      rakeAngle: 10,
    },
    cuttingParameters: {
      cuttingSpeed: 0,
      depthOfCut: 15,
      feedPerTooth: 0.2,
      feedRate: 400,
      feedTypeSelection: 1,
      materialSelection: 1,
      millingTypeSelection: 1,
      speedTypeSelection: 1,
      spindleSpeed: 1000,
      widthOfCut: 8,
    },
  };

  const renderComponent = (windowWidth) => {
    const componentMap = {
      Tooling: (
        <Tooling
          setSelectedOption={setSelectedOption}
          windowWidth={windowWidth}
          setCuttingDisabled={setCuttingDisabled}
        />
      ),
      DigibasicSubmitError: <DigibasicErrorModal />,
      FeedRateOptimisationError: <DigibasicErrorModal />,
      cuttingParameters: (
        <CuttingParameters
          setSelectedOption={setSelectedOption}
          setToolingDataSubmitted={setToolingDataSubmitted}
          loading={loading}
          windowWidth={windowWidth}
        />
      ),
      processPrediction: (
        <ProcessPrediction
          windowWidth={windowWidth}
          digibasicResponse={digibasicResponse}
          setSelectedOption={setSelectedOption}
        />
      ),
      feedRateOptimization: (
        <FeedRateOptimization
          setToolingDataSubmitted={setToolingDataSubmitted}
          optimizedFeedRate={optimizationResponse}
          digibasicResponse={digibasicResponse}
          setSelectedOption={setSelectedOption}
        />
      ),
      history: <History />,
    };
    return componentMap[selectedOption] || null;
  };

  const handleDigiBasicSubmit = async (values) => {
    const propertiesToParse = [
      "digibasicToolTypeId",
      "materialSelection",
      "widthOfCut",
      "cuttingSpeed",
      "feedRate",
      "spindleSpeed",
      "feedPerTooth",
      "diameter",
      "fluteLength",
      "noOfFlutes",
      "rakeAngle",
    ];

    for (const prop of propertiesToParse) {
      if (!isNaN(values.cuttingParameters[prop])) {
        values.cuttingParameters[prop] = parseFloat(
          values.cuttingParameters[prop]
        );
      }
    }

    values.fluteParameters.helixAngle = values.fluteParameters.helixAngle
      .map(parseFloat)
      .filter((value) => !isNaN(value) && value !== " ");
    values.fluteParameters.pitchAngle = values.fluteParameters.pitchAngle
      .map(parseFloat)
      .filter((value) => !isNaN(value) && value !== " ");

    try {
      setLoading(true);
      const response = await postDigiBasicToolData({
        ...values,
      });
      setDigibasicResponse(response.data.lambdaResponse);
      setSelectedOption("processPrediction");
      notify("Tool data has been received successfully. ✅ ");
      setDigibasicToolId(response.data.digiBasicToolId);
      setLoading(false);
      setToolingDataSubmitted(true);
      return response;
    } catch (err) {
      setSelectedOption("DigibasicSubmitError");
      setLoading(false);
    }
  };

  const handleDigiBasicFeedRateOptimisation = async (values) => {
    values.optimizationParameters.digibasicOptimisationConstraintId =
      parseFloat(
        values.optimizationParameters.digibasicOptimisationConstraintId
      );
    values.optimizationParameters.selectedConstraintMagnitude = parseFloat(
      values.optimizationParameters.selectedConstraintMagnitude
    );
    try {
      setLoading(true);
      const {
        optimizationParameters: {
          digibasicOptimisationConstraintId,
          selectedConstraintMagnitude,
        },
      } = values;
      const response = await postDigiBasicFeedRateOptimisation({
        digibasicOptimisationConstraintId,
        selectedConstraintMagnitude,
        digibasicToolId,
      });
      notify("Feed Rate Optimisation has been received successfully. ✅ ");
      setOptimizationResponse(response);
    } catch (err) {
      setSelectedOption("FeedRateOptimisationError");
    } finally {
      setLoading(false);
    }
  };

  const [renderedComponent, setRenderedComponent] = useState();
  const [styleObject, setStyleObject] = useState();

  const dynamicallyGenerateStyle = (windowWidth) => {
    const calculateClassName1 = () =>
      windowWidth > 994 ? "row mt-5 mb-5" : "mt-5 mb-5";

    const calculateClassName2 = () => (windowWidth > 994 ? "col-md-2" : "ml-3");

    const calculateClassName3 = () => (windowWidth > 994 ? "0" : "40px");

    const calculateClassName4 = () =>
      windowWidth > 994
        ? "col-md-10 justify-content-center"
        : "d-flex justify-content-center";
    return {
      style1: calculateClassName1(),
      style2: calculateClassName2(),
      style3: calculateClassName3(),
      style4: calculateClassName4(),
    };
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setStyleObject(dynamicallyGenerateStyle(windowWidth));
    });

    return () => {
      window.removeEventListener("resize", () => {
        setStyleObject(dynamicallyGenerateStyle(windowWidth));
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  useEffect(() => {
    setRenderedComponent(renderComponent(window.innerWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, selectedOption, digibasicResponse, optimizationResponse]);

  return (
    <>
      {loading && (
        <div className="loading-spinner">
          <div className="spinner-border spinner" />
        </div>
      )}
      <div className="container my-4" style={{ backgroundColor: "#f6f7fc" }}>
        <div className={styleObject ? styleObject.style1 : "row mt-5 mb-5"}>
          <div className={styleObject ? styleObject.style2 : "col-md-2"}>
            <div
              className="nav-buttons-container"
              style={{
                marginLeft: styleObject ? styleObject.style3 : "0",
              }}
            >
              <div
                className={
                  windowWidth < 1200
                    ? "d-flex justify-content-center"
                    : "d-flex justify-content-center ml-1"
                }
              >
                <ul
                  className={
                    windowWidth < 768
                      ? "nav d-flex justify-content-center"
                      : "nav"
                  }
                >
                  <li>
                    <button
                      type="button"
                      className="btn nav-buttons"
                      style={{
                        boxShadow:
                          selectedOption === "Tooling"
                            ? "inset 5px 5px 10px rgba(0, 0, 0, 0.25)"
                            : "inset -5px -5px 10px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(255, 255, 255, 0.45)",
                      }}
                      onClick={() => setSelectedOption("Tooling")}
                      disabled={loading || toolingDataSubmitted}
                    >
                      <h6>Tooling</h6>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn nav-buttons"
                      style={{
                        boxShadow:
                          selectedOption === "cuttingParameters"
                            ? "inset 5px 5px 10px rgba(0, 0, 0, 0.25)"
                            : "inset -5px -5px 10px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(255, 255, 255, 0.45)",
                      }}
                      onClick={() => setSelectedOption("cuttingParameters")}
                      disabled={
                        loading || toolingDataSubmitted || cuttingDisabled
                      }
                    >
                      <h6>Cutting Parameters</h6>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn nav-buttons"
                      style={{
                        boxShadow:
                          selectedOption === "processPrediction"
                            ? "inset 5px 5px 10px rgba(0, 0, 0, 0.25)"
                            : "inset -5px -5px 10px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(255, 255, 255, 0.45)",
                      }}
                      onClick={() => setSelectedOption("processPrediction")}
                      disabled={loading || !toolingDataSubmitted}
                    >
                      <h6>Process Prediction</h6>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn nav-buttons"
                      style={{
                        boxShadow:
                          selectedOption === "feedRateOptimization"
                            ? "inset 5px 5px 10px rgba(0, 0, 0, 0.25)"
                            : "inset -5px -5px 10px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(255, 255, 255, 0.45)",
                      }}
                      onClick={() => setSelectedOption("feedRateOptimization")}
                      disabled={loading || !toolingDataSubmitted}
                    >
                      <h6>Feed Rate Optimization</h6>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn nav-buttons"
                      style={{
                        boxShadow:
                          selectedOption === "history"
                            ? "inset 5px 5px 10px rgba(0, 0, 0, 0.25)"
                            : "inset -5px -5px 10px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(255, 255, 255, 0.45)",
                      }}
                      onClick={() => setSelectedOption("history")}
                    >
                      <h6>History</h6>
                    </button>
                  </li>
                </ul>
              </div>
              <button
                type="button"
                className="refresh-session-button"
                style={{
                  width: windowWidth <= 994 ? "120px" : "150px",
                }}
                onClick={() => window.location.reload(false)}
              >
                <h6
                  style={{
                    fontSize: "13px",
                    alignSelf: "center",
                  }}
                >
                  Refresh This Session
                </h6>
              </button>
            </div>
          </div>
          <div
            className={
              styleObject
                ? styleObject.style4
                : "col-md-10 justify-content-center"
            }
          >
            <Form
              initialValues={initialValues}
              validationSchema={
                selectedOption === "feedRateOptimization"
                  ? feedRateOptimizationSchema({
                      digibasicResponse,
                    })
                  : digiBasicSchema
              }
              onSubmit={
                selectedOption === "feedRateOptimization"
                  ? handleDigiBasicFeedRateOptimisation
                  : handleDigiBasicSubmit
              }
            >
              {renderedComponent}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigiBasic;
