import axios from "axios";
import { user } from "constants/endpoints";
import parseJWTPayload from "./utils/parseJWTPayload";
import { jwtDecode } from "jwt-decode";

class Authentication {
  login({ email, password, captcha }) {
    const loginEndpoint = user.login();
    const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
    return axios(`${API_BASE_URL}${loginEndpoint}`, {
      method: "POST",
      data: {
        email,
        password,
        captcha,
      },
    }).then((response) => {
      if (response.data.challengeName) {
        return {
          mfaRequired: true,
          challengeName: response.data.challengeName,
          session: response.data.session,
          email,
        };
      } else {
        const accessToken = response.data.accessToken;
        const refreshToken = response.data.refreshToken;
        const userData = response.data.user;
        localStorage.setItem("creds", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("userData", JSON.stringify(userData));
        return {
          mfaRequired: false,
        };
      }
    });
  }

  respondToChallenge({ email, mfaCode, session, challengeName }) {
    const mfaEndpoint = user.respondToAuthChallenge();
    const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
    return axios(`${API_BASE_URL}${mfaEndpoint}`, {
      method: "POST",
      data: {
        email,
        mfaCode,
        session,
        challengeName,
      },
    }).then((response) => {
      const accessToken = response.data.accessToken;
      const refreshToken = response.data.refreshToken;
      const userData = response.data.user;
      localStorage.setItem("creds", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("userData", JSON.stringify(userData));
    });
  }

  associateSoftwareToken({ session, email }) {
    const endpoint = user.associateSoftwareToken();
    const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
    return axios
      .post(`${API_BASE_URL}${endpoint}`, { session, email })
      .then((response) => response.data);
  }

  verifySoftwareToken({ userCode, session }) {
    const endpoint = user.verifySoftwareToken();
    const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
    return axios
      .post(`${API_BASE_URL}${endpoint}`, { userCode, session })
      .then((response) => {
        const accessToken = response.data.accessToken;
        const refreshToken = response.data.refreshToken;
        const userData = response.data.user;
        localStorage.setItem("creds", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("userData", JSON.stringify(userData));
      });
  }

  respondToMfaChallenge({ email, mfaCode, session, challengeName }) {
    const mfaEndpoint = user.respondToAuthChallenge();
    const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
    return axios
      .post(`${API_BASE_URL}${mfaEndpoint}`, {
        email,
        mfaCode,
        session,
        challengeName,
      })
      .then((response) => {
        const accessToken = response.data.accessToken;
        const refreshToken = response.data.refreshToken;
        const userData = response.data.user;
        localStorage.setItem("creds", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("userData", JSON.stringify(userData));
      });
  }

  loginWithAdamos(token) {
    localStorage.setItem("creds", token);
  }

  logout() {
    const logoutEndpoint = user.logout();
    const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
    const refreshToken = localStorage.getItem("refreshToken");
    return axios(`${API_BASE_URL}${logoutEndpoint}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
      data: {
        refreshToken,
      },
    }).then(() => {
      localStorage.removeItem("creds");
      localStorage.removeItem("userData");
      localStorage.removeItem("refreshToken");
    });
  }

  forgotPassword({ email }) {
    const forgotPasswordEndpoint = user.postInitiateForgotPassword();
    const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
    return axios(`${API_BASE_URL}${forgotPasswordEndpoint}`, {
      method: "POST",
      data: {
        email,
      },
    });
  }

  confirmForgotPassword({ email, code, newPassword }) {
    const confirmForgotPasswordEndpoint = user.postConfirmForgotPassword();
    const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
    return axios(`${API_BASE_URL}${confirmForgotPasswordEndpoint}`, {
      method: "POST",
      data: {
        email,
        code,
        newPassword,
      },
    });
  }

  register() {}

  isAuthenticated() {
    let hasAccessTokenExpired;
    const accessToken = this.getAccessToken();
    if (accessToken) {
      const parsedToken = parseJWTPayload(accessToken);
      const { exp } = parsedToken;
      if (!exp) {
        hasAccessTokenExpired = true;
      } else {
        const now = Math.floor(Date.now() / 1000);
        hasAccessTokenExpired = exp < now;
      }
      return !hasAccessTokenExpired;
    } else {
      return false;
    }
  }

  getAccessToken() {
    return localStorage.getItem("creds");
  }

  getUserData() {
    return localStorage.getItem("userData");
  }

  getAccessTokenPayload() {
    const accessToken = this.getAccessToken();
    const userDataString = this.getUserData();

    if (accessToken && userDataString) {
      const decodedToken = jwtDecode(accessToken);
      const userData = JSON.parse(userDataString);
      const combinedData = { ...decodedToken, ...userData };
      return combinedData;
    }

    return null;
  }
}

export default Authentication;
