import React from "react";
import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import getColor from "./utils/getColor";
import { convertFromUnderscoreCase } from "./utils/toSentenceCase";

const ProcessPrediction = ({
  digibasicResponse,
  windowWidth,
  setSelectedOption,
}) => {
  const [parametersToPlot, setParametersToPlot] = useState(["Feed_Force"]);
  const [lastSelectedParameter, setLastSelectedParameter] =
    useState("Feed_Force");

  const PARAMETER_TYPES = [
    "Feed_Force",
    "CrossFeed_Force",
    "Axial_Force",
    "Side_Force",
    "Torque",
    "Power",
    "ChipThickness",
  ];

  let allParameterData = [];

  const computeChartData = () => {
    const chipThicknessKeys = Object.keys(
      digibasicResponse.Original?.ChipThickness
    );

    const chipThicknessFirstKey = chipThicknessKeys[0];
    const chipThicknessFirstValue =
      digibasicResponse.Original?.ChipThickness[chipThicknessFirstKey];

    const chipThicknessLength = chipThicknessFirstValue.length;

    parametersToPlot
      .filter((item) => !item.startsWith("ChipThickness_"))
      .map((parameterType) => {
        const chartDataFilteredByParameterType =
          digibasicResponse.Original[parameterType];

        const parameterData = Object.entries(chartDataFilteredByParameterType);

        if (parameterType === "ChipThickness") {
          const chipThicknessData = [];

          parameterData.forEach((item) => {
            const [degree, parameterValue] = item;

            if (parameterValue.length < chipThicknessLength) {
              // handle the error appropriately (e.g. throw an error or skip the object)
            } else {
              for (let i = 0; i < chipThicknessLength; i++) {
                const obj = {
                  degree,
                  [`ChipThickness_${i + 1}`]: parameterValue[i],
                };

                chipThicknessData.push(obj);
              }
            }
          });

          const groupedItems = Object.entries(
            chipThicknessData.reduce((acc, curr) => {
              Object.entries(curr).forEach(([key, value]) => {
                if (key.startsWith("ChipThickness_")) {
                  const index = key.split("_")[1];
                  if (!acc[index]) {
                    acc[index] = [];
                  }
                  acc[index].push(curr);
                }
              });
              return acc;
            }, {})
          ).map(([, value]) => value);

          allParameterData.push(...groupedItems);
        } else {
          allParameterData.push(
            parameterData.map(([degree, parameterValue]) => {
              return {
                degree,
                [parameterType]: parameterValue,
              };
            })
          );
        }

        allParameterData.forEach((arr) => {
          arr.forEach((item) => {
            item.degree = parseFloat(item.degree);
          });

          arr.sort((a, b) => {
            return a.degree - b.degree;
          });
        });

        return allParameterData;
      });

    const parameterLength = allParameterData[0]?.length;

    let chartDataPoints = [];
    for (let i = 0; i < parameterLength; i++) {
      let finalChartDataPoint = {};
      allParameterData.forEach((parameterValues) => {
        finalChartDataPoint = { ...finalChartDataPoint, ...parameterValues[i] };
      });
      chartDataPoints.push(finalChartDataPoint);
    }
    const finalDataPoints = chartDataPoints.map((dataPoint) => {
      const { ChipThickness, ...rest } = dataPoint;
      if (!ChipThickness) return rest;
      const newProps = ChipThickness.reduce(
        (acc, val, i) => ({ ...acc, [`ChipThickness_${i + 1}`]: val }),
        {}
      );
      return { ...rest, ...newProps };
    });

    return finalDataPoints;
  };

  const chartDataToBeDisplayed = computeChartData();

  const getAveMaxValues = (data, lastSelectedParameter) => {
    if (!lastSelectedParameter) {
      return {
        ave: "-",
        max: "-",
        unit: "",
      };
    }

    if (lastSelectedParameter === "ChipThickness") {
      const aveKey = `Ave_${lastSelectedParameter}`;
      const maxKey = `Max_${lastSelectedParameter}`;

      return {
        ave: data[aveKey],
        max: data[maxKey],
        unit: "mm",
      };
    }

    const aveKey = `Ave_${lastSelectedParameter}`;
    const maxKey = `Max_${lastSelectedParameter}`;

    return {
      ave: data[aveKey],
      max: data[maxKey],
      unit:
        lastSelectedParameter === "Torque"
          ? "Nm"
          : lastSelectedParameter === "Power"
          ? "W"
          : "N",
    };
  };

  const averageValue =
    getAveMaxValues(digibasicResponse.Original, lastSelectedParameter).ave +
    " " +
    getAveMaxValues(digibasicResponse.Original, lastSelectedParameter).unit;

  const maxValue =
    getAveMaxValues(digibasicResponse.Original, lastSelectedParameter).max +
    " " +
    getAveMaxValues(digibasicResponse.Original, lastSelectedParameter).unit;

  return (
    <>
      {digibasicResponse === undefined ? (
        <h1>Loading...</h1>
      ) : (
        <div className="container mt-2" style={{ background: "none" }}>
          <h2 className="d-flex justify-content-center mb-4">
            Process Prediction
          </h2>
          <div className="d-flex justify-content-between">
            <div className="row">
              {/* First Column */}
              <div className="col-sm-3 row align-content-between">
                <div>
                  {PARAMETER_TYPES.map((parameterType, index) => {
                    return (
                      <div key={parameterType}>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const doesParameterExists = parametersToPlot.some(
                              (parameter) => parameter === parameterType
                            );
                            if (doesParameterExists) {
                              const newParametersToPlot =
                                parametersToPlot.filter(
                                  (parameter) => parameter !== parameterType
                                );

                              parameterType === "ChipThickness"
                                ? setParametersToPlot(
                                    newParametersToPlot.filter(
                                      (item) =>
                                        !item.startsWith("ChipThickness_")
                                    )
                                  )
                                : setParametersToPlot(newParametersToPlot);

                              lastSelectedParameter === parameterType &&
                                setLastSelectedParameter(
                                  parametersToPlot.filter(
                                    (item) => !item.startsWith("ChipThickness_")
                                  )[parametersToPlot.length - 2]
                                );
                            } else {
                              const chipThickness =
                                digibasicResponse.Original.ChipThickness;

                              const chipThicknessLength =
                                chipThickness[Object.keys(chipThickness)[0]]
                                  .length;

                              let chipThicknessArray = [];
                              for (let i = 0; i < chipThicknessLength; i++) {
                                chipThicknessArray.push(
                                  `ChipThickness_${i + 1}`
                                );
                              }

                              parameterType === "ChipThickness"
                                ? setParametersToPlot([
                                    parameterType,
                                    ...chipThicknessArray,
                                  ])
                                : parameterType === "Torque"
                                ? setParametersToPlot([parameterType])
                                : parameterType === "Power"
                                ? setParametersToPlot([parameterType])
                                : setParametersToPlot(
                                    [
                                      ...parametersToPlot.filter(
                                        (parameter) =>
                                          !parameter.includes("ChipThickness")
                                      ),
                                      parameterType,
                                    ].filter(
                                      (item) =>
                                        item !== "ChipThickness" &&
                                        item !== "Torque" &&
                                        item !== "Power"
                                    )
                                  );
                              setLastSelectedParameter(parameterType);
                            }
                          }}
                          style={{
                            width: "120px",
                            height: "50px",
                            color: "#000",
                            borderTopLeftRadius: index === 0 ? "10px" : "0px",
                            borderTopRightRadius: index === 0 ? "10px" : "0px",
                            borderBottomRightRadius:
                              PARAMETER_TYPES.length - 1 === index
                                ? "10px"
                                : "0px",
                            borderBottomLeftRadius:
                              PARAMETER_TYPES.length - 1 === index
                                ? "10px"
                                : "0px",
                            backgroundColor: parametersToPlot.filter(
                              (parameter) => parameter === parameterType
                            ).length
                              ? "#bbc3c9"
                              : "#DAE0E5",
                            border: "none",
                            borderBottom:
                              PARAMETER_TYPES.length - 1 === index
                                ? "0px solid"
                                : "1px solid",
                          }}
                        >
                          {convertFromUnderscoreCase(parameterType)}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div className="ml-5 pl-3 mt-2">
                  <div
                    className="d-flex"
                    style={{ marginTop: "6em", marginBottom: -3 }}
                  >
                    <div
                      className="d-flex flex-row align-items-center"
                      style={{ marginBottom: -5 }}
                    >
                      <p
                        style={{
                          fontSize: "26px",
                        }}
                      >
                        MRR
                      </p>
                      <p className="ml-1" style={{ fontSize: "20px" }}>
                        (cm3/min):
                      </p>
                      <p className="ml-2" style={{ fontSize: "26px" }}>
                        {digibasicResponse?.Original.MRR}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex" style={{ marginBottom: -5 }}>
                    <p
                      style={{
                        fontSize: "25px",
                        marginRight: "1em",
                        minWidth: "7em",
                      }}
                    >
                      Max: {maxValue}
                    </p>
                  </div>
                  <div className="d-flex">
                    <p
                      style={{
                        fontSize: "25px",
                        width: "10em",
                      }}
                    >
                      Average: {averageValue}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-9">
                <LineChart
                  width={650}
                  height={450}
                  data={chartDataToBeDisplayed}
                  margin={{ top: 25, right: 30, left: 10, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="degree"
                    tickCount={1}
                    interval={39}
                    label={{
                      value: "Rotation Angle (deg)",
                      position: "right",
                      dx: -93,
                      dy: 12,
                    }}
                  />
                  <YAxis
                    label={{
                      value: !parametersToPlot.length
                        ? undefined
                        : parametersToPlot.length === 1
                        ? parametersToPlot[0] === "Power"
                          ? "Power (W)"
                          : parametersToPlot[0] === "Torque"
                          ? "Torque (Nm)"
                          : "Force (N)"
                        : parametersToPlot.includes("ChipThickness")
                        ? "Chip Thickness (mm)"
                        : "Force (N)",
                      position: "top",
                      dy: -10,
                      dx: 21,
                    }}
                  />

                  <Tooltip />
                  <Legend />
                  {parametersToPlot
                    .filter((item) => item !== "ChipThickness")
                    .map((parameterType) => {
                      return (
                        <Line
                          key={parameterType}
                          type="monotone"
                          dataKey={parameterType}
                          stroke={getColor(parameterType)}
                          dot={false}
                        />
                      );
                    })}
                </LineChart>
                <div
                  className="d-flex justify-content-end"
                  style={{ marginTop: "5rem" }}
                >
                  <button
                    className="btn waves-effect waves-light"
                    style={{
                      width: "200px",
                      backgroundColor: "#3498DB",
                      color: "#fff",
                    }}
                    onClick={() => {
                      setSelectedOption("feedRateOptimization");
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5 ml-2">
              <img
                style={{
                  borderRadius: "5px",
                  height: "16em",
                  display: windowWidth <= 994 && "none",
                  marginLeft: "10em",
                  marginTop: "4em",
                }}
                src="assets/images/forces.png"
                alt="Card cap"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProcessPrediction;
