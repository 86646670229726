import React from "react";
import deletePrtUploadById from "services/sessions/deletePrtUploadById";

const DeletePrtUploadEntry = ({ uploadId, fetchPrtUploads }) => {
  const handleDeleteUpload = async () => {
    try {
      await deletePrtUploadById({ uploadId });
      const prtUploadIds = JSON.parse(localStorage.getItem("prtUploadIds"));
      const indexOfDeletedUpload = prtUploadIds.indexOf(uploadId);
      prtUploadIds.splice(indexOfDeletedUpload, 1);
      localStorage.setItem("prtUploadIds", JSON.stringify(prtUploadIds));

      fetchPrtUploads();
      // eslint-disable-next-line no-undef
      $(`.bs-example-modal-center${uploadId}`).modal("hide");
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      <i
        className="ion ion-md-trash delete-icon"
        data-toggle="modal"
        data-target={`.bs-example-modal-center${uploadId}`}
        style={{ cursor: "pointer" }}
      ></i>
      <div
        id="modal"
        className={`modal show bs-example-modal-center${uploadId}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mt-0 text-center">
                Are you sure want to delete this file upload{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-footer d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light"
                onClick={handleDeleteUpload}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeletePrtUploadEntry;
