import React, { useState } from "react";
import getOrganizationMembersByOrganizationId from "services/getOrganizationMembersByOrganizationId";
import useAPI from "effects/useAPI";
import SendInvitationModal from "./components/SendInvitationModal";
import getUserInvitations from "services/Organizations/getUserInvitations";
import InvitationActionModal from "./components/InvitationActionModal";
import Authentication from "services/Authentication";
import RemoveOrganizationUserModal from "./components/RemoveOrganizationUserModal";

let auth = new Authentication();

const TeamManagement = () => {
  const jwtPayload = auth.getAccessTokenPayload();
  const [
    loadingOrganizationMembers,
    errorOrganizationMembers,
    organizationMembersResponse,
    fetchAllOrganizationMembers,
  ] = useAPI(() => getOrganizationMembersByOrganizationId());

  const [
    loadingUserInvitations,
    errorUserInvitations,
    userInvitationsResponse,
    fetchAllUserInvitations,
  ] = useAPI(() => getUserInvitations());

  const { organizationMembers } = organizationMembersResponse;
  const { invitations } = userInvitationsResponse;
  const [openInvitationModal, setOpenInvitationModal] = useState(false);
  const [openInvitationActionModal, setOpenInvitationActionModal] =
    useState(false);
  const [invitationPayload, setInvitationPayload] = useState({});
  const [removeOrganizationUserModal, setRemoveOrganizationUserModal] =
    useState(false);
  const [organizationUserToRemove, setOrganizationUserToRemove] =
    useState(null);

  const renderOrganizationInviteButton = () => {
    if (loadingOrganizationMembers) {
      return "Loading user invitations...";
    }

    if (errorOrganizationMembers) {
      return "Error loading user invitations";
    }

    if (organizationMembers[0]?.organizationName) {
      return (
        <div className="d-flex justify-content-center flex-column align-items-center">
          <h2 className="ml-3 text-center mb-4">
            {organizationMembers[0]?.organizationName}
          </h2>
          {organizationMembers.some(
            (member) =>
              member.userId === jwtPayload.userId && member.userRoleId === 1
          ) && (
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-success waves-effect waves-light"
                onClick={() => setOpenInvitationModal(true)}
              >
                Send Organization Invite
              </button>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const renderInvitations = () => {
    if (loadingUserInvitations) {
      return "Loading user invitations...";
    }

    if (errorUserInvitations) {
      return "Error loading user invitations";
    }

    if (invitations?.length === 0) {
      return <h3 className="text-center">You don't have any invitations...</h3>;
    }

    if (invitations?.length !== 0) {
      return (
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Invited By</th>
              <th scope="col">Message</th>
              <th scope="col">Organization</th>
              <th scope="col">User Role</th>
              <th scope="col">Send At</th>
              <th scope="col" className="text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {organizationMembers?.length === 0 ? (
              errorOrganizationMembers ? (
                <h2 className="ml-5">Error loading team members</h2>
              ) : loadingOrganizationMembers ? (
                <h2 className="ml-5">Loading team members...</h2>
              ) : (
                <h2 className="ml-5">No team members...</h2>
              )
            ) : (
              invitations?.map((invitation) => {
                return (
                  <tr key={invitation.userOrganizationInvitationId}>
                    <td>
                      {invitation.invitedByFirstName}{" "}
                      {invitation.invitedByLastName}
                    </td>
                    <td>{invitation.message}</td>
                    <td>{invitation?.organizationName}</td>
                    <td>{invitation.userRole}</td>
                    <td>{invitation.sentAt}</td>
                    <td>
                      {invitation.acceptedAt !== null ? (
                        "Accepted"
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          <button
                            className="btn btn-success waves-effect waves-light mr-2"
                            onClick={() => {
                              setOpenInvitationActionModal(true);
                              setInvitationPayload({
                                invitationShortcode:
                                  invitation.invitationShortcode,
                                userOrganizationInvitationId:
                                  invitation.userOrganizationInvitationId,
                                functionToUse: "acceptInvitation",
                              });
                            }}
                          >
                            Accept
                          </button>
                          <button
                            className="btn btn-danger waves-effect waves-light"
                            onClick={() => {
                              setOpenInvitationActionModal(true);
                              setInvitationPayload({
                                invitationShortcode:
                                  invitation.invitationShortcode,
                                userOrganizationInvitationId:
                                  invitation.userOrganizationInvitationId,
                                functionToUse: "declineInvitation",
                              });
                            }}
                          >
                            Decline
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      );
    }

    return "No user invitations...";
  };

  const renderOrganizationMembers = () => {
    if (loadingOrganizationMembers) {
      return <h2 className="ml-5">Loading team members...</h2>;
    }

    if (errorOrganizationMembers) {
      return <h2 className="ml-5">Error loading team members</h2>;
    }

    if (organizationMembers?.length === 0) {
      return <h2 className="ml-5">No team members...</h2>;
    }

    if (organizationMembers?.length !== 0) {
      return organizationMembers?.map((member) => {
        return (
          <tr key={member.userId}>
            <td>
              {member.firstName} {member.lastName}
            </td>
            <td>{member.email}</td>
            {organizationMembers?.some(
              (member) =>
                member.userId === jwtPayload.userId && member.userRoleId === 1
            ) && (
              <td>
                {member.userId === jwtPayload.userId ? (
                  ""
                ) : (
                  <button
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => {
                      setRemoveOrganizationUserModal(true);
                      setOrganizationUserToRemove(member.userId);
                    }}
                  >
                    Remove
                  </button>
                )}
              </td>
            )}
          </tr>
        );
      });
    }
  };

  const renderTable = () => {
    return (
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              {organizationMembers?.some(
                (member) =>
                  member.userId === jwtPayload.userId && member.userRoleId === 1
              ) && <th scope="col">Action</th>}
            </tr>
          </thead>
          <tbody>{renderOrganizationMembers()}</tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <div
        className="row cardss"
        style={{ display: "flex", justifyContent: "center", marginTop: "8em" }}
      >
        <h1 className="mb-5 d-flex justify-content-center w-100">
          My Organization
        </h1>
      </div>
      <div
        className="row cardss"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-xl-9">
          <div className="card" style={{ marginBottom: "100px" }}>
            {renderOrganizationInviteButton()}
            <div className="card-body">
              <h4 className="mt-0 header-title mb-4 text-center">
                Organization Invitations
              </h4>
              <div className="table-responsive">{renderInvitations()}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row cardss"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-xl-9">
          <div className="card" style={{ marginBottom: "100px" }}>
            <div className="card-body">
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="mt-0 header-title mb-4">All Team Members</h4>
                </div>
              </div>
              <div>{renderTable()}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        {openInvitationActionModal && (
          <div className="modal-overlay">
            <div className="notify-modal-content">
              <InvitationActionModal
                setOpenInvitationActionModal={setOpenInvitationActionModal}
                invitationPayload={invitationPayload}
                fetchAllUserInvitations={fetchAllUserInvitations}
              />
            </div>
          </div>
        )}
        {openInvitationModal && (
          <div className="modal-overlay">
            <div className="notify-modal-content">
              <SendInvitationModal
                setOpenInvitationModal={setOpenInvitationModal}
              />
            </div>
          </div>
        )}
        {removeOrganizationUserModal && (
          <div className="modal-overlay">
            <div className="notify-modal-content">
              <RemoveOrganizationUserModal
                setRemoveOrganizationUserModal={setRemoveOrganizationUserModal}
                fetchAllOrganizationMembers={fetchAllOrganizationMembers}
                userId
                organizationUserToRemove={organizationUserToRemove}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamManagement;
