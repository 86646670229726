import * as yup from "yup";

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
    .label("Email")
    .typeError("Email must be a valid email address."),
  password: yup
    .string()
    .label("Password")
    .required()
    .typeError("Please fill in the password field"),
});

export default loginSchema;
