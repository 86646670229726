import * as yup from "yup";
// import toolHolderInputTypes from "constants/toolHolderInputTypes";
// import toolInputTypes from "constants/toolInputTypes";

const cuttingToolSchema = yup.object().shape({
  // operationName: yup
  //   .string()
  //   .required()
  //   .label("Operation Name")
  //   .typeError("Operation Name must be a url."),
  // toolHolderName: yup
  //   .string()
  //   .required()
  //   .label("Tool Holder Name")
  //   .typeError("Tool Holder Name must be a text."),
  // toolHolderDataInputSelection: yup
  //   .string()
  //   .required()
  //   .label("Tool Holder Input Radio Button")
  //   .typeError(
  //     "Radio button output expects either of the following: choice url/iso"
  //   ),
  // toolHolderUrl: yup
  //   .string()
  //   .label("Tool Holder Url")
  //   .typeError("Tool Holder Url must be a valid url.")
  //   .when("toolHolderDataInputSelection", {
  //     is: toolHolderInputTypes.URL,
  //     then: yup.string().required(),
  //   }),
  // toolHolderIsoNo: yup
  //   .string()
  //   .label("Tool Holder Iso No")
  //   .typeError("Tool Holder Iso No must be a string.")
  //   .when("toolHolderDataInputSelection", {
  //     is: toolHolderInputTypes.ISO,
  //     then: yup.string().required(),
  //   }),
  // cuttingToolName: yup
  //   .string()
  //   .required()
  //   .label("Cutting Tool Name")
  //   .typeError("Cutting Tool Name is a required field."),
  // cuttingToolNumber: yup
  //   .number()
  //   .required()
  //   .label("Cutting Tool Number")
  //   .typeError("Cutting Tool Number must be a number."),
  // toolDataInputSelection: yup
  //   .string()
  //   .required()
  //   .label("Tool Data Input Radio Button")
  //   .typeError(
  //     "Radio button output expects either of the following choice: url/iso/datasheet/manual"
  //   ),
  // toolUrl: yup
  //   .string()
  //   .label("Tool URL")
  //   .typeError("Tool URL Id must be a valid url.")
  //   .when("toolDataInputSelection", {
  //     is: toolInputTypes.URL,
  //     then: yup.string().required(),
  //   }),
  // toolIsoNo: yup
  //   .string()
  //   .label("Tool Iso Number")
  //   .typeError("Tool Iso Number must be a string.")
  //   .when("toolDataInputSelection", {
  //     is: toolInputTypes.ISO,
  //     then: yup.string().required(),
  //   }),
  // toolDatasheet: yup
  //   .string()
  //   .label("Tool Datasheet")
  //   .typeError("Dimensional Tolerance must be a number.")
  //   .when("toolDataInputSelection", {
  //     is: toolInputTypes.DATASHEET,
  //     then: yup.string().required(),
  //   }),
  // toolParameters: yup
  //   .object()
  //   .label("Tool Parameters")
  //   .typeError("Tool Parameters must be an object.")
  //   .when("toolDataInputSelection", {
  //     is: toolInputTypes.MANUAL,
  //     then: yup.object().shape({
  //       cuttingToolType: yup
  //         .number()
  //         .typeError("Cutting Tool Type Upload must be a number.")
  //         .required(),
  //       // cuttingTool: yup
  //       //   .number()
  //       //   .typeError("Cutting Tool Upload must be a number.")
  //       //   .required(),
  //       diameter: yup
  //         .number()
  //         .typeError("Cutting Tool must be a number.")
  //         .required(),
  //       cornerRadius: yup
  //         .number()
  //         .typeError("Corner Radius must be a number.")
  //         .required(),
  //       taperAngle: yup
  //         .number()
  //         .typeError("Taper Angle must be a number.")
  //         .required(),
  //       tipAngle: yup
  //         .number()
  //         .typeError("Tip Angle must be a number.")
  //         .required(),
  //       leadDistance: yup
  //         .number()
  //         .typeError("Lead Distance must be a number.")
  //         .required(),
  //       toolLength: yup
  //         .number()
  //         .typeError("Tool Length must be a number.")
  //         .required(),
  //       stickOutLength: yup
  //         .number()
  //         .typeError("Stick-Out Length must be a number.")
  //         .required(),
  //       fluteLength: yup
  //         .number()
  //         .typeError("Flute Length Upload must be a number.")
  //         .required(),
  //       noOfFlutes: yup
  //         .number()
  //         .typeError("No of Flutes must be a number.")
  //         .required(),
  //       helixOption: yup
  //         .string()
  //         .typeError("You need to select a helix angle arrangement"),
  //       pitchOption: yup
  //         .string()
  //         .typeError("You need to select a helix angle arrangement"),
  //       rakeOption: yup
  //         .string()
  //         .typeError("You need to select a helix angle arrangement"),
  //       helixAngle: yup
  //         .array()
  //         .typeError("Helix angle must be a number.")
  //         .when("helixOption", {
  //           is: "1",
  //           then: yup
  //             .array()
  //             .typeError("Helix angle must be a number.")
  //             .test(
  //               "hasHelixAngleArrayGotEnoughEntries",
  //               `Helix Angle should have 1 entry to represent constant`,
  //               function test(helixAngle) {
  //                 const nonEmptyHelixAngles = helixAngle?.filter((angle) => {
  //                   return angle !== "";
  //                 });
  //                 if (nonEmptyHelixAngles?.length === 1) {
  //                   return true;
  //                 }
  //                 return false;
  //               }
  //             )
  //             .required(),
  //           // eslint-disable-next-line
  //           is: "2",
  //           // eslint-disable-next-line
  //           then: yup
  //             .array()
  //             .typeError("Helix angle must be a number.")
  //             .test(
  //               "hasHelixAngleArrayGotEnoughEntries",
  //               `Helix Angle should have enough entries to match No of Flutes`,
  //               function test(helixAngle) {
  //                 const nonEmptyHelixAngles = helixAngle?.filter((angle) => {
  //                   return angle !== "";
  //                 });
  //                 if (nonEmptyHelixAngles?.length === this.parent.noOfFlutes) {
  //                   return true;
  //                 }
  //                 return false;
  //               }
  //             )
  //             .required(),
  //         }),
  //       pitchAngle: yup
  //         .array()
  //         .typeError("Pitch Angle Upload must be a array.")
  //         .when("pitchOption", {
  //           is: "1",
  //           then: yup
  //             .array()
  //             .typeError("Pitch angle must be a number.")
  //             .test(
  //               "hasPitchAngleArrayGotEnoughEntries",
  //               `Pitch Angle should have 0 entry to represent constant`,
  //               function test(pitchAngle) {
  //                 const nonEmptyPitchAngles = pitchAngle?.filter((angle) => {
  //                   return angle !== "";
  //                 });
  //                 if (nonEmptyPitchAngles?.length === 0) {
  //                   return true;
  //                 }
  //                 return false;
  //               }
  //             ),
  //           // eslint-disable-next-line
  //           is: "2",
  //           // eslint-disable-next-line
  //           then: yup
  //             .array()
  //             .typeError("Pitch angle must be a number.")
  //             .test(
  //               "hasPitchAngleArrayGotEnoughEntries",
  //               `Pitch Angle should have enough entries to match No of Flutes`,
  //               function test(pitchAngle) {
  //                 const nonEmptyPitchAngles = pitchAngle?.filter((angle) => {
  //                   return angle !== "";
  //                 });
  //                 if (nonEmptyPitchAngles?.length === this.parent.noOfFlutes) {
  //                   return true;
  //                 }
  //                 return false;
  //               }
  //             )
  //             .required(),
  //         }),
  //       rakeAngle: yup
  //         .array()
  //         .typeError("Rake Angle must be a number.")
  //         .when("rakeOption", {
  //           is: "1",
  //           then: yup
  //             .array()
  //             .typeError("Rake Angle must be a number.")
  //             .test(
  //               "hasRakeAngleArrayGotEnoughEntries",
  //               `Rake Angle should have 1 entry to represent constant`,
  //               function test(rakeAngle) {
  //                 const nonEmptyRakeAngles = rakeAngle?.filter((angle) => {
  //                   return angle !== "";
  //                 });
  //                 if (nonEmptyRakeAngles?.length === 1) {
  //                   return true;
  //                 }
  //                 return false;
  //               }
  //             )
  //             .required(),
  //           // eslint-disable-next-line
  //           is: "2",
  //           // eslint-disable-next-line
  //           then: yup
  //             .array()
  //             .typeError("Rake Angle must be a array.")
  //             .test(
  //               "hasRakeAngleArrayGotEnoughEntries",
  //               `Rake Angle should have enough entries to match No of Flutes`,
  //               function test(rakeAngle) {
  //                 const nonEmptyRakeAngles = rakeAngle?.filter((angle) => {
  //                   return angle !== "";
  //                 });
  //                 if (nonEmptyRakeAngles?.length === this.parent.noOfFlutes) {
  //                   return true;
  //                 }
  //                 return false;
  //               }
  //             )
  //             .required(),
  //         }),
  //     }),
  //   }),
  // cuttingToolFRFxxUpload: yup
  //   .string()
  //   .label("Cutting Tool FRFxx Upload")
  //   .typeError("Cutting Tool FRFxx Upload must be a text."),
  // cuttingToolFRFyyUpload: yup
  //   .string()
  //   .label("Cutting Tool FRFyy Upload")
  //   .typeError("Cutting Tool FRFyy must be a text."),
});

export default cuttingToolSchema;
