import React, { useEffect } from "react";
import { useParams } from "react-router";
import patchApproveJoinTeamRequestStatusByOrgId from "services//patchApproveJoinTeamRequestStatusByOrgId";

const ApproveTeamJoinRequest = () => {
  const { shortcode } = useParams();

  useEffect(() => {
    patchApproveJoinTeamRequestStatusByOrgId({
      shortcode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <img className="login-logo" src="assets/images/ll2.png" alt="logo" />
      <div
        className="mt-5"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="col-lg-5 mt-5">
          <div className="card mb-0 mt-5">
            <div className="card-body">
              <div className="mb-4 text-center">
                <h3 className="mb-0" style={{ fontFamily: "Poppins" }}>
                  Join Request Approved
                </h3>
                <p
                  className="my-2 mt-4"
                  style={{ fontFamily: "Montserrat", fontSize: "16px" }}
                >
                  You have Successfully Approved the Join Request!{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApproveTeamJoinRequest;
