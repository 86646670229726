import React from "react";
import { useSubscription } from "./subscriptionContext";
import { Redirect } from "react-router-dom";
import Authentication from "services/Authentication";
let auth = new Authentication();

const SubscriptionCheck = ({ children, subscriptionNames }) => {
  const subscription = useSubscription();
  const { subscribedProduct, subscriptionStatus } = subscription;
  const jwtPayload = auth.getAccessTokenPayload();

  if (subscribedProduct === undefined && jwtPayload.userTypeId !== 1) {
    return (
      <div className="spinner-centered ">
        <i className="fa fa-spinner fa-spin fa-2x fa-fw"></i>
      </div>
    );
  }

  if (
    (subscriptionNames.includes(subscribedProduct) &&
      subscriptionStatus !== "canceled") ||
    jwtPayload.userTypeId === 1
  ) {
    return children;
  } else {
    return <Redirect to="/" />;
  }
};

export default SubscriptionCheck;
