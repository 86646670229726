import http from "utils/http";
import { cuttingTools } from "constants/endpoints";

const getSessionCuttingToolByIds = ({ cuttingToolIds }) => {
  const ENDPOINT = cuttingTools.getSessionCuttingToolByIds({
    cuttingToolIds,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`).then((res) => {
    const dataMapped = res.data.cuttingTools.map((tool) => {
      return {
        ...tool,
        cuttingToolFRFxxUpload: tool.cuttingToolFrFxxUpload,
        cuttingToolFRFyyUpload: tool.cuttingToolFrFyyUpload,
      };
    });
    const x = { ...res, data: { cuttingTools: dataMapped } };
    return x;
  });
};

export default getSessionCuttingToolByIds;
