import http from "utils/http";
import { predictiveMaintenance } from "constants/endpoints";

const getMachineByMachineId = ({ machineId }) => {
  const ENDPOINT = predictiveMaintenance.getMachineByMachineId({
    machineId,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`);
};

export default getMachineByMachineId;
