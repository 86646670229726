import React, { useState, useEffect } from "react";
import "./index.css";
import NotifyMeModal from "pages/Admin/components/NotifyMeModal";
import EssentialOptimizationModal from "pages/Dashboard/components/essentialOptimizationModal";
// import SubscriptionModal from "../Subscription/components/SubscriptionModal";
import { useSubscription } from "../../context/subscriptionContext";
import { useHistory } from "react-router-dom";
import isSubscriptionActive from "utils/isSubscriptionActive";
import Authentication from "services/Authentication";

let auth = new Authentication();

const Dashboard = ({ enlarged }) => {
  const history = useHistory();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [openEssentialOptimizationModal, setOpenEssentialOptimizationModal] =
    useState(false);
  const [selectedEssentialOptimization, setSelectedEssentialOptimization] =
    useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  //   const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const subscription = useSubscription();

  const jwtPayload = auth.getAccessTokenPayload();
  const { subscribedProduct } = subscription;

  useEffect(() => {
    if (isSubscriptionActive(subscription) || jwtPayload.userTypeId === 1) {
      history.push(selectedEssentialOptimization);
    } else {
      history.push("/");
      //   setShowSubscriptionModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEssentialOptimization]);

  //   const handleOpenEssentialOptimizationModal = () => {
  //     if (
  //       (isSubscriptionActive(subscription) &&
  //         subscribedProduct === "ESSENTIAL OPTIMIZER") ||
  //       jwtPayload.userTypeId === 1
  //     ) {
  //       setOpenEssentialOptimizationModal(true);
  //     } else {
  //       setShowSubscriptionModal(true);
  //     }
  //   };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenModal = (productName) => {
    setSelectedProduct(productName);
    setOpenNotificationModal(true);
  };

  return (
    <>
      <div
        className={
          windowWidth <= 768 ? "d-flex flex-column " : "d-flex flex-column "
        }
        style={{
          marginTop: windowWidth <= 768 ? "80px" : "5rem",
          marginBottom: windowWidth <= 768 ? "80px" : "5rem",
          marginLeft: enlarged && "180px",
        }}
      >
        <div className="mb-3 ml-1 d-flex align-items-center justify-content-center flex-column">
          <h2 style={{ fontSize: "28px" }}>
            Reach the best part, faster right first time.
          </h2>
          <h6 style={{ fontSize: "18px" }}>
            Try our apps. Start your 14-day free trial.
          </h6>
          {((subscribedProduct === "ESSENTIAL OPTIMIZER" ||
            subscribedProduct === "FEEDS AND ESSENTIAL OPTIMIZER" ||
            subscribedProduct === "FINESSE AND ESSENTIAL OPTIMIZER") &&
            isSubscriptionActive(subscription)) ||
          jwtPayload.userTypeId === 1 ? (
            <div
              className="essential-optimization-card m-4"
              style={{ backgroundColor: "#f6f7fc" }}
            >
              <div className="card-body">
                <p className="card-heading mt-2">
                  Welcome to Essential Optimizer
                </p>
                <p style={{ fontSize: "16px" }}>
                  1.- Start with our Spindle Speed Optimizer to get your
                  recommended spindle speed and depth of cut.
                </p>
                <p style={{ fontSize: "16px" }}>
                  2.-Input these into Feed Rate Optimizer to predict cutting
                  forces and identify optimum feed rate based on the cutting
                  force constraints.
                </p>
              </div>
            </div>
          ) : ((subscribedProduct === "FEEDRATE OPTIMIZER" ||
              subscribedProduct === "FEEDS AND FEEDRATE OPTIMIZER" ||
              subscribedProduct === "FINESSE AND FEEDRATE OPTIMIZER") &&
              isSubscriptionActive(subscription)) ||
            jwtPayload.userTypeId === 1 ? (
            <div
              className="essential-optimization-card m-4"
              style={{ backgroundColor: "#f6f7fc" }}
            >
              <div className="card-body">
                <p className="card-heading mt-2" style={{ fontSize: "28px" }}>
                  Welcome to Feed Rate Optimizer
                </p>
                <p style={{ fontSize: "16px" }}>
                  Access the Feed Rate Optimizer app below and start optimizing
                  your machine settings.
                </p>
              </div>
            </div>
          ) : (
            <div className="digibasic-card m-4">
              <div className="card-body m-3">
                <p className="card-heading mt-2" style={{ fontSize: "28px" }}>
                  TapStarter - Launch Bundle
                </p>
                <p style={{ fontSize: "16px" }}>
                  Get free tap test training plus one year's subscription to our
                  Essential Optimizer package when you buy the tap test hardware
                  kit and DigiTap software.
                </p>
                <h4>£9,750</h4>
                <p className="mt-4" style={{ textDecoration: "underline" }}>
                  <button
                    className="btn waves-effect waves-light about-this-promotion-button"
                    onClick={() =>
                      handleOpenModal("Tap Starter - Launch Bundle")
                    }
                  >
                    Enquire about this promotion
                  </button>
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className={
            windowWidth <= 768
              ? "d-flex flex-column align-items-center"
              : "d-flex flex-row justify-content-center align-items-center"
          }
        >
          <div
            className={
              ((subscribedProduct === "ESSENTIAL OPTIMIZER" ||
                subscribedProduct === "FEEDS AND ESSENTIAL OPTIMIZER" ||
                subscribedProduct === "FINESSE AND ESSENTIAL OPTIMIZER") &&
                isSubscriptionActive(subscription)) ||
              jwtPayload.userTypeId === 1
                ? "dashboard-card-active"
                : "dashboard-card"
            }
            style={{
              backgroundColor:
                ((subscribedProduct === "ESSENTIAL OPTIMIZER" ||
                  subscribedProduct === "FEEDS AND ESSENTIAL OPTIMIZER" ||
                  subscribedProduct === "FINESSE AND ESSENTIAL OPTIMIZER") &&
                  isSubscriptionActive(subscription)) ||
                jwtPayload.userTypeId === 1
                  ? ""
                  : "#f6f7fc",
            }}
          >
            <div className="card-body">
              <p className="card-heading" style={{ fontSize: "28px" }}>
                Predictive Maintenance
              </p>
              <p style={{ fontSize: "16px", marginTop: "18px" }}>
                Inspect the <strong>health of your machine tool spindle</strong>{" "}
                to extend its life and prevent downtime (requires FRF data).
              </p>
              {((subscribedProduct === "ESSENTIAL OPTIMIZER" ||
                subscribedProduct === "FEEDS AND ESSENTIAL OPTIMIZER" ||
                subscribedProduct === "FINESSE AND ESSENTIAL OPTIMIZER") &&
                isSubscriptionActive(subscription)) ||
              jwtPayload.userTypeId === 1 ? (
                <div style={{ marginTop: "3.3rem", marginLeft: "1.2rem" }}>
                  <button
                    style={{ backgroundColor: "#ffffff", color: "#2250fb" }}
                    className="btn btn-primary waves-effect waves-light get-notified-button mb-3"
                    onClick={() =>
                      setSelectedEssentialOptimization(
                        "/predictive-maintenance"
                      )
                    }
                  >
                    Access App
                  </button>
                </div>
              ) : (
                <>
                  <button
                    className="btn btn-primary waves-effect waves-light get-notified-button mb-3"
                    style={{
                      marginTop: "5.15rem",
                    }}
                    onClick={() => handleOpenModal("PREDICTIVE MAINTENANCE")}
                  >
                    Become an Early Adopter
                  </button>
                </>
              )}
            </div>
          </div>
          <div
            className={
              ((subscribedProduct === "FEEDRATE OPTIMIZER" ||
                subscribedProduct === "ESSENTIAL OPTIMIZER" ||
                subscribedProduct === "FEEDS AND FEEDRATE OPTIMIZER" ||
                subscribedProduct === "FEEDS AND ESSENTIAL OPTIMIZER" ||
                subscribedProduct === "FINESSE AND FEEDRATE OPTIMIZER" ||
                subscribedProduct === "FINESSE AND ESSENTIAL OPTIMIZER") &&
                isSubscriptionActive(subscription)) ||
              jwtPayload.userTypeId === 1
                ? "dashboard-card-active"
                : "dashboard-card"
            }
            style={{
              backgroundColor:
                ((subscribedProduct === "FEEDRATE OPTIMIZER" ||
                  subscribedProduct === "ESSENTIAL OPTIMIZER" ||
                  subscribedProduct === "FEEDS AND FEEDRATE OPTIMIZER" ||
                  subscribedProduct === "FEEDS AND ESSENTIAL OPTIMIZER" ||
                  subscribedProduct === "FINESSE AND FEEDRATE OPTIMIZER" ||
                  subscribedProduct === "FINESSE AND ESSENTIAL OPTIMIZER") &&
                  isSubscriptionActive(subscription)) ||
                jwtPayload.userTypeId === 1
                  ? ""
                  : "#f6f7fc",
            }}
          >
            <div className="card-body">
              <p className="card-heading">Feed Rate Optimizer</p>
              <p style={{ fontSize: "16px" }}>
                Apply <strong>optimal feed</strong> rate to{" "}
                <strong>optimize</strong> the milling process{" "}
                <strong>considering cutting forces</strong>
              </p>
              {((subscribedProduct === "FEEDRATE OPTIMIZER" ||
                subscribedProduct === "ESSENTIAL OPTIMIZER" ||
                subscribedProduct === "FEEDS AND FEEDRATE OPTIMIZER" ||
                subscribedProduct === "FEEDS AND ESSENTIAL OPTIMIZER" ||
                subscribedProduct === "FINESSE AND FEEDRATE OPTIMIZER" ||
                subscribedProduct === "FINESSE AND ESSENTIAL OPTIMIZER") &&
                isSubscriptionActive(subscription)) ||
              jwtPayload.userTypeId === 1 ? (
                <div
                  className="d-flex justify-content-around"
                  style={{ marginTop: "5.3rem" }}
                >
                  <button
                    style={{ backgroundColor: "#ffffff", color: "#2250fb" }}
                    className="btn btn-primary waves-effect waves-light get-notified-button mb-3"
                    onClick={() =>
                      setSelectedEssentialOptimization("/digi-basic")
                    }
                  >
                    Access App
                  </button>
                  <img
                    style={{ width: "30%", height: "30%" }}
                    className="mt-3"
                    alt="Feed Rate Optimizer"
                    src="assets/images/FeedRateCalculatorFR.png"
                  />
                </div>
              ) : (
                <>
                  <p
                    style={{
                      fontSize: "22px",
                      marginBottom: "0px",
                      marginTop: "62px",
                    }}
                  >
                    £49/month
                  </p>
                  <p>(when billed annually)</p>
                  {/* <button
                    className="btn btn-primary waves-effect waves-light get-notified-button mb-3"
                    style={{
                      marginTop: "0rem",
                    }}
                    onClick={() =>
                      setSelectedEssentialOptimization("/digi-basic")
                    }
                  >
                    Try it Now For Free
                  </button> */}
                </>
              )}
            </div>
          </div>
          <div
            className={
              ((subscribedProduct === "ESSENTIAL OPTIMIZER" ||
                subscribedProduct === "FEEDS AND ESSENTIAL OPTIMIZER" ||
                subscribedProduct === "FINESSE AND ESSENTIAL OPTIMIZER") &&
                isSubscriptionActive(subscription)) ||
              jwtPayload.userTypeId === 1
                ? "dashboard-card-active"
                : "dashboard-card"
            }
            style={{
              backgroundColor:
                ((subscribedProduct === "ESSENTIAL OPTIMIZER" ||
                  subscribedProduct === "FEEDS AND ESSENTIAL OPTIMIZER" ||
                  subscribedProduct === "FINESSE AND ESSENTIAL OPTIMIZER") &&
                  isSubscriptionActive(subscription)) ||
                jwtPayload.userTypeId === 1
                  ? ""
                  : "#f6f7fc",
            }}
          >
            <div className="card-body">
              <div className="">
                {((subscribedProduct === "ESSENTIAL OPTIMIZER" ||
                  subscribedProduct === "FEEDS AND ESSENTIAL OPTIMIZER" ||
                  subscribedProduct === "FINESSE AND ESSENTIAL OPTIMIZER") &&
                  isSubscriptionActive(subscription)) ||
                jwtPayload.userTypeId === 1 ? (
                  <>
                    <p className="card-heading">Spindle Speed Optimizer</p>
                    <p style={{ fontSize: "16px" }}>
                      Apply optimal <strong>Feed Rates</strong> and{" "}
                      <strong>Spindle Speed </strong> to achieve the{" "}
                      <strong>fastest chatter-free results</strong> (requires
                      FRF data).
                    </p>
                    <div
                      className="d-flex justify-content-around"
                      style={{ marginTop: "61px" }}
                    >
                      <button
                        className="btn btn-primary waves-effect waves-light get-notified-button mb-3"
                        onClick={() =>
                          setSelectedEssentialOptimization("/digi-stability")
                        }
                        style={{ backgroundColor: "#ffffff", color: "#2250fb" }}
                      >
                        Access App
                      </button>
                      <img
                        style={{ width: "30%", height: "30%" }}
                        className="mt-3"
                        alt="Spindle Speed Optimizer"
                        src="assets/images/SpindleSpeedCalculatorSS.png"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <p className="card-heading">Essential Optimizer</p>
                    <p style={{ fontSize: "16px", marginTop: "54px" }}>
                      Apply <strong>optimal Feed Rate</strong> and{" "}
                      <strong>Spindle Speed</strong> to achieve the{" "}
                      <strong>fastest chatter-free results</strong> (requires
                      FRF data).
                    </p>
                    <div>
                      <p
                        style={{
                          fontSize: "22px",
                          marginBottom: "0px",
                          marginTop: "39px",
                        }}
                      >
                        £149/month
                      </p>
                      <p>(when billed annually)</p>
                    </div>
                    {/* <button
                      className="btn btn-primary waves-effect waves-light get-notified-button"
                      onClick={handleOpenEssentialOptimizationModal}
                      style={{
                        marginTop: "0rem",
                      }}
                    >
                      Try it Now For Free
                    </button> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          {openNotificationModal && (
            <div className="modal-overlay">
              <div className="notify-modal-content">
                <NotifyMeModal
                  setOpenNotificationModal={setOpenNotificationModal}
                  productName={selectedProduct}
                />
              </div>
            </div>
          )}
          {openEssentialOptimizationModal && (
            <div className="modal-overlay">
              <div className="notify-modal-content">
                <EssentialOptimizationModal
                  setOpenEssentialOptimizationModal={
                    setOpenEssentialOptimizationModal
                  }
                  setSelectedEssentialOptimization={
                    setSelectedEssentialOptimization
                  }
                />
              </div>
            </div>
          )}
        </div>
        {/* {showSubscriptionModal && (
          <SubscriptionModal
            showSubscriptionModal={showSubscriptionModal}
            setShowSubscriptionModal={setShowSubscriptionModal}
          />
        )} */}
      </div>
    </>
  );
};

export default Dashboard;
