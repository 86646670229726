import http from "utils/http";
import { organizations } from "constants/endpoints";

const getUserInvitations = () => {
  const ENDPOINT = organizations.getUserInvitations();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`);
};

export default getUserInvitations;
