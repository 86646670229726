import http from "utils/http";
import { digiBasic } from "constants/endpoints";

const postDigiBasicToolData = ({
  digibasicToolTypeId,
  diameter,
  fluteLength,
  numberOfFlutes,
  fluteParameters,
  cuttingParameters,
}) => {
  const POST_USER_ENDPOINT = digiBasic.postDigiBasicToolData();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_USER_ENDPOINT}`, {
    data: {
      digibasicToolTypeId,
      diameter,
      fluteLength,
      numberOfFlutes,
      fluteParameters,
      cuttingParameters,
    },
  });
};

export default postDigiBasicToolData;
