import * as yup from "yup";

const toolingSchema = yup.object().shape({
  digibasicToolTypeId: yup
    .number()
    .required()
    .label("Digibasic Tool Type Id")
    .typeError("Tool type id should be a number"),
  diameter: yup
    .number()
    .min(0.1, "Diameter value cannot be less than 0.1mm")
    .max(200, "Diameter value cannot be bigger than 200mm")
    .required()
    .positive()
    .min(1)
    .max(200)
    .label("Diameter")
    .typeError("Diameter should be a number"),
  fluteLength: yup
    .number()
    .min(0.1, "Minimum flute length is 0.1")
    .max(150, "Maximum flute length is 150")
    .required()
    .label("Flute Length")
    .typeError("Flute Length cannot be empty"),
  fluteParameters: yup.object().shape({
    noOfFlutes: yup
      .number()
      .integer("Should be positive digit number")
      .min(1)
      .required()
      .label("Number Of Flutes")
      .typeError("Number of flutes cannot be empty")
      .min(1)
      .max(10),
    helixOption: yup
      .number()
      .min(1, "Should be a minimum 1")
      .max(2, "Should be a maximum 2")
      .required()
      .label("Helix Option")
      .typeError("Helix Option cannot be empty")
      .test(
        "Is helix value right",
        "Helix values cannot be empty",
        function test() {
          const { helixOption, helixAngle } = this.parent;
          if (helixOption === 1) {
            const nonEmptyHelixAngles = helixAngle.filter((angle) => {
              return angle !== null && angle !== "";
            });
            return nonEmptyHelixAngles.length === 1;
          }
          if (helixOption === 2) {
            const nonEmptyHelixAngles = helixAngle.filter((angle) => {
              return angle !== null && angle !== "";
            });
            return nonEmptyHelixAngles.length === this.parent.noOfFlutes;
          }
          return false;
        }
      ),
    pitchOption: yup
      .number()
      .min(1)
      .required()
      .label("Pitch Option")
      .typeError("Pitch Option cannot be empty")
      .test(
        "positive",
        "You have an error in your pitch values",
        function test() {
          const { pitchOption, pitchAngle } = this.parent;
          if (pitchOption === 1) {
            const nonEmptyPitchAngles = pitchAngle.filter((angle) => {
              return angle !== null && angle !== "";
            });
            // TODO: When user selecting variable option and after it selecting constant, empty array is returning.
            return (
              nonEmptyPitchAngles.length === 1 ||
              nonEmptyPitchAngles.length === 0
            );
          }
          if (pitchOption === 2) {
            const nonEmptyPitchAngles = pitchAngle.filter((angle) => {
              return angle !== null && angle !== "";
            });
            return nonEmptyPitchAngles.length === this.parent.noOfFlutes;
          }
          return false;
        }
      ),
    rakeAngle: yup
      .number()
      .min(-90)
      .max(90)
      .required()
      .label("Rake Angel")
      .typeError("Rake Angle cannot be empty"),
  }),
});

export default toolingSchema;
