import * as yup from "yup";

const confirmForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
    .label("Email")
    .typeError("Email must be a valid email address."),
  code: yup.string().min(6).max(6).required().label("Confirmation Code"),
  newPassword: yup
    .string()
    .min(8, "Password must be at least 8 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must include at least one capital letter, one number, and one special symbol. Allowed special symbols are: @, $, !, %, *, ?, &"
    )
    .required("Password is required.")
    .label("Password")
    .typeError("Password must be a string."),
});

export default confirmForgotPasswordSchema;
