import http from "utils/http";
import { sessions } from "constants/endpoints";

const postPrtUpload = ({
  userSessionId,
  prtUpload,
  optimisationConstraintId,
}) => {
  const ENDPOINT = sessions.postPrtUpload();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      userSessionId,
      prtUpload,
      optimisationConstraintId,
    },
  });
};

export default postPrtUpload;
