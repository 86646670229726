import http from "utils/http";
import { sessions } from "constants/endpoints";

const getSessionById = (sessionId) => {
  const ENDPOINT = sessions.getSessionById({ sessionId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`);
};

export default getSessionById;
