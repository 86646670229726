import { useFormContext } from "react-hook-form";

const SubmitButton = ({ children, ...rest }) => {
  const { formState } = useFormContext();

  const { disabled } = rest;

  const { isSubmitting, isValid, isValidating, submitCount } = formState;
  const isDisabled =
    disabled || isSubmitting || (!isValid && submitCount > 0) || isValidating;

  return (
    <button {...rest} disabled={isDisabled ? "disabled" : ""}>
      {children}
    </button>
  );
};

export default SubmitButton;
