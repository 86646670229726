import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const RecoverPasswordRequestSent = () => {
  return (
    <>
      <img className="login-logo" src="assets/images/ll2.png" alt="logo" />
      <div
        className="mt-5"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="col-lg-5 mt-5">
          <div className="card mb-0 mt-5">
            <div className="card-body">
              <div className="mb-4 text-center">
                <AiOutlineCheckCircle
                  style={{ fontSize: "100px", color: "#189b39" }}
                />
                <p
                  className="my-2 mt-4"
                  style={{ fontFamily: "Montserrat", fontSize: "18px" }}
                >
                  Password recovery email has been sent. Please check your inbox
                  and follow the instructions.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link
                  to="/login"
                  style={{ color: "white" }}
                  className="btn btn-primary btn-lg w-50"
                >
                  Back to Login{" "}
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverPasswordRequestSent;
