import * as yup from "yup";

const digiBasicSchema = yup.object().shape({
  digibasicToolTypeId: yup
    .number()
    .required()
    .label("Digibasic Tool Type Id")
    .typeError("Tool type id should be a number"),
  diameter: yup
    .number()
    .min(0.01, "Tool Diameter cannot be less than 0.01mm")
    .max(200, "Tool Diameter cannot be higher than 200mm")
    .required()
    .label("Diameter")
    .typeError("Tool Diameter cannot be empty")
    .test(
      "is Diameter correct",
      "Diameter value should equal or bigger than width of cut and should positive",
      function test() {
        const { widthOfCut } = this.parent.cuttingParameters;
        const { diameter } = this.parent;
        return diameter >= widthOfCut && diameter > 0;
      }
    ),
  fluteLength: yup
    .number()
    .min(0.1, "Minimum flute length is 0.1")
    .max(150, "Maximum flute length is 150")
    .required()
    .label("Flute Length")
    .typeError("Flute Length cannot be empty"),
  fluteParameters: yup.object().shape({
    noOfFlutes: yup
      .number()
      .integer("Should be positive digit number")
      .min(1)
      .required()
      .label("Number Of Flutes")
      .typeError("Number of flutes cannot be empty")
      .min(1)
      .max(10),
    helixOption: yup
      .number()
      .min(1, "Should be a minimum 1")
      .max(2, "Should be a maximum 2")
      .required()
      .label("Helix Option")
      .typeError("Helix Option cannot be empty")
      .test(
        "Is helix value right",
        "Helix values cannot be empty",
        function test() {
          const { helixOption, helixAngle } = this.parent;
          if (helixOption === 1) {
            const nonEmptyHelixAngles = helixAngle.filter((angle) => {
              return angle !== null && angle !== "";
            });
            return nonEmptyHelixAngles.length === 1;
          }
          if (helixOption === 2) {
            const nonEmptyHelixAngles = helixAngle.filter((angle) => {
              return angle !== null && angle !== "";
            });
            return nonEmptyHelixAngles.length === this.parent.noOfFlutes;
          }
          return false;
        }
      ),
    pitchOption: yup
      .number()
      .min(1)
      .required()
      .label("Pitch Option")
      .typeError("Pitch Option cannot be empty")
      .test(
        "Is pitch value right",
        "You have an error in your pitch values",
        function test() {
          const { pitchOption, pitchAngle } = this.parent;
          if (pitchOption === 1) {
            const nonEmptyPitchAngles = pitchAngle.filter((angle) => {
              return angle !== null && angle !== "";
            });
            return nonEmptyPitchAngles.length === 1;
          }
          if (pitchOption === 2) {
            const nonEmptyPitchAngles = pitchAngle.filter((angle) => {
              return angle !== null && angle !== "";
            });
            return nonEmptyPitchAngles.length === this.parent.noOfFlutes;
          }
          return false;
        }
      ),
    rakeAngle: yup
      .number()
      .min(-90)
      .max(90)
      .required()
      .label("Rake Angel")
      .typeError("Rake Angle cannot be empty"),
  }),
  cuttingParameters: yup.object().shape({
    millingTypeSelection: yup
      .number()
      .required()
      .label("Milling Type Selection")
      .typeError("Milling Type Selection cannot be empty"),
    depthOfCut: yup
      .number()
      .min(0.1)
      .max(150)
      .label("Depth Of Cut")
      .typeError("Depth Of Cut cannot be empty")
      .test(
        "is Depth Of Cut value valid",
        "Depth Of Cut value should be less than Flute Length value and should positive",
        function test() {
          const { depthOfCut } = this.parent;
          const { fluteLength } = this.from[1].value;
          if (depthOfCut <= fluteLength && depthOfCut > 0) {
            return true;
          }
          return false;
        }
      ),
    widthOfCut: yup
      .number()
      .min(0.1, "Width Of Cut cannot be less than 0.1mm")
      .label("Width Of Cut")
      .typeError("Width Of Cut cannot be empty")
      .test(
        "is Width of Cut value valid",
        "Width of Cut value should less than Tool Diameter value and should positive",
        function test() {
          const { widthOfCut } = this.parent;
          const { diameter } = this.from[1].value;
          if (widthOfCut <= diameter && widthOfCut > 0) {
            return true;
          }
          return false;
        }
      ),
    speedTypeSelection: yup
      .number()
      .required()
      .label("Speed Type Selection")
      .typeError("Speed Type Selection cannot be empty")
      .test(
        "isSelectedSpeedTypeValueValid",
        "Speed type value should be in the allowed range and positive.",
        function () {
          const { speedTypeSelection } = this.parent;
          switch (speedTypeSelection) {
            case 1:
              const { spindleSpeed } = this.parent;
              return spindleSpeed <= 40000 && spindleSpeed > 0;
            case 2:
              const { cuttingSpeed } = this.parent;
              return cuttingSpeed <= 15000 && cuttingSpeed > 0;
            default:
              return false;
          }
        }
      ),
    feedTypeSelection: yup
      .number()
      .required()
      .label("Feed Type Selection")
      .typeError("Feed Type Selection cannot be empty")
      .test(
        "isSelectedFeedTypeValueValid",
        "Feed Type value should be in the allowed range and positive.",
        function () {
          const { feedTypeSelection } = this.parent;
          switch (feedTypeSelection) {
            case 1:
              const { feedRate } = this.parent;
              return feedRate <= 40000 && feedRate > 0;
            case 2:
              const { feedPerTooth } = this.parent;
              return feedPerTooth <= 1 && feedPerTooth > 0;
            default:
              return false;
          }
        }
      ),
    materialSelection: yup
      .number()
      .required()
      .label("Material Selection")
      .typeError("Material Selection cannot be empty"),
  }),
});

export default digiBasicSchema;
