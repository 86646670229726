import * as yup from "yup";
import toolHolderInputTypes from "constants/toolHolderInputTypes";
import toolInputTypes from "constants/toolInputTypes";

const cuttingToolSchema = yup.object().shape({
  operationName: yup
    .string()
    .required()
    .label("Operation Name")
    .typeError("Operation Name must be a url."),
  toolHolderName: yup
    .string()
    .required()
    .label("Tool Holder Name")
    .typeError("Tool Holder Name must be a text."),
  toolHolderDataInputSelection: yup
    .string()
    .required()
    .label("Tool Holder Input Radio Button")
    .typeError(
      "Radio button output expects either of the following: choice url/iso"
    ),
  toolHolderUrl: yup
    .string()
    .label("Tool Holder Url")
    .typeError("Tool Holder Url must be a valid url.")
    .when("toolHolderDataInputSelection", {
      is: (value) => value === toolHolderInputTypes.URL,
      then: yup.string().required("Tool Holder Url is required"),
    }),
  toolHolderIsoNo: yup
    .string()
    .label("Tool Holder Iso No")
    .typeError("Tool Holder Iso No must be a string.")
    .when("toolHolderDataInputSelection", {
      is: (value) => value === toolHolderInputTypes.ISO,
      then: yup.string().required("Tool Holder Iso No is required"),
    }),
  cuttingToolName: yup
    .string()
    .required("Cutting Tool Name is required")
    .label("Cutting Tool Name")
    .typeError("Cutting Tool Name must be a string."),
  cuttingToolNumber: yup
    .number()
    .required()
    .label("Cutting Tool Number")
    .typeError("Cutting Tool Number must be a number."),
  toolDataInputSelection: yup
    .string()
    .required()
    .label("Tool Data Input Radio Button")
    .typeError(
      "Radio button output expects either of the following choice: url/iso/datasheet/manual"
    ),
  toolUrl: yup
    .string()
    .label("Tool URL")
    .typeError("Tool URL must be a valid url.")
    .when("toolDataInputSelection", {
      is: (value) => value === toolInputTypes.URL,
      then: yup.string().required("Tool URL is required"),
    }),
  toolIsoNo: yup
    .string()
    .label("Tool Iso Number")
    .typeError("Tool Iso Number must be a string.")
    .when("toolDataInputSelection", {
      is: (value) => value === toolInputTypes.ISO,
      then: yup.string().required("Tool Iso Number is required"),
    }),
  toolDatasheet: yup
    .string()
    .label("Tool Datasheet")
    .typeError("Tool Datasheet must be a string.")
    .when("toolDataInputSelection", {
      is: (value) => value === toolInputTypes.DATASHEET,
      then: yup.string().required("Tool Datasheet is required"),
    }),
  // toolParameters: yup
  //   .object()
  //   .label("Tool Parameters")
  //   .typeError("Tool Parameters must be an object.")
  //   .when("toolDataInputSelection", {
  //     is: toolInputTypes.MANUAL,
  //     then: yup.object().shape({
  //       cuttingToolType: yup
  //         .number()
  //         .typeError("Cutting Tool Type Upload must be a number.")
  //         .required("Cutting Tool Type Upload is required"),
  //       diameter: yup
  //         .number()
  //         .typeError("Diameter must be a number.")
  //         .required("Diameter is required"),
  //       cornerRadius: yup
  //         .number()
  //         .typeError("Corner Radius must be a number.")
  //         .required("Corner Radius is required"),
  //       taperAngle: yup
  //         .number()
  //         .typeError("Taper Angle must be a number.")
  //         .required("Taper Angle is required"),
  //       tipAngle: yup
  //         .number()
  //         .typeError("Tip Angle must be a number.")
  //         .required("Tip Angle is required"),
  //       leadDistance: yup
  //         .number()
  //         .typeError("Lead Distance must be a number.")
  //         .required("Lead Distance is required"),
  //       toolLength: yup
  //         .number()
  //         .typeError("Tool Length must be a number.")
  //         .required("Tool Length is required"),
  //       fluteLength: yup
  //         .number()
  //         .typeError("Flute Length Upload must be a number.")
  //         .required("Flute Length is required"),
  //       noOfFlutes: yup
  //         .number()
  //         .typeError("No of Flutes must be a number.")
  //         .required("No of Flutes is required"),
  //       helixOption: yup
  //         .string()
  //         .typeError("You need to select a helix angle arrangement"),
  //       pitchOption: yup
  //         .string()
  //         .typeError("You need to select a pitch angle arrangement"),
  //       rakeOption: yup
  //         .string()
  //         .typeError("You need to select a rake angle arrangement"),
  //       helixAngle: yup
  //         .array()
  //         .typeError("Helix angle must be a number.")
  //         .when("helixOption", {
  //           is: "1",
  //           then: yup
  //             .array()
  //             .typeError("Helix angle must be a number.")
  //             .test(
  //               "hasHelixAngleArrayGotEnoughEntries",
  //               "Helix Angle should have 1 entry to represent constant",
  //               function test(helixAngle) {
  //                 const nonEmptyHelixAngles = helixAngle?.filter(
  //                   (angle) => angle !== ""
  //                 );
  //                 return nonEmptyHelixAngles?.length === 1;
  //               }
  //             )
  //             .required("Helix Angle is required"),
  //           is: "2",
  //           then: yup
  //             .array()
  //             .typeError("Helix angle must be a number.")
  //             .test(
  //               "hasHelixAngleArrayGotEnoughEntries",
  //               "Helix Angle should have enough entries to match No of Flutes",
  //               function test(helixAngle) {
  //                 const nonEmptyHelixAngles = helixAngle?.filter(
  //                   (angle) => angle !== ""
  //                 );
  //                 return nonEmptyHelixAngles?.length === this.parent.noOfFlutes;
  //               }
  //             )
  //             .required("Helix Angle is required"),
  //         }),
  //       pitchAngle: yup
  //         .array()
  //         .typeError("Pitch Angle Upload must be an array.")
  //         .when("pitchOption", {
  //           is: "1",
  //           then: yup
  //             .array()
  //             .typeError("Pitch angle must be a number.")
  //             .test(
  //               "hasPitchAngleArrayGotEnoughEntries",
  //               "Pitch Angle should have 0 entry to represent constant",
  //               function test(pitchAngle) {
  //                 const nonEmptyPitchAngles = pitchAngle?.filter(
  //                   (angle) => angle !== ""
  //                 );
  //                 return nonEmptyPitchAngles?.length === 0;
  //               }
  //             ),
  //           is: "2",
  //           then: yup
  //             .array()
  //             .typeError("Pitch angle must be a number.")
  //             .test(
  //               "hasPitchAngleArrayGotEnoughEntries",
  //               "Pitch Angle should have enough entries to match No of Flutes",
  //               function test(pitchAngle) {
  //                 const nonEmptyPitchAngles = pitchAngle?.filter(
  //                   (angle) => angle !== ""
  //                 );
  //                 return nonEmptyPitchAngles?.length === this.parent.noOfFlutes;
  //               }
  //             )
  //             .required("Pitch Angle is required"),
  //         }),
  //       rakeAngle: yup
  //         .array()
  //         .typeError("Rake Angle must be a number.")
  //         .when("rakeOption", {
  //           is: "1",
  //           then: yup
  //             .array()
  //             .typeError("Rake Angle must be a number.")
  //             .test(
  //               "hasRakeAngleArrayGotEnoughEntries",
  //               "Rake Angle should have 1 entry to represent constant",
  //               function test(rakeAngle) {
  //                 const nonEmptyRakeAngles = rakeAngle?.filter(
  //                   (angle) => angle !== ""
  //                 );
  //                 return nonEmptyRakeAngles?.length === 1;
  //               }
  //             )
  //             .required("Rake Angle is required"),
  //           is: "2",
  //           then: yup
  //             .array()
  //             .typeError("Rake Angle must be an array.")
  //             .test(
  //               "hasRakeAngleArrayGotEnoughEntries",
  //               "Rake Angle should have enough entries to match No of Flutes",
  //               function test(rakeAngle) {
  //                 const nonEmptyRakeAngles = rakeAngle?.filter(
  //                   (angle) => angle !== ""
  //                 );
  //                 return nonEmptyRakeAngles?.length === this.parent.noOfFlutes;
  //               }
  //             )
  //             .required("Rake Angle is required"),
  //         }),
  //     }),
  //   }),
  stickOutLength: yup
    .number()
    .required()
    .label("Stick Out Length")
    .typeError("Stick-Out Length is required field."),
  spindleName: yup
    .string()
    .required()
    .label("Spindle Name")
    .typeError("Spindle Name is required field."),
  cuttingToolFRFxxUpload: yup
    .string()
    .required()
    .label("Cutting Tool FRFxx Upload")
    .typeError("Cutting Tool FRFxx Upload must be a text."),
  cuttingToolFRFyyUpload: yup
    .string()
    .required()
    .label("Cutting Tool FRFyy Upload")
    .typeError("Cutting Tool FRFyy must be a text."),
});

export default cuttingToolSchema;
