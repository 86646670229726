import { useHistory } from "react-router";
import postUser from "services/postUser";
import * as yup from "yup";
import {
  Form,
  Input,
  ErrorMessage,
  SubmitButton,
  Select,
  MultiSelect,
} from "components/Forms";
import notify from "utils/toastMsg";
import getOrganizations from "services/getOrganizations";
import useAPI from "effects/useAPI";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .label("First Name")
    .typeError("First Name must be a text."),
  lastName: yup
    .string()
    .required()
    .label("Last Name")
    .typeError("Last Name must be a text."),
  password: yup
    .string()
    .min(8)
    .required()
    .label("password")
    .typeError("password must be a text."),
  phoneNumber: yup
    .string()
    .required()
    .label("Phone Number")
    .typeError("Phone number is a required field."),
  jobTitle: yup
    .string()
    .label("Job Title")
    .typeError("Job title must be a text."),
  userTypeId: yup
    .number()
    .required()
    .label("User Type ID")
    .typeError("User Type Id must be a number."),
  organizationName: yup
    .array()
    .of(yup.string())
    .min(1)
    .max(1)
    .required()
    .label("Organisation Name ")
    .typeError("Organisation name must be a list"),
  email: yup
    .string()
    .email()
    .required()
    .label("Email")
    .typeError("Email must be a valid email address."),
});

const CreateUser = ({ setRefreshKey }) => {
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [allOrganizationsLoading, allOrganizationsError, allOrganizations] =
    useAPI(() => getOrganizations());

  let organizationsToRender;
  if (allOrganizationsLoading) {
    organizationsToRender = [];
  } else {
    organizationsToRender = allOrganizations.organizations;
  }

  const organizationNames = organizationsToRender.map(
    (org) => org.organizationName
  );

  const handleRegister = (formValues) => {
    const {
      firstName,
      lastName,
      email,
      password,
      userTypeId,
      phoneNumber,
      jobTitle,
      organizationName,
    } = formValues;
    return postUser({
      firstName,
      lastName,
      email,
      password,
      userTypeId,
      phoneNumber,
      jobTitle,
      organizationName: organizationName[0],
    }).then(() => {
      setRefreshKey((oldKey) => oldKey + 1);
      history.push("/admin");
      notify("User created succesfully. ✅ ");
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        $(".close").click();
      }, 120);
    });
  };

  return (
    <Form
      initialValues={{}}
      validationSchema={schema}
      onSubmit={handleRegister}
    >
      <div className="form-group">
        <label>First Name</label>
        <div>
          <Input
            name="firstName"
            type="text"
            className="form-control"
            placeholder="Type first name"
          />
        </div>
        <ErrorMessage name="firstName" />
      </div>

      <div className="form-group">
        <label>Last Name</label>
        <div>
          <Input
            name="lastName"
            type="text"
            className="form-control"
            placeholder="Type last name"
          />
        </div>
        <ErrorMessage name="lastName" />
      </div>

      <div className="form-group">
        <label>E-Mail</label>
        <div>
          <Input
            name="email"
            type="email"
            className="form-control"
            parsley-type="email"
            placeholder="Enter a valid e-mail"
          />
        </div>
        <ErrorMessage name="email" />
      </div>

      <div className="form-group">
        <label>Password</label>
        <div>
          <Input
            name="password"
            type="password"
            className="form-control"
            placeholder="Password"
            autoComplete="new-password"
          />
        </div>
        <ErrorMessage name="password" />
      </div>
      <div className="form-group">
        <label>Phone Number</label>
        <div>
          <Input
            name="phoneNumber"
            type="text"
            className="form-control"
            placeholder="Enter phone number"
          />
        </div>
        <ErrorMessage name="phoneNumber" />
      </div>

      <div className="form-group">
        <label>User Type</label>
        <div>
          <Select
            className="form-control"
            name="userTypeId"
            options={[
              { key: 1, value: 1, label: "Admin" },
              { key: 2, value: 2, label: "Trial User" },
              { key: 3, value: 3, label: "Paid User" },
            ]}
          />
        </div>
      </div>
      <div className="form-group">
        <label>Job Title</label>
        <div>
          <Input
            name="jobTitle"
            type="text"
            className="form-control"
            placeholder="Enter your job title"
          />
        </div>
        <ErrorMessage name="jobTitle" />
      </div>
      <div className="form-group">
        <label>Organization</label>
        <div>
          <MultiSelect
            name="organizationName"
            options={organizationNames}
            maxSelectedOptions={1}
            onMaxOptionsSelected={() =>
              notify("You can only select 1 organization.")
            }
            allowCreate={`onFilter`}
          />
          <ErrorMessage name="organizationName" />
        </div>
      </div>
      <div className="form-group">
        <SubmitButton
          type="submit"
          className="btn btn-success btn-lg waves-effect waves-light"
          style={{ marginRight: 10 }}
        >
          Submit
        </SubmitButton>
      </div>
    </Form>
  );
};

export default CreateUser;
