import http from "utils/http";
import { cuttingTools } from "constants/endpoints";

const postSessionCuttingTool = ({
  userSessionId,
  operationName,
  toolHolderName,
  toolHolderDataInputSelection,
  toolHolderUrl,
  toolHolderIsoNo,
  cuttingToolName,
  cuttingToolNumber,
  toolDataInputSelection,
  toolUrl,
  toolIsoNo,
  toolDatasheet,
  toolParameters,
  cuttingToolFRFxxUpload,
  cuttingToolFRFyyUpload,
}) => {
  const ENDPOINT = cuttingTools.postSessionCuttingTool();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      userSessionId,
      operationName,
      toolHolderName,
      toolHolderDataInputSelection,
      toolHolderUrl,
      toolHolderIsoNo,
      cuttingToolName,
      cuttingToolNumber,
      toolDataInputSelection,
      toolUrl,
      toolIsoNo,
      toolDatasheet,
      toolParameters,
      cuttingToolFRFxxUpload,
      cuttingToolFRFyyUpload,
    },
  });
};

export default postSessionCuttingTool;
