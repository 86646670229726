import http from "utils/http";
import { user } from "constants/endpoints";

const postUser = ({
  firstName,
  lastName,
  email,
  password,
  userTypeId,
  phoneNumber,
  jobTitle,
  organizationName,
}) => {
  const POST_USER_ENDPOINT = user.register();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_USER_ENDPOINT}`, {
    data: {
      firstName,
      lastName,
      email,
      password,
      userTypeId,
      phoneNumber,
      jobTitle,
      organizationName,
    },
  });
};

export default postUser;
